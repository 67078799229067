import { actions } from './loaderReducer';

export function showLoader(block = false) {
  return {
    type: actions.SHOW_LOADER,
    payload: block
  };
}

export function hideLoader() {
  return {
    type: actions.HIDE_LOADER,
  };
}
