export const FORMAT_CHARACTERS = {
  D: {
    validate(char) {
      return /\w/.test(char);
    },
  },
  M: {
    validate(char) {
      return /\w/.test(char);
    },
  },
  Y: {
    validate(char) {
      return /\w/.test(char);
    },
  },
  W: {
    validate(char) {
      return /\w/.test(char);
    },
    transform(char) {
      return char.toUpperCase();
    },
  },
};
