import React from 'react';
import PropTypes from 'prop-types';
import './DoctorsModal.css';
import DoctorsSelect from '../../../UI/DoctorsSelect/DoctorsSelect';
import { CLAIM_STATUSES } from '../../../Claim/const/constants';
import { apiRootDoctor } from '../store/api';

const DoctorsModal = (props) => {
  const {
    fetch, close, claimId, docs, defaultValue,
  } = props;

  // TODO: to redux
  const linkClaimToDoctor = async (doctorId) => {
    await apiRootDoctor.addClaimToDoctor(doctorId, claimId, CLAIM_STATUSES.SET_TO_DOCTOR);
    if (typeof close === 'function') {
      fetch();
      close();
    }
  };

  // returns chosen id from Select
  const handleSelect = async (id) => {
    if (id) { await linkClaimToDoctor(id); }
  };

  return (
    <DoctorsSelect
      docs={docs}
      defaultValue={defaultValue}
      handleSelect={handleSelect}
    />
  );
};

DoctorsModal.propTypes = {
  fetch: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  claimId: PropTypes.string.isRequired,
  docs: PropTypes.array.isRequired,
  defaultValue: PropTypes.string.isRequired,
};

export default DoctorsModal;
