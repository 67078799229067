import React from 'react';
import { Link } from 'react-router-dom';
import './header.scss';
import { connect } from 'react-redux';
import UserMenu from './components/UserMenu';
import { notifyError, notifySuccess } from '../Notificator/notificatorActions';
import NotificationsPanel from '../../Notifications/NotificationsPanel/NotificationsPanel';
import FetchIndicator from './components/FetchIndicator';

const Header = (props) => {
  const { user } = props?.account;
  const clinicTitle = props?.appconfig?.clinicSettings?.clinicTitle;

  if (user) {
    const isAdmin = +user.role === 3;
    const isDoctor = +user.role === 2;
    const isRoot = +user.role === 99;

    return (
      <header className="container-fluid sticky-top">
        <div className="row">
          <nav className={`navbar navbar-expand-lg navbar-light w-100
              ${isAdmin ? '__admin-indicator' : ''}
              ${isDoctor ? '__doctor-indicator' : ''}
              ${isRoot ? '__clinic-indicator' : ''}`}>
            <div className="d-flex w-100">
              <div className="d-flex w-100">
                <div className="mr-3">
                  <Link to='/'>
                    <img
                      className="navbar-logo"
                      src="/logo.svg"
                      alt={clinicTitle}/>
                  </Link>
                </div>
                <div>
                  <Link className="navbar-brand" to="/">
                    {clinicTitle}
                  </Link>
                </div>
              </div>
              <div className="d-flex w-100 justify-content-end">
                <div className="w-100 d-flex justify-content-end mr-md-2">
                  {props.loader.fetch && <FetchIndicator/>}
                  <NotificationsPanel/>
                  <UserMenu/>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
  return (
    <header className="container-fluid shadow-sm">
      <div className="container">
        <div className="row">
          <div className="col-6">
            <div className="header">
              <div className="header--logo">
                <Link to='/'>
                  <img src="/logo.svg" alt={clinicTitle}/>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (store) => ({
  account: store.account,
  header: store.components.header,
  loader: store.loader,
  appconfig: store?.appconfig,
});

const mapDispatchToProps = {
  notifySuccess,
  notifyError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
