import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-maskedinput';
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import { withRouter } from 'react-router';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from '../../../../../containers/UI/Buttons/Button';
import Confirm from '../../../Confirm/Confirm';
import { getGlobalConfig } from '../../../../root/AppConfig/store/appconfigActions';
import { UserAgreement } from '../../../../User/components/UserAgreement/UserAgreement';
import { DATA_TYPES } from '../../../types/VALIDATE';
import { registerUser, verify } from '../../../store/registration-form.actions';
import { InputBase } from '../../../../../containers/Inputs/InputBase/inputBase';
import { FORMAT_CHARACTERS } from '../../../../../utils/FORMAT_CHARACTERS';
import { DateFormats, PHONE_FORMAT } from '../../../../../const/dateFormats';
import StepperContainer from '../../../../../containers/UI/StepperContainer/StepperContainer';
import { RegistrationUserAgreement } from '../../../RegistrationUserAgreement/RegistrationUserAgreement';
import { loadSidePanel, hideSidePanel } from '../../../../UI/SidePanel/actions/sidePanel.actions';

const STEPS = ['Почта', 'Основные данные', 'Пароль'];

/**
 * Component Registration Form
 *
 * @param {Object} props - component props
 *
 * @returns {JSX.Element}
 */
class RegistrationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      password2: '',
      phone: '',
      firstName: '',
      surName: '',
      lastName: '',
      birthday: '',
      avatar: '',
      medCard: '',
      sex: '',
      errors: [],
      emailConfirmed: false,
      phoneConfirmed: false,

      verifyEmailVisible: false,
      verifyEmail: '',
      verifyPhoneVisible: false,
      verifyPhone: '',

      personalDataAgreement: false,
      ofertaAgreement: false,

      activeStep: 0,
      isShortRegistration: false,

      isShowPassword: false,
      noLastName: false,
    };
  }

  componentDidMount() {
    getGlobalConfig();
  }

  // TODO: fix telephone validation

  disabledSubmitButton = () => {
    const {
      email,
      phone,
      firstName,
      surName,
      lastName,
      birthday,
      personalDataAgreement,
      ofertaAgreement,
      sex
    } = this.state;
    const isVerify = this.checkVerify();

    return (
      isVerify &&
      personalDataAgreement &&
      ofertaAgreement &&
      email &&
      this.validateEmail(email) &&
      phone &&
      firstName &&
      surName &&
      lastName &&
      birthday &&
      sex
    );
  };

  disabledNextStepButton = () => {
    const { email } = this.state;
    const isVerify = this.checkVerify();

    return isVerify && email && this.validateEmail(email);
  };

  checkboxHandler = e => {
    const { state } = this;
    state[e.target.name] = e.target.checked;
    this.setState(state);
  };

  validatePhone = phone =>
    String(phone)
      .toLowerCase()
      .match(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/);

  validateEmail = email =>
    String(email)
      .toLowerCase()
      .match(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  isValid = (field, type) => {
    const { name, validity, value } = field;
    return (
      type === name &&
      validity.valid &&
      (this.validateEmail(value) || this.validatePhone(value))
    );
  };

  onBlur = e => {
    const { appconfig } = this.props;
    const { verifyEmail, verifyPhone } = this.state;
    const field = e.target;
    const { value } = field;

    if (
      this.isValid(field, DATA_TYPES.email) &&
      appconfig.appSettings.enableEmailVerify &&
      value !== verifyEmail
    ) {
      this.setState({
        verifyEmail: value,
        email: value,
        verifyEmailVisible: true
      });
      this.onValidate(field.name);
    }
    if (
      this.isValid(field, DATA_TYPES.phone) &&
      appconfig.appSettings.enableSmsVerify &&
      value !== verifyPhone
    ) {
      this.setState({
        verifyPhone: value,
        phone: value,
        verifyPhoneVisible: true
      });
      this.onValidate(field.name);
    }
  };

  onValidate = (type, code) => {
    if (this.state[type]) {
      this.props.verify({
        type: code && code.length > 0 ? `validate-${type}` : type,
        code,
        UID: this.state[type]
      });
    }
  };

  onChange = e => {
    const { state } = this;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  checkVerify = () => {
    const config = this.props.appconfig;
    const { emailConfirmed, phoneConfirmed } = this.props.register;

    return (
      (config.appSettings.enableEmailVerify && emailConfirmed) ||
      (config.appSettings.enableSmsVerify && phoneConfirmed)
    );
  };

  onSubmit = () => {
    const isVerify = this.checkVerify();
    if (!isVerify) return false;

    this.props.registerUser(this.state, () => this.props.history.push('/auth'));
  };

  togglePolicyModal = () => {
    this.props.loadSidePanel({
      component: UserAgreement,
      args: {
        agreement: this.props.appconfig?.clinicSettings?.agreement,
        title: 'Политика конфиденциальности',
        hideSidePanel: this.props.hideSidePanel,
      },
    });
  };

  toggleOfertaModal = () => {
    this.props.loadSidePanel({
      component: UserAgreement,
      args: {
        agreement: this.props.appconfig?.clinicSettings?.oferta,
        title: 'Оферта о предоставлении услуг',
        hideSidePanel: this.props.hideSidePanel,
      },
    });
  };

  handleNoLastName = () => {
    this.setState({
      noLastName: !this.state.noLastName,
      lastName: this.state.noLastName ? '' : '-',
    });
  }

  render() {
    const { appconfig, register } = this.props;

    const {
      email,
      password,
      password2,
      phone,
      firstName,
      surName,
      lastName,
      birthday,
      personalDataAgreement,
      ofertaAgreement,
      sex,
      activeStep,
      isShowPassword,
      isShortRegistration,
      noLastName,
    } = this.state;

    const ActionButton = props => {
      if (props.activeStep === 0) {
        return this.disabledNextStepButton() ? (
          <Button
            className="btn-firm-filled w-100"
            onClick={() => {
              if (this.state.isShortRegistration) {
                this.setState({
                  firstName: 'Пользователь',
                  surName: 'Кабинета',
                  lastName: 'Личного',
                  birthday: new Date(Date.now()).toLocaleString().split(',')[0],
                  sex: 'M',
                  phone: '+79999999999',
                  activeStep: 2
                });
              } else {
                this.setState({ activeStep: 1 });
              }
            }}
          >
            Далее
          </Button>
        ) : (
          <Button className="btn-firm-filled w-100 disabled" type={'button'}>
            Необходимо заполнить и подтвердить почту
          </Button>
        );
      }

      if (props.activeStep === 1) {
        return this.disabledSubmitButton() ? (
          <Button
            className="btn-firm-filled w-100"
            onClick={() => this.setState({ activeStep: 2 })}
          >
            Далее
          </Button>
        ) : (
          <Button className="btn-firm-filled w-100 disabled" type={'button'}>
            Необходимо заполнить все пункты формы регистрации
          </Button>
        );
      }

      return this.disabledSubmitButton() ? (
        <Button
          className="btn-firm-filled w-100"
          onClick={() => this.onSubmit()}
        >
          Зарегистрироваться
        </Button>
      ) : (
        <Button className="btn-firm-filled w-100 disabled" type={'button'}>
          {password && password && password === password2 ? 'Необходимо заполнить все пункты формы регистрации' : 'Пароли должны совпадать'}
        </Button>
      );
    };

    return (
      <div className="container">
        <h2 className="pt-5 pb-3 text-center">Регистрация</h2>
        <StepperContainer activeStep={activeStep} steps={STEPS} />
        <div className="row">
          <div className="col-md-6 col-md-offset-3 col-xs-12 col-sm-12 m-auto">
            <form className="form-signin" onSubmit={this.onSubmit}>
              <div className="row">
                {activeStep === 0 && (
                  <>
                    <div className="col-12">
                      <div className="form-group">
                        <InputBase
                          required
                          type="email"
                          className="form-control"
                          placeholder="Почта *"
                          id={DATA_TYPES.email}
                          name={DATA_TYPES.email}
                          value={email}
                          onChange={this.onChange}
                          onBlur={this.onBlur}
                        />
                      </div>
                    </div>
                    {appconfig.appSettings.enableEmailVerify &&
                      register.verifyEmailVisible && (
                        <Confirm
                          refresh={() =>
                            this.props.verify({
                              UID: email,
                              type: DATA_TYPES.email
                            })
                          }
                          type={DATA_TYPES.email}
                          name={`${DATA_TYPES.email}-confirm`}
                          handler={this.onValidate}
                        />
                    )}
                    <div className="col-12 pb-2">
                      <Checkbox
                        size="small"
                        color="default"
                        name="isShortRegistration"
                        checked={isShortRegistration}
                        onChange={() =>
                          this.setState({
                            isShortRegistration: !isShortRegistration
                          })
                        }
                      />
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            Регистрация без указания ФИО, даты рождения, номера
                            телефона и пола
                          </Tooltip>
                        }
                      >
                        <label>
                          <Box component="div" fontSize={13} m={0} p={0}>
                            Упрощенная регистрация (без персональных данных)
                          </Box>
                        </label>
                      </OverlayTrigger>
                    </div>
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <div className="col-4">
                      <div className="form-group">
                        <InputBase
                          required
                          type="text"
                          className="form-control"
                          placeholder="Фамилия *"
                          name="surName"
                          value={surName}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <InputBase
                          required
                          type="text"
                          className="form-control"
                          placeholder="Имя *"
                          name="firstName"
                          value={firstName}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <InputBase
                          type="text"
                          className="form-control"
                          placeholder="Отчество"
                          name="lastName"
                          value={lastName}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            checked={noLastName}
                            onChange={this.handleNoLastName}
                            name="personalDataAgreement"
                          />
                        }
                        label={
                          <Box component="div" fontSize={13} m={0} p={0}>
                            Отчество отсутствует
                          </Box>
                        }
                      />
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <MaskedInput
                          required
                          className="form-control"
                          name="birthday"
                          mask={DateFormats.DEFAULT}
                          placeholder="Дата рождения *"
                          size="11"
                          value={birthday}
                          onChange={this.onChange}
                          formatCharacters={FORMAT_CHARACTERS}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <FormControl component="fieldset" className="col-12">
                        <div className="d-flex flex-row justify-content-between">
                          <div>
                            <FormLabel component="legend" className="mt-3 ml-2">
                              Пол *
                            </FormLabel>
                          </div>
                          <div>
                            <RadioGroup
                              row
                              aria-label="gender"
                              name="gender1"
                              value={sex}
                              onChange={this.onChange}
                            >
                              <FormControlLabel
                                className="mt-1"
                                value="F"
                                control={<Radio color="default" />}
                                label={
                                  <Box component="div" fontSize={13}>
                                    Женский
                                  </Box>
                                }
                                name="sex"
                              />
                              <FormControlLabel
                                className="ml-3 mt-1"
                                value="M"
                                control={<Radio color="default" />}
                                label={
                                  <Box component="div" fontSize={13}>
                                    Мужской
                                  </Box>
                                }
                                name="sex"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </FormControl>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <MaskedInput
                          required
                          mask={PHONE_FORMAT}
                          className="form-control"
                          placeholder="Телефон *"
                          id={DATA_TYPES.phone}
                          name={DATA_TYPES.phone}
                          value={phone}
                          onChange={this.onChange}
                          onBlur={this.onBlur}
                        />
                      </div>
                    </div>
                    <RegistrationUserAgreement
                      personalDataAgreement={personalDataAgreement}
                      checkboxHandler={this.checkboxHandler.bind(this)}
                      togglePolicyModal={this.togglePolicyModal.bind(this)}
                      ofertaAgreement={ofertaAgreement}
                      toggleOfertaModal={this.toggleOfertaModal.bind(this)}
                    />
                    {appconfig.appSettings.enableSmsVerify &&
                      register.verifyPhoneVisible && (
                        <Confirm
                          type={DATA_TYPES.phone}
                          name={`${DATA_TYPES.phone}-confirm`}
                          handler={this.onValidate}
                        />
                    )}
                  </>
                )}
                {activeStep === 2 && (
                  <>
                    <p className="col-12">
                      Все готово! Осталось только придумать пароль
                    </p>
                    <div className="col-12">
                      <div className="form-group">
                        <InputBase
                          required
                          type={isShowPassword ? 'text' : 'password'}
                          className="form-control"
                          placeholder="Пароль *"
                          name="password"
                          value={password}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <InputBase
                          required
                          type={isShowPassword ? 'text' : 'password'}
                          className="form-control"
                          placeholder="Повторите пароль *"
                          name="password2"
                          value={password2}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 pb-2">
                      <label>
                        <Box component="div" fontSize={13} m={0} p={0}>
                          Показать пароль
                        </Box>
                      </label>
                      <Checkbox
                        size="small"
                        color="default"
                        name="isShowPassword"
                        checked={isShowPassword}
                        onChange={() =>
                          this.setState({ isShowPassword: !isShowPassword })
                        }
                      />
                    </div>
                    {
                      isShortRegistration && <RegistrationUserAgreement
                        personalDataAgreement={personalDataAgreement}
                        checkboxHandler={this.checkboxHandler.bind(this)}
                        togglePolicyModal={this.togglePolicyModal.bind(this)}
                        ofertaAgreement={ofertaAgreement}
                        toggleOfertaModal={this.toggleOfertaModal.bind(this)}
                      />
                    }
                  </>
                )}
                <p>
                  <small className="col-12 text-muted">
                    Поля, отмеченные * обязательны для заполнения
                  </small>
                </p>
              </div>
              <ActionButton activeStep={activeStep} />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

RegistrationForm.propTypes = {
  appconfig: PropTypes.shape({
    clinicSettings: PropTypes.shape({
      oferta: PropTypes.string,
      agreement: PropTypes.string
    }),
    appSettings: PropTypes.shape({
      enableEmailVerify: PropTypes.bool,
      enableSmsVerify: PropTypes.bool
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  registerUser: PropTypes.func.isRequired,
  verify: PropTypes.func.isRequired,
  register: PropTypes.object.isRequired
};

RegistrationForm.defaultProps = {
  appconfig: {
    clinicSettings: {
      oferta: '',
      agreement: '',
    },
    appSettings: {
      enableEmailVerify: true,
      enableSmsVerify: true,
    },
  },
  register: {
    emailConfirmed: false,
    phoneConfirmed: false
  },
  history: {
    push: () => {}
  },
  registerUser: () => {},
  verify: () => {},
  loadModal: () => {},
};

const mapStateToProps = store => ({
  appconfig: store.appconfig,
  register: store.register
});

const mapDispatchToProps = {
  getAppConfig: getGlobalConfig,
  registerUser,
  verify,
  loadSidePanel,
  hideSidePanel,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegistrationForm)
);
