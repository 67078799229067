import {
  call, put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { reviewsApi } from '../api';
import { actions } from './reviews.reducer';
import { actions as notifyActions } from '../../UI/Notificator/notificatorReducer';
import { querystring } from '../../../store/utils/formatters/querystring';

export function* reviewsUserGetSaga() {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const pagination = yield select((state) => state.reviews.pagination);
    const query = yield select((state) => state.reviews.query);

    const q = { ...pagination, ...query };
    const response = yield call(reviewsApi.reviewsUserGet, { query: querystring(q) });
    yield put({
      type: actions.REVIEWS_USER_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: 'HIDE_LOADER' });
    yield put({ type: actions.REVIEWS_USER_REQUEST_FAILED });
  }
}

export function* createReviewSaga(action) {
  const response = yield call(reviewsApi.reviewCreate, { ...action.payload });
  if (response) {
    yield put({
      type: actions.REVIEWS_CREATE_REQUEST_SUCCESS,
    });
  } else {
    yield put({
      type: actions.REVIEWS_CREATE_REQUEST_FAILED,
    });
  }
}

export function* updateReviewSaga(action) {
  const response = yield call(reviewsApi.reviewUpdate, { ...action.payload });
  if (response) {
    yield put({
      type: actions.REVIEWS_UPDATE_REQUEST_SUCCESS,
    });

    yield put({
      type: actions.REVIEWS_USER_REQUEST,
    });

    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Спасибо! Ваш отзыв успешно отправлен.',
    });
  } else {
    yield put({
      type: actions.REVIEWS_UPDATE_REQUEST_FAILED,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
  }
}

export const registerReviewsSagas = [
  takeLatest([
    actions.REVIEWS_USER_REQUEST,
    actions.REVIEWS_BY_USER_CHANGE_PAGE,
  ], reviewsUserGetSaga),
  takeEvery([actions.REVIEWS_CREATE_REQUEST], createReviewSaga),
  takeEvery([actions.REVIEWS_UPDATE_REQUEST], updateReviewSaga),
];
