import {
  call, put, takeEvery
} from 'redux-saga/effects';
import { apiSettings } from '../api/apiSettings';
import { actions } from './settings.root.reducer';
import { actions as configActions } from '../../AppConfig/store/appconfigReducer';
import { actions as notifyActions } from '../../../UI/Notificator/notificatorReducer';

export function* saveSettingsRootSaga(action) {
  try {
    const response = yield call(apiSettings.saveSettings, { ...action.payload });
    if (response) {
      yield put({
        type: actions.SETTINGS_SAVE_REQUEST_SUCCESS,
      });

      yield put({
        type: configActions.CONFIG_REQUEST
      });

      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Настройки успешно сохранены',
      });
    }
  } catch (e) {
    yield put({
      type: actions.SETTINGS_SAVE_REQUEST_FAILED,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
  }
}

export const registerSettingsRootSagas = [
  takeEvery([actions.SETTINGS_SAVE_REQUEST], saveSettingsRootSaga),
];
