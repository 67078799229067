import React from 'react';
import PropTypes from 'prop-types';
import '../css/VideoContainer.scss';
import { toast } from 'react-toastify';

export const VideoContainer = (props) => {
  const { id, visible } = props;
  const display = visible ? '' : 'd-none';

  if (id === 'outcoming') {
    return (<div className={display}>
      {id === 'incoming' && <b>Входящий звонок</b>}
      <video id={id} muted={true}/>
    </div>);
  }

  return (
    <div className={display}>
      {id === 'incoming' && <b>Входящий звонок</b>}
      <video id={id} playsInline="" autoPlay="" onDoubleClick={(e) => {
        const elem = e.currentTarget;
        if (!document.fullscreenElement) {
          elem.requestFullscreen().catch((err) => {
            toast.error(`Войти в полноэкранный режим не удалось: ${err.message} (${err.name})`);
          });
        } else {
          document.exitFullscreen();
        }
      }}/>
    </div>
  );
};

VideoContainer.propTypes = {
  id: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};
