import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  notifyError,
  notifySuccess,
} from '../../../UI/Notificator/notificatorActions';
import Button from '../../../../containers/UI/Buttons/Button';
import { submit2FACode, generate2FACode } from './store/authValidate.actions';
import { TIMER_VALUE } from '../../types/TIMER';
import { InputBase } from '../../../../containers/Inputs/InputBase/inputBase';

/**
 * Component Two-Factor Authentication
 *
 * @param {Object} props - component props
 * @param {function} props.timer - number of seconds until a request new code
 *
 * @returns {JSX.Element}
 */
class AuthValidate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
      errors: [],
      countdown: 0,
    };
  }

  onChange = (e) => {
    const { state } = this;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { code } = this.state;
    this.props.submit2FACode({ code });
  };

  setTimer = (value) => {
    this.setState({
      countdown: value,
    });
  };

  /**
   * Create a request to generate a new auth code
   */
  generateCode = () => {
    const { countdown } = this.state;
    const { timer } = this.props;

    if (countdown) return;

    this.props.generate2FACode();

    this.setTimer(timer);
    const timerId = setInterval(() => {
      if (this.state.countdown) {
        this.setTimer(this.state.countdown - 1);
      } else {
        clearInterval(timerId);
      }
    }, 1000);
  };

  render() {
    const { countdown, code } = this.state;
    return (
      <div className="container panel">
        <div className="row">
          <div className="col-12 panel-header">
            <h4>Подтвердите вход</h4>
            <p>
              <small>Вам на почту отправлен код подтверждения</small>
            </p>
          </div>
          <div className="col-12 text-center panel-body">
            <form className="form-inline" onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="code">Код из письма:</label>
                <InputBase
                  id="code"
                  name="code"
                  onChange={this.onChange}
                  value={code}
                  type="text"
                  className="form-control mx-sm-3"
                  aria-describedby="code"
                />
                <small id="code" className="text-muted" />
              </div>
              <div className="form-group panel-body-buttons">
                <Button
                  className="btn btn-light"
                  type="button"
                  onClick={this.generateCode}
                  disabled={countdown}
                >
                  {!countdown
                    ? 'Отправить еще раз'
                    : `Запросить код повторно можно через ${countdown} сек.`}
                </Button>
                <Button className="btn btn-success" type="submit" disabled={!code}>
                  Войти
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  config: store.config,
});

const mapDispatchToProps = {
  notifySuccess,
  notifyError,
  submit2FACode,
  generate2FACode,
};

AuthValidate.propTypes = {
  timer: PropTypes.number,
  submit2FACode: PropTypes.func.isRequired,
  generate2FACode: PropTypes.func.isRequired,
};

AuthValidate.defaultProps = {
  timer: TIMER_VALUE,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthValidate);
