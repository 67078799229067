import withPagination from '../../../store/utils/mutators/withPagination';
import withQuery from '../../../store/utils/mutators/withQuery';
import withFetch from '../../../store/utils/mutators/withFetch';

const defaultState = {
  transactions: [],
  fetch: false,
};

export const initialState = {
  ...defaultState,
  ...withQuery(defaultState),
  ...withPagination(defaultState, 12),
  ...withFetch(defaultState),
};

export const actions = {
  TRANSACTIONS_REQUEST: 'TRANSACTIONS:REQUEST',
  TRANSACTIONS_REQUEST_SUCCESS: 'TRANSACTIONS:REQUEST_SUCCESS',
  TRANSACTIONS_REQUEST_FAILED: 'TRANSACTIONS:REQUEST_FAILED',

  TRANSACTIONS_CHANGE_PAGE: 'TRANSACTION:CHANGE_PAGE',
  TRANSACTIONS_CHANGE_PAGE_SUCCESS: 'TRANSACTION:CHANGE_PAGE_SUCCESS',
  TRANSACTIONS_CHANGE_PAGE_FAILED: 'TRANSACTION:CHANGE_PAGE_FAILED',
  TRANSACTIONS_CHANGE_PAGE_RESET: 'TRANSACTION:CHANGE_PAGE_RESET',

  TRANSACTION_UPDATE_REQUEST: 'TRANSACTION_UPDATE:REQUEST',
  TRANSACTION_UPDATE_REQUEST_SUCCESS: 'TRANSACTION_UPDATE:REQUEST_SUCCESS',
  TRANSACTION_UPDATE_REQUEST_FAILED: 'TRANSACTIONS_UPDATE:REQUEST_FAILED',

  TRANSACTION_VALIDATE_REQUEST: 'TRANSACTION_VALIDATE:REQUEST',
  TRANSACTION_VALIDATE_REQUEST_SUCCESS: 'TRANSACTION_VALIDATE:REQUEST_SUCCESS',
  TRANSACTION_VALIDATE_REQUEST_FAILED: 'TRANSACTIONS_VALIDATE:REQUEST_FAILED',
};

export function transactionsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.TRANSACTIONS_REQUEST:
      return {
        ...state,
        fetch: true,
        query: action.payload || '',
        pagination: {
          ...state.pagination,
          end: false,
        },
      };

    case actions.TRANSACTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        transactions: action.payload.transactions,
        fetch: false,

        pagination: {
          ...state.pagination,
          count: action.payload.count,
        },
      };

    case actions.TRANSACTIONS_REQUEST_FAILED:
      return {
        ...state,
        transactions: [],
        fetch: false,

        pagination: {
          ...state.pagination,
          count: 0,
        },
      };

    case actions.TRANSACTIONS_CHANGE_PAGE:
      return {
        ...state,
        fetch: true,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          limit: state.pagination.limit,
        },
      };

    case actions.TRANSACTIONS_CHANGE_PAGE_RESET:
      return {
        ...state,
        fetch: true,
        pagination: {
          ...initialState.pagination,
        },
      };

    case actions.TRANSACTIONS_CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        fetch: false,
        pagination: { ...state.pagination },
      };

    case actions.TRANSACTION_UPDATE_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.TRANSACTION_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.TRANSACTION_UPDATE_REQUEST_FAILED:
      return {
        ...state,
        fetch: false,
      };

    case actions.TRANSACTION_VALIDATE_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.TRANSACTION_VALIDATE_REQUEST_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.TRANSACTION_VALIDATE_REQUEST_FAILED:
      return {
        ...state,
        fetch: false,
      };

    default:
      return { ...state };
  }
}

export default transactionsReducer;
