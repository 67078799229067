import {
  call, put, takeEvery, select
} from 'redux-saga/effects';
import { actions } from './userEmr.reducer';
import { actions as notifyActions } from '../../../../UI/Notificator/notificatorReducer';
import {
  cancelFutureVisit, fetchFutureVisits, fetchHistoryVisitDetails, fetchHistoryVisits, fetchUserFiles
} from '../api';

export function* userAnalysisGetSaga(payload) {
  yield put({ type: 'SHOW_LOADER' });
  const { response, error } = yield call(fetchUserFiles, { ...payload });
  if (response) {
    yield put({
      type: actions.EMR_ANALYSIS_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } else {
    yield put({
      type: actions.EMR_ANALYSIS_ERROR,
      payload: error,
    });
    yield put({ type: 'HIDE_LOADER' });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Профиль пациента не верифицирован или произошла ошибка загрузки данных',
    });
  }
}

export function* visitFutureDeleteSaga(payload) {
  yield put({ type: 'SHOW_LOADER' });
  const { response, error } = yield call(cancelFutureVisit, { ...payload });
  if (response) {
    const profileId = yield select((state) => state.account.user.profileId);
    yield put({
      type: actions.EMR_VISIT_FUTURE_CANCEL_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: actions.EMR_VISIT_FUTURE_REQUEST,
      payload: { profileId },
    });
    yield put({ type: 'HIDE_LOADER' });
    if (payload.payload.enableDeleteVisitsFromSchedule) {
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Запись успешно отменена',
      });
    } else {
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Заявка на отмену записи передана для обработки',
      });
    }
  } else {
    yield put({
      type: actions.EMR_VISIT_FUTURE_CANCEL_ERROR,
      payload: error,
    });
    yield put({ type: 'HIDE_LOADER' });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка изменения данных',
    });
  }
}

export function* visitHistoryGetDetailsSaga(payload) {
  yield put({ type: 'SHOW_LOADER' });
  const { response, error } = yield call(fetchHistoryVisitDetails, { ...payload });
  if (response) {
    yield put({
      type: actions.EMR_VISIT_HISTORY_DETAILS_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } else {
    yield put({
      type: actions.EMR_VISIT_HISTORY_DETAILS_ERROR,
      payload: error,
    });
    yield put({ type: 'HIDE_LOADER' });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Профиль пациента не верифицирован или произошла ошибка загрузки данных',
    });
  }
}

export function* visitHistoryGetSaga(payload) {
  yield put({ type: 'SHOW_LOADER' });
  const { response, error } = yield call(fetchHistoryVisits, { ...payload });
  if (response) {
    yield put({
      type: actions.EMR_VISIT_HISTORY_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } else {
    yield put({
      type: actions.EMR_VISIT_HISTORY_ERROR,
      payload: error,
    });
    yield put({ type: 'HIDE_LOADER' });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Профиль пациента не верифицирован или произошла ошибка загрузки данных',
    });
  }
}

export function* visitFutureGetSaga(payload) {
  yield put({ type: 'SHOW_LOADER' });
  const { response, error } = yield call(fetchFutureVisits, { ...payload });
  if (response) {
    yield put({
      type: actions.EMR_VISIT_FUTURE_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } else {
    yield put({
      type: actions.EMR_VISIT_FUTURE_ERROR,
      payload: error,
    });
    yield put({ type: 'HIDE_LOADER' });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Профиль пациента не верифицирован или произошла ошибка загрузки данных',
    });
  }
}

export const registerUserEmrSagas = [
  takeEvery([actions.EMR_ANALYSIS_REQUEST], userAnalysisGetSaga),
  takeEvery([actions.EMR_VISIT_FUTURE_CANCEL_REQUEST], visitFutureDeleteSaga),
  takeEvery([actions.EMR_VISIT_HISTORY_DETAILS_REQUEST], visitHistoryGetDetailsSaga),
  takeEvery([actions.EMR_VISIT_HISTORY_REQUEST], visitHistoryGetSaga),
  takeEvery([actions.EMR_VISIT_FUTURE_REQUEST], visitFutureGetSaga),
];
