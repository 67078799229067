import { combineReducers } from 'redux';
import { chatReducer } from '../components/Chat/store/chat.reducer';
import { notificationReducer } from '../components/UI/Notificator/notificatorReducer';
import { authReducer } from '../components/Auth/store/auth.reducer';
import { registrationFormReducer } from '../components/Registration/store/registration-form.reducer';
import { transactionsReducer } from '../components/Transactions/store/transactions.reducer';
import { appconfigReducer } from '../components/root/AppConfig/store/appconfigReducer';
import { loaderReducer } from '../components/UI/Loader/store/loaderReducer';
import { headerReducer } from '../components/UI/Header/store/headerReducer';
import { doctorsReducer } from '../components/Doctor/store/doctors.reducer';
import { newsReducer } from '../components/News/store/news.reducer';
import { faqReducer } from '../components/Faq/store/faqReducer';
import { directionsReducer } from '../components/Directions/store/directionsReducer';
import { rootAdminReducer } from '../components/root/store/rootAdminReducer';
import { notificationsReducer } from '../components/Notifications/store/notificationsReducer';
import { reviewsReducer } from '../components/Reviews/store/reviews.reducer';
import { sidePanelReducer } from '../components/UI/SidePanel/store/sidePanel.reducer';
import { modalReducer } from '../components/UI/Modal/store/modalReducer';
import { modalsReducer } from '../components/UI/Modal/store/ModalsReducer';
import { userProfileReducer } from '../components/User/components/UserProfileAdd/store/userProfile.reducer';
import { userSettingsReducer } from '../components/User/components/UserSettings/store/userSettings.reducer';
import { userEmrReducer } from '../components/User/components/UserEmr/store/userEmr.reducer';
import { dialogReducer } from '../components/UI/Dialog/store/dialog.reducer';
import widgetsReducer from '../components/UI/Widgets/store/widgets.reducer';
import { forgotPasswordReducer } from '../components/ForgotPassword/store/ForgotPassword.reducer';
import { resetPasswordReducer } from '../components/ResetPassword/store/ResetPassword.reducer';
import { claimsReducer } from '../components/Claim/store/claims.reducer';
import { rightsManagerReducer } from '../components/root/RightsManager/store/rightsManager.reducer';
import emrRecordsReducer from '../components/EmrRecords/store/emrRecords.reducer';

const rootReducer = combineReducers({
  account: authReducer,
  register: registrationFormReducer,
  forgotPassword: forgotPasswordReducer,
  resetpassword: resetPasswordReducer,
  chat: chatReducer,
  loader: loaderReducer,
  transactions: transactionsReducer,
  notifications: notificationsReducer,
  doctors: doctorsReducer,
  news: newsReducer,
  faq: faqReducer,
  widgets: widgetsReducer,
  root: rootAdminReducer,
  appconfig: appconfigReducer,
  claims: claimsReducer,
  emrRecords: emrRecordsReducer,
  directions: directionsReducer,
  reviews: reviewsReducer,
  rightsManager: rightsManagerReducer,
  components: combineReducers({
    notifications: notificationReducer,
    header: headerReducer,
    sidePanel: sidePanelReducer,
    modal: modalReducer,
    modals: modalsReducer,
    userProfile: userProfileReducer,
    userSettings: userSettingsReducer,
    userEmr: userEmrReducer,
    dialog: dialogReducer,
  }),
});

export default rootReducer;
