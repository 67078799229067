import { call, put } from 'redux-saga/effects';
import { actions } from './directionsReducer';

export function* updateDirectionsSaga(payload) {
  try {
    yield put({ type: 'SHOW_LOADER' });
    yield put({
      type: actions.DIRECTIONS_UPDATE_SUCCESS,
      payload: payload.payload.elementsFiltered,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.DIRECTIONS_UPDATE_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}
