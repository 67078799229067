import AxiosClient from '../../../functional/Axios/AxiosClient';

export const getDoctors = ({ query }) => AxiosClient.get(`/doctors?${query}`);

export function getDoctorProfiles() {
  return AxiosClient.get('/doctors/');
}

export function getDoctorInfo(data) {
  const {
    id,
  } = data;
  return AxiosClient.get(`/doctors/user/${id}`);
}

export function getDoctorInfoById(data) {
  const {
    id,
  } = data;
  return AxiosClient.get(`/doctors/${id}`);
}

export function getApiDoctorInfo(data) {
  const {
    resourceId,
  } = data;
  return AxiosClient.post('/integration/resource', { resourceId });
}

export function getDoctorsByDirection(data) {
  const {
    directionId, date,
  } = data;
  return AxiosClient.post('schedule/direction/docs', { directionId, date });
}

export function getDoctorsByBranch(data) {
  const { branchId } = data;
  return AxiosClient.get(`/doctors/branch/${branchId}`);
}

export const getDoctorProfile = async (id) => AxiosClient.get(`/doctors/user/${id}`);
