import AxiosClient from '../../../../functional/Axios/AxiosClient';

export const newsAdminApi = {
  getNews: () => AxiosClient.get('/root/news'),

  deleteNewsItem: ({ ...payload }) => {
    const { id } = payload.payload;
    return AxiosClient.delete(`/root/news/${id}`)
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },

  addNewsItem: ({ ...payload }) => {
    const {
      title, tags, content, author, audience,
    } = payload.payload;
    return AxiosClient.post('/root/news', {
      title, tags, content, author, audience,
    })
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },

  updateNewsItem: ({ ...payload }) => {
    const {
      title, content, newsItemId,
    } = payload.payload;
    return AxiosClient.put('/root/news', {
      title, content, newsItemId,
    })
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },
};
