import { call, put, takeEvery } from 'redux-saga/effects';
import { actions } from './userProfile.reducer';
import { actions as notifyActions } from '../../../../UI/Notificator/notificatorReducer';
import { actions as sidePanelActions } from '../../../../UI/SidePanel/store/sidePanel.reducer';
import { actions as userActions } from '../../../../Auth/store/auth.reducer';
import { userProfilesApi } from '../api';

export function* addUserProfileSaga(action) {
  try {
    const response = yield call(userProfilesApi.addUserProfile, { ...action.payload });
    if (response) {
      yield put({
        type: actions.USERPROFILE_ADD_SUCCESS,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Профиль успешно добавлен',
      });
      yield put({
        type: sidePanelActions.HIDE_SIDE_PANEL,
      });
      yield put({
        type: userActions.USER_REQUEST,
      });
    }
  } catch (e) {
    yield put({
      type: actions.USERPROFILE_ADD_ERROR,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
    yield put({
      type: sidePanelActions.HIDE_SIDE_PANEL,
    });
  }
}

export function* deleteUserProfileSaga(action) {
  try {
    const response = yield call(userProfilesApi.deleteUserProfile, { ...action.payload });
    if (response) {
      yield put({
        type: actions.USERPROFILE_DELETE_SUCCESS,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Профиль успешно удален',
      });
      yield put({
        type: userActions.USER_REQUEST,
      });
    }
  } catch (e) {
    yield put({
      type: actions.USERPROFILE_ADD_ERROR,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка удаления профиля',
    });
  }
}

export function* verifyProfileSaga(action) {
  yield put({ type: 'SHOW_LOADER' });
  try {
    const response = yield call(userProfilesApi.verifyProfileRequest, { ...action.payload });
    const { profileId } = action.payload;
    if (response && response.status === 200) {
      yield call(userProfilesApi.verifyProfile, profileId);
      yield put({
        type: actions.USERPROFILE_VERIFY_SUCCESS,
      });
      yield put({ type: 'HIDE_LOADER' });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Профиль успешно ',
      });
      yield put({
        type: userActions.USER_REQUEST,
      });
    }
  } catch (e) {
    yield put({
      type: actions.USERPROFILE_VERIFY_ERROR,
    });
    yield put({ type: 'HIDE_LOADER' });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка верификации профиля',
    });
  }
}

export function* cancelVerifyProfileSaga(action) {
  yield put({ type: 'SHOW_LOADER' });
  const { id } = action.payload;
  try {
    const response = yield call(userProfilesApi.cancelVerificationProfileRequest, id);
    if (response && response.status === 200) {
      yield call(userProfilesApi.cancelProfileVerification, id);
      yield put({
        type: actions.USERPROFILE_CANCEL_VERIFY_SUCCESS,
      });
      yield put({ type: 'HIDE_LOADER' });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Профиль успешно отвязан',
      });
      yield put({
        type: userActions.USER_REQUEST,
      });
    }
  } catch (e) {
    yield put({
      type: actions.USERPROFILE_CANCEL_VERIFY_ERROR,
    });
    yield put({ type: 'HIDE_LOADER' });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отвязки профиля',
    });
  }
}

export const registerUserProfileSagas = [
  takeEvery([actions.USERPROFILE_ADD_REQUEST], addUserProfileSaga),
  takeEvery([actions.USERPROFILE_VERIFY_REQUEST], verifyProfileSaga),
  takeEvery([actions.USERPROFILE_CANCEL_VERIFY_REQUEST], cancelVerifyProfileSaga),
  takeEvery([actions.USERPROFILE_DELETE_REQUEST], deleteUserProfileSaga),
];
