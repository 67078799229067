import { call, put } from 'redux-saga/effects';
import { apiAuth } from '../../../api/apiAuth';
import { actions } from '../../../store/auth.reducer';
import { actions as loaderActions } from '../../../../UI/Loader/store/loaderReducer';
import { actions as emrActions } from '../../../../User/components/UserEmr/store/userEmr.reducer';

import SessionStorageCrypt from '../../../../../functional/SessionStorageCrypt';

export function* getUserSaga(payload: { showLoader: true }) {
  try {
    if (payload.showLoader) yield put({ type: loaderActions.SHOW_LOADER });

    const response = yield call(apiAuth.fetchUser);

    SessionStorageCrypt.setItem('user', response.data);

    yield put({
      type: actions.USER_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({ type: loaderActions.HIDE_LOADER });
  } catch (error) {
    yield put({ type: actions.USER_REQUEST_FAILED });
    yield put({ type: loaderActions.HIDE_LOADER });
  }
}

export function* changeUserSaga({ payload }) {
  try {
    yield put({ type: loaderActions.SHOW_LOADER });
    const response = yield call(apiAuth.changeCurrentUserProfile, {
      ...payload,
    });

    SessionStorageCrypt.setItem('user', response.data);
    yield put({
      type: actions.AUTH_USER_PROFILE_CHANGE_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: emrActions.EMR_CLEAN_ALL,
    });
    yield put({ type: loaderActions.HIDE_LOADER });
  } catch (error) {
    yield put({ type: actions.AUTH_USER_PROFILE_CHANGE_FAILED });
    yield put({ type: loaderActions.HIDE_LOADER });
  }
}
