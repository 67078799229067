import { actions } from './transactions.reducer';

export function getTransactions(payload) {
  return {
    type: actions.TRANSACTIONS_REQUEST,
    payload,
  };
}

export function getTransactionsByStatus(status) {
  return {
    type: actions.TRANSACTIONS_REQUEST,
    payload: { status },
  };
}

export function transactionsSetPage(page) {
  return {
    type: actions.TRANSACTIONS_CHANGE_PAGE,
    payload: { page },
  };
}

export function transactionsResetPage() {
  return {
    type: actions.TRANSACTIONS_CHANGE_PAGE_RESET,
  };
}

export function updateTransaction(obj) {
  return {
    type: actions.TRANSACTION_UPDATE_REQUEST,
    payload: { ...obj },
  };
}

export function validateTransaction(transactionId) {
  return {
    type: actions.TRANSACTION_VALIDATE_REQUEST,
    payload: { transactionId },
  };
}
