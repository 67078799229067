import { actions } from './chatModalReducer';

export function updateClinicChatMessages(message) {
  return {
    type: actions.CHAT_WITH_CLINIC_UPDATE_MESSAGES,
    payload: message,
  };
}

export function initClinicChat(chat) {
  return {
    type: actions.CHAT_INIT_WITH_CLINIC,
    payload: chat,
  };
}

export function showClinicChat() {
  return {
    type: actions.CHAT_WITH_CLINIC_SHOW,
  };
}

export function hideClinicChat() {
  return {
    type: actions.CHAT_WITH_CLINIC_HIDE,
  };
}
