import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import SessionStorageCrypt from '../../../functional/SessionStorageCrypt';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => (SessionStorageCrypt.getItem('jwtToken') ? (
        <Component {...props}/>
      ) : (
        <Redirect
          to={{
            pathname: '/auth',
            state: { from: props.location },
          }}
        />
      ))
      }
    />
);
