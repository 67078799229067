import { EXTENSION_TO_MIME } from '../components/Chat/types/ALLOWED_MIMES';

const downloadBlob = (data, filename = 'unknown.txt') => {
  const extension = filename.split('.').pop();
  let mime = 'text/plain';

  if (extension) {
    mime = EXTENSION_TO_MIME[extension];
  }

  const url = window.URL.createObjectURL(new Blob([data], { type: mime }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadBlob;
