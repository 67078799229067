import React from 'react';
import LinkHandler from '../components/ExternalLink/components/LinkHandler/LinkHandler';
import uuid from 'react-uuid';

const wrapTags = (text, regex) => {
  const textArray = text.split(regex);
  return textArray.map(str => {
    if (regex.test(str)) {
      return <LinkHandler url={str} key={uuid()}/>;
    } else {
      return str.replace(/https|http/gi, '')
    }
  });
};

export const parseLinks = (text) => {
  const urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  return wrapTags(text, urlRegex)
}
