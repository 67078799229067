import React from 'react';
import { connect } from 'react-redux';
import ChatRoom from './components/ChatRoom/ChatRoom';
import { roleChecker } from '../../utils/rolesChecker';
import ChatRoomRoot from './components/ChatRoom/ChatRoomRoot';

// it s just wrapper around Chat component.

const Chat = ({ account }) => {
  if (roleChecker.isAdministrative(account.user.role)) return <ChatRoomRoot/>;
  return <ChatRoom/>;
};

const mapStateToProps = (store) => ({
  account: store.account,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
