import AxiosClient from '../../../../functional/Axios/AxiosClient';

export const apiSettings = {
  updateFileServiceLocalConfig: (data) => AxiosClient.post('/root/config/v2', data),

  getRemoteFileServiceConfig: () => AxiosClient.get('/root/config/fileservice'),
  updateFileServiceConfig: (data) => AxiosClient.post('/root/config/fileservice', data),

  saveSettings: (data) => AxiosClient.post('/root/config/', data),

};
