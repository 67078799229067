import React from 'react';
import PropTypes from 'prop-types';
import { UserName } from '../../../UI/UserName/UserName';

const ClaimItemPatient = ({ claim, link }) => (
    <div className="col-6">
        <div className="text-break">
            <b>Пациент:</b>
            <UserName user={claim?.user} link={link}/>
        </div>
    </div>
);

ClaimItemPatient.propTypes = {
  claim: PropTypes.object.isRequired,
  link: PropTypes.bool
};

export default ClaimItemPatient;
