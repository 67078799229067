import { actions } from './userEmr.reducer';

export function getVisitsHistory(obj) {
  return {
    type: actions.EMR_VISIT_HISTORY_REQUEST,
    payload: { ...obj },
  };
}

export function getVisitHistoryDetails(obj) {
  return {
    type: actions.EMR_VISIT_HISTORY_DETAILS_REQUEST,
    payload: { ...obj },
  };
}

export function getVisitsFuture(obj) {
  return {
    type: actions.EMR_VISIT_FUTURE_REQUEST,
    payload: { ...obj },
  };
}

export function deleteFutureVisit(obj) {
  return {
    type: actions.EMR_VISIT_FUTURE_CANCEL_REQUEST,
    payload: { ...obj },
  };
}

export function getUserAnalysis(obj) {
  return {
    type: actions.EMR_ANALYSIS_REQUEST,
    payload: { ...obj },
  };
}
