import {
  call, put, takeEvery,
} from 'redux-saga/effects';
import { actions as notifyActions } from '../../../UI/Notificator/notificatorReducer';
import { replacementsAdminApi } from './api';
import { actions } from './replacements.reducer';
import { actions as sidePanelActions } from '../../../UI/SidePanel/store/sidePanel.reducer';

export function* getReplacements() {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const response = yield call(replacementsAdminApi.getReplacements);
    yield put({
      type: actions.REPLACEMENTS_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.REPLACEMENTS_REQUEST_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}

export function* addReplacement(payload) {
  try {
    const { response } = yield call(replacementsAdminApi.addReplacement, { ...payload });
    if (response) {
      yield put({ type: 'SHOW_LOADER' });
      yield put({
        type: actions.REPLACEMENTS_CREATE_SUCCESS,
        payload: response,
      });
      yield put({
        type: actions.REPLACEMENTS_REQUEST,
        payload: response.data,
      });
      yield put({ type: 'HIDE_LOADER' });
      yield put({
        type: sidePanelActions.HIDE_SIDE_PANEL,
      });
      yield put({
        type: notifyActions.NOTIFICATION_SUCCESS,
        payload: 'Переменная успешно добавлена',
      });
    }
  } catch (error) {
    yield put({
      type: actions.REPLACEMENTS_CREATE_FAILED,
      payload: error,
    });
    yield put({ type: 'HIDE_LOADER' });
    yield put({
      type: sidePanelActions.HIDE_SIDE_PANEL,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Произошла ошибка',
    });
  }
}

export const registerReplacementsSagas = [
  takeEvery([actions.REPLACEMENTS_REQUEST], getReplacements),
  takeEvery([actions.REPLACEMENTS_CREATE_REQUEST], addReplacement),
];
