import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/cjs/Col';
import Row from 'react-bootstrap/cjs/Row';
import { ACCESS_ROLES } from '../../../../../../../functional/roles';
import Button from '../../../../../../../containers/UI/Buttons/Button';

export const VideoInitiator = (props) => {
  const { userRole, startCallback, webrtcStatus } = props;
  return (
    <Row className="mt-2">
      <Col>
        {userRole === +ACCESS_ROLES.DOCTOR && webrtcStatus === 0
        && <>
          <Button onClick={startCallback} className="btn-warning btn-sm">
            Начать видео
          </Button>
          <br/>
          <small className="text-muted">
            <FontAwesomeIcon
              icon={faExclamationCircle}/> Перед началом видео-чата убедитесь, что ваш
            собеседник онлайн</small></>}
      </Col>
    </Row>
  );
};

VideoInitiator.propTypes = {
  userRole: PropTypes.number.isRequired,
  webrtcStatus: PropTypes.number.isRequired,
  startCallback: PropTypes.func.isRequired,
};
