import AxiosClient from '../../../../functional/Axios/AxiosClient';

export const apiServices = {
  /**
   * Fetch all services
   * @param query
   * @returns {Promise<AxiosResponse<any>>}
   */
  fetchServices: ({ query }) => AxiosClient.get(`/root/services?${query}`),

  /**
   * Adds services
   * @param title
   * @param direction
   * @param price
   * @param type
   * @param duration
   * @param misId
   * @returns {Promise<AxiosResponse<any>>}
   */
  addService: ({
    title, direction, price, type, duration, misId,
  }) => AxiosClient.post('/root/services',
    {
      title,
      direction,
      price,
      type,
      duration,
      misId,
    }),

  fetchService: ({ id }) => AxiosClient.get(`/root/services/${id}`),

  /**
   * Update services with all fields
   * @param id
   * @param title
   * @param price
   * @param duration
   * @param direction
   * @param type
   * @param misId
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateService: ({
    id, title, price, duration, direction, type, misId,
  }) => AxiosClient.put(`/root/services/${id}`, {
    title,
    direction,
    price,
    type,
    duration,
    misId,
  }),

  /**
   * Deleting services by its id
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  deleteService: ({ id }) => AxiosClient.delete(`/root/services/${id}`),

  /**
   * Dunno how to iterate in saga over array, so i had to create it.
   * @param services
   */
  deleteServices: (services) => {
    services.forEach((service) => AxiosClient.delete(`/root/services/${service}`));
  },

  /**
   * Linking MIS ID to services
   * @param id
   * @param misId
   * @returns {Promise<AxiosResponse<any>>}
   */
  linkMisID: ({ id, misId }) => AxiosClient.post(`/root/service/${id}/mis`, { misId }),
};
