export const videoChatSocketEvents = {
  GET_PEER_SERVER: 'WS:GET_PEER_SERVER',
  WEBRTC_SYNC_HOST: 'WS:WEBRTC_SYNC_HOST',
  WEBRTC_SYNC: 'WS:WEBRTC_SYNC',
  WEBRTC_SYNC_ROOM: 'WS:WEBRTC_SYNC_ROOM',
  WEBRTC_OFFER_SYNC: 'WS:WEBRTC_OFFER_SYNC',
  WEBRTC_ANSWER_SYNC: 'WS:WEBRTC_ANSWER_SYNC',
  WEBRTC_CANCEL: 'WS:WEBRTC_CANCEL',
  WEBRTC_REJECT_CALL: 'WS:WEBRTC_REJECT_CALL',
  WEBRTC_ABORT: 'WS:WEBRTC_SYNC_ABORT',
  WEBRTC_ANSWER: 'WS:WEBRTC_ANSWER',
};
