import React from 'react';

const Button = (props) => (
  <button className={`${props.className} btn`}
          type={props.type}
          onClick={props.onClick}
          disabled={props.disabled}
  >
    {props.children}
  </button>
);

export default Button;
