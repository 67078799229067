import {
  call, put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { actions as notifyActions } from '../../UI/Notificator/notificatorReducer';
import { transactionsApi } from '../api/transactionsApi';
import { actions } from './transactions.reducer';
import { actions as userClaimsActions } from '../../Claim/store/claims.reducer';
import { querystring } from '../../../store/utils/formatters/querystring';
import { chatsApi } from '../../Chat/api/chats.api';

export function* getTransactionsSaga(action) {
  try {
    const pagination = yield select((state) => state.transactions.pagination);
    const query = yield select((state) => state.transactions.query);

    let q = { ...pagination, ...query };

    if (action?.payload?.status) {
      q = { ...q, status: action?.payload?.status };
    }
    const response = yield call(transactionsApi.getTransactions, { query: querystring(q) });

    yield put({
      type: actions.TRANSACTIONS_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.TRANSACTIONS_REQUEST_FAILED });
  }
}

export function* validateTransactionSaga(payload) {
  try {
    const validationResult = yield call(transactionsApi.validateTransaction, payload.payload.transactionId);
    if (validationResult.data.doctorId && validationResult.data.userId) {
      const chatCreator = yield call(chatsApi.createChatOrGet, {
        doctor: validationResult.data.doctorId,
        user: validationResult.data.userId,
      });
      if (chatCreator) {
        window.location = `/chats/${chatCreator.data[0]._id}`;
      }
      yield put({ type: actions.TRANSACTION_VALIDATE_REQUEST_SUCCESS });
    } else {
      yield put({ type: actions.TRANSACTION_VALIDATE_REQUEST_FAILED });
    }
  } catch (error) {
    yield put({ type: actions.TRANSACTION_VALIDATE_REQUEST_FAILED });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка валидации транзакции. Попробуйте еще раз или свяжитесь с службой поддержки',
    });
  }
}

export function* updateTransactionSaga(payload) {
  try {
    const { response } = yield call(transactionsApi.updateTransaction, { ...payload });
    if (response) {
      yield put({
        type: actions.TRANSACTION_UPDATE_REQUEST_SUCCESS,
        payload: response,
      });
      if (payload.payload.status === 7) {
        yield put({
          type: actions.TRANSACTION_VALIDATE_REQUEST,
          payload: { transactionId: payload.payload.transactionId },
        });
      } else {
        yield put({
          type: userClaimsActions.CLAIMS_BY_USER_REQUEST,
        });
      }
    }
  } catch (error) {
    yield put({
      type: actions.TRANSACTION_UPDATE_REQUEST_FAILED,
      payload: error,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Произошла ошибка оплаты. Попробуйте еще раз или свяжитесь с службой поддержки',
    });
  }
}

export const registerTransactionsSagas = [
  takeLatest([
    actions.TRANSACTIONS_REQUEST,
    actions.TRANSACTIONS_CHANGE_PAGE,
    actions.TRANSACTIONS_CHANGE_PAGE_RESET,
  ], getTransactionsSaga),
  takeEvery([actions.TRANSACTION_UPDATE_REQUEST], updateTransactionSaga),
  takeEvery([actions.TRANSACTION_VALIDATE_REQUEST], validateTransactionSaga),
];
