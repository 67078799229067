import { actions } from './ForgotPassword.actions';

export const initialState = {
  errors: [],
};

export function forgotPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
      };

    case actions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
      };

    case actions.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        errors: action.errors,
      };

    default:
      return { ...state };
  }
}
