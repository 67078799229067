import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ClaimItemService from '../ClaimItemService/ClaimItemService';
import ClaimItemDateTime from '../ClaimItemDateTime/ClaimItemDateTime';
import ClaimItemDirection from '../ClaimItemDirection/ClaimItemDirection';
import ClaimItemComment from '../ClaimItemComment/ClaimItemComment';
import ClaimItemPatient from '../ClaimItemPatient/ClaimItemPatient';
import ClaimItemStatus from '../ClaimItemStatus/ClaimItemStatus';
import { ClaimItemTransactionPanel } from '../ClaimItemTransactionPanel/ClaimItemTransactionPanel';
import ClaimItemFiles from '../ClaimItemFiles/ClaimItemFiles';
import downloadBlob from '../../../../utils/downloadBlob';
import { claimsApi } from '../../../root/Claim/store/api/apiClaims';
import { ACCESS_ROLES, GROUPS, RBAC } from '../../../../functional/roles';
import ClaimItemType from '../ClaimItemType/ClaimItemType';
import { CLAIM_STATUSES, CLAIM_TYPES } from '../../const/constants';
import ClaimItemDoctorInfo from '../ClaimItemDoctorInfo/ClaimItemDoctorInfo';

const UserClaimItem = (props) => {
  const {
    claim, account, appconfig,
    startPayment, confirmPayment,
    getService, docs, services
  } = props;

  const secondOpinionServices = services && services.filter((s) => s.type === CLAIM_TYPES.SECOND_OPINION);
  const callDoctorServices = services && services.filter((s) => s.type === CLAIM_TYPES.CALL_DOCTOR);

  const downloadFile = async (filename) => {
    try {
      const result = await claimsApi.claimDownloadFile(filename);
      const { data, headers } = result;
      downloadBlob(data, headers?.filename);
    } catch (err) {
      toast.error('Ошибка загрузки файла');
    }
  };

  const isUserAdministrative = RBAC(account, GROUPS.ADMINISTRATIVE);

  const renderTransactionsPanel = account?.user?.role === +ACCESS_ROLES.USER
      && claim?.transaction && (claim?.status === CLAIM_STATUSES.AWAIT_PAYMENT || claim?.status === CLAIM_STATUSES.PAID);

  return (
    <div key={claim._id} className="list-group mb-3">
      <Paper elevation={3} className="list-group-item list-group-item-action wl__list-item"
             variant="outlined">
        <div className="row">
          <div className="col-12 mb-2">
            <ClaimItemType claim={claim}/>
          </div>
          <ClaimItemDateTime claim={claim}/>
          <ClaimItemPatient claim={claim} link={isUserAdministrative}/>
          <ClaimItemDoctorInfo
            claim={claim}
            account={account}
            docs={docs}
            services={claim.claimType === CLAIM_TYPES.SECOND_OPINION ? secondOpinionServices : callDoctorServices}
            selectService={() => {}}
          />
          <div className="col-12 d-sm-flex flex-row justify-content-between">
            {
              claim?.direction && <ClaimItemDirection claim={claim}/>
            }
          </div>
          <div className="col-12 p-2">
            <Divider/>
          </div>
          <ClaimItemComment appconfig={appconfig} claim={claim}/>
          {
            (claim?.attachedFiles && !_isEmpty(claim?.attachedFiles))
            && <ClaimItemFiles
              downloadFile={downloadFile}
              claim={claim}
            />
          }
          <div className="col-12 p-2">
            <Divider/>
          </div>
          <ClaimItemStatus claim={claim}/>
          <ClaimItemService claim={claim} account={account}/>
          {
            renderTransactionsPanel
              && <ClaimItemTransactionPanel
              transaction={claim?.transaction}
              claim={claim}
              pay={startPayment}
              confirmPay={confirmPayment}
              getService={getService}
            />
          }
        </div>
      </Paper>
      <Divider/>
    </div>
  );
};

UserClaimItem.propTypes = {
  claim: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  appconfig: PropTypes.object,
  startPayment: PropTypes.func,
  confirmPayment: PropTypes.func,
  getService: PropTypes.func,
};

export default UserClaimItem;
