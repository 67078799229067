export const initialState = {
  visible: false,
  side: 'right',
  component: null,
  args: null, // component props!
};

export const actions = {
  SHOW_SIDE_PANEL: 'SIDE-PANEL:SHOW',
  HIDE_SIDE_PANEL: 'SIDE-PANEL:HIDE',
  LOAD_SIDE_PANEL: 'SIDE-PANEL:LOAD',
};

export function sidePanelReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SHOW_SIDE_PANEL:
      return {
        ...state,
        visible: true,
      };

    case actions.HIDE_SIDE_PANEL:
      return {
        ...state,
        visible: false,
        component: null,
        args: null,
      };

    case actions.LOAD_SIDE_PANEL:
      return {
        ...state,
        visible: true,
        ...action.payload,
      };

    default:
      return { ...state };
  }
}
