import { call, put, takeEvery } from 'redux-saga/effects';
import { apiAuth } from '../../../api/apiAuth';
import { actions as loginActions } from '../../../store/auth.reducer';
import { actions as notifyActions } from '../../../../UI/Notificator/notificatorReducer';
import { actions as headerActions } from '../../../../UI/Header/store/headerReducer';
import { actions as loaderReducer } from '../../../../UI/Loader/store/loaderReducer';

import SessionStorageCrypt from '../../../../../functional/SessionStorageCrypt';
import { changeUserSaga, getUserSaga } from './user.saga';
import { userLogout } from '../../../../UI/Header/store/headerLogoutSaga';
import { hideUserMenuSaga } from '../../../../UI/Header/store/headerSaga';
import { AUTH_MESSAGES } from '../../../types/MESSAGES';
import {
  loginWith2FA,
  loginWithout2FA,
} from '../../AuthValidate/store/authValidate.saga';

export function saveUserToSession(response) {
  SessionStorageCrypt.setItem('user', response.data.user);
  SessionStorageCrypt.setItem('jwtToken', response.data.token);
}

export function* loginUserSaga(payload) {
  try {
    yield put({ type: loaderReducer.SHOW_LOADER });
    const response = yield call(apiAuth.authRequest, { ...payload });

    if (response.data.user.settings.auth.twoFactorAuth) {
      yield call(loginWith2FA, response);
    } else {
      yield call(loginWithout2FA, response);
    }
  } catch (error) {
    yield put({ type: loginActions.AUTH_REQUEST_FAILED });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: AUTH_MESSAGES.LOGIN_FAILED,
    });
    yield put({ type: loaderReducer.HIDE_LOADER });
  }
}

export const registerLoginSagas = [
  takeEvery([loginActions.AUTH_USER_PROFILE_CHANGE], hideUserMenuSaga),
  takeEvery([loginActions.AUTH_USER_PROFILE_CHANGE], changeUserSaga),
  takeEvery([loginActions.AUTH_REQUEST], loginUserSaga),
  takeEvery([loginActions.USER_REQUEST], getUserSaga),
  takeEvery([headerActions.LOGOUT_MENU], userLogout),
];
