import { actions } from './notificationsReducer';

export function pushNotification(data) {
  return {
    type: actions.PUSH_NOTIFICATION,
    payload: data,
  };
}

export function pushWebNotification(data) {
  return {
    type: actions.PUSH_WEB_NOTIFICATION,
    payload: data,
  };
}

export function getNotifications() {
  return {
    type: actions.GET_NOTIFICATIONS,
  };
}

const TYPE = {
  1: actions.READ_NOTIFICATION,
  99: actions.DELETE_NOTIFICATION,
};

export function changeStatusNotificationAction(status, notificationId) {
  return {
    type: TYPE[status],
    payload: {
      notificationId,
      status,
    },
  };
}

export function readAll() {
  return {
    type: actions.READ_NOTIFICATIONS,
  };
}
