/**
 * Dirty func with simple functionality of adding rbactag,
 * cuz production build mangling components name;
 * @param component
 * @param tag
 */
export default function addRbacTag(component, tag) {
  // eslint-disable-next-line no-param-reassign
  component.prototype.rbacTag = tag;
}
