import React from 'react';
import { connect } from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import Avatar from '@material-ui/core/Avatar';
import { SocketManager } from '../../../../ioc/socketManager';

class ChatParticipants extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pStatus: {}, // participants status
    };
    this.updateTimer = null;
  }

  componentDidMount() {
    SocketManager.ws.on('WS:COMMON:UPDATE_USER_STATUS', async (data) => {
      this.setState({
        pStatus: {
          ...this.state.pStatus,
          [data.id]: data.online,
        },
      });
    });

    this.updateTimer = setInterval(() => {
      if (this.props?.chat?.participants) {
        const participantsIds = this.props.chat.participants.map((p) => p._id);

        if (participantsIds && participantsIds.length > 0) {
          participantsIds.forEach((id) => {
            SocketManager.ws.emit('WS:COMMON:GET_USER_STATUS', id);
          });
        }
      }
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.updateTimer);
  }

  render() {
    const { pStatus: status } = this.state;
    const { chat: { participants } } = this.props;
    return (
      <div className="card p-2">
        <div className="font-weight-bold">
          Участники
        </div>
        {participants && participants.map((p) => (
          <div key={p._id} className="d-flex justify-content-start mt-2">
            {
              p.currentProfile.avatar && <Avatar alt={`${p.currentProfile.firstName} ${p.currentProfile.lastName}`}
                                                 src={`/avatars/${p.currentProfile.avatar}`} />
            }
            <small key={p.currentProfile.firstName} className="pt-2 pl-2">
              {p.currentProfile.firstName} {p.currentProfile.surName} {status[p._id]
                ? <Badge variant="success">online</Badge>
                : <Badge variant="danger">offline</Badge>
            }
            </small>
          </div>))}
      </div>);
  }
}

const mapStateToProps = (store) => ({
  chat: store.chat.chatRoom,
});

export default connect(mapStateToProps, {})(ChatParticipants);
