import LocalStorageCrypt from '../../../../functional/LocalStorageCrypt/LocalStorageCrypt';

export const initialState = { ...LocalStorageCrypt.getItem('appconfig') || {} };

export const actions = {
  CONFIG_REQUEST: 'CONFIG:REQUEST',
  CONFIG_REQUEST_SUCCESS: 'CONFIG:REQUEST_SUCCESS',
  CONFIG_REQUEST_FAILED: 'CONFIG:REQUEST_FAILED',
  CONFIG_ADMIN_REQUEST: 'CONFIG_ADMIN:REQUEST',
  CONFIG_ADMIN_REQUEST_SUCCESS: 'CONFIG_ADMIN:REQUEST_SUCCESS',
  CONFIG_ADMIN_REQUEST_FAILED: 'CONFIG_ADMIN:REQUEST_FAILED',
};

export function appconfigReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CONFIG_REQUEST:
      return { ...state };

    case actions.CONFIG_REQUEST_SUCCESS:
      return {
        ...state, ...action.payload,
      };

    case actions.CONFIG_REQUEST_FAILED:
      return {
        ...state, ...action.payload, // saga will put LocalStorage data into
      };

    case actions.CONFIG_ADMIN_REQUEST:
      return { ...state };

    case actions.CONFIG_ADMIN_REQUEST_SUCCESS:
      return {
        ...state, ...action.payload,
      };

    default:
      return { ...state };
  }
}

export default appconfigReducer;
