import React from 'react';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const UserIsReadyText = () => (
  <div>
    <div>
      <b>Пользователь готов к звонку!</b>
    </div>
    <div>
      Пока вы не начали, убедитесь в том что:
    </div>
    <div>
      <ul>
        <li>Камера и микрофон подключены;</li>
        <li>Никакие другие приложения их не используют;</li>
        <li>Колонки работают и вы слышите звук;</li>
        <li>Вы разрешили браузеру получить доступ к вашим устройствам;</li>
      </ul>
      <div className="d-flex justify-content-between">
        <div style={{ padding: '15px 15px 0 0' }}>
          <FontAwesomeIcon icon={faQuestionCircle} className="flex-wrap"/>
        </div>
        <div style={{ display: 'inline-block', lineHeight: '12px' }}>
          <small>
            Нажав "Начать" браузер запросит разрешение на использование камеры и микрофона. <br/>
            Программа запоминает эти настройки и изменить решение может быть проблематично, мы не предоставляем
            консультации технического характера, поэтому будьте внимательны.
          </small>
        </div>
      </div>
    </div>
  </div>
);

export const DoctorIsReadyText = () => (
  <div>
    <div>
      <b>Доктор предлагает вам начать видео-звонок</b>
    </div>
    <div>
      Пока вы не начали, убедитесь в том что:
    </div>
    <div>
      <ul>
        <li>Камера и микрофон подключены;</li>
        <li>Никакие другие приложения их не используют;</li>
        <li>Колонки работают и вы слышите звук;</li>
        <li>Вы разрешили браузеру получить доступ к вашим устройствам;</li>
      </ul>

      <div className="d-flex justify-content-between">
        <div style={{ padding: '15px 15px 0 0' }}>
          <FontAwesomeIcon icon={faQuestionCircle} className="flex-wrap"/>
        </div>
        <div style={{ display: 'inline-block', lineHeight: '12px' }}>
          <small>
            Нажав "Начать" браузер запросит разрешение на использование камеры и микрофона. <br/>
            Программа запоминает эти настройки и изменить решение может быть проблематично, мы не предоставляем
            консультации технического характера, поэтому будьте внимательны.
          </small>
        </div>
      </div>

    </div>
  </div>
);
