import { actions } from './claims.reducer';

export function getClaimsByUser(payload) {
  return {
    type: actions.CLAIMS_BY_USER_REQUEST,
    payload,
  };
}

export function claimsByUserSetPage(page) {
  return {
    type: actions.CLAIMS_BY_USER_CHANGE_PAGE,
    payload: { page },
  };
}

export function claimsByUserResetPage() {
  return {
    type: actions.CLAIMS_BY_USER_CHANGE_PAGE_RESET,
  };
}

export function createClaim(obj) {
  return {
    type: actions.CLAIM_CREATE_REQUEST,
    payload: { ...obj },
  };
}
