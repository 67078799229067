import React from 'react';
import Button from '../../../containers/UI/Buttons/Button';
import { NOTIFICATION_GROUPS } from '../const/constants';
import NotificationItem from '../NotificationItem/NotificationItem';

const NotificationsDropdown = ({
  list, filter, readHandler, readAll, visibleGroup, showClinicChat,
}) => (
    <>
        <div className="header--dropdown shadow-sm notification-container text-dark">
            <div className="w-100 mt-2 mx-3 row">
                <h6 className="flex-grow-1">Уведомления</h6>
                <Button className="flex-grow-1 p-0 mt-n2" onClick={() => readAll()}>
                    <small>Прочитать все</small>
                </Button>

            </div>

            <div className="float-list border-top border-bottom">
                {list && list.length > 0 && list.map((e) =>
                    <NotificationItem
                        key={e._id}
                        notification={e}
                        readHandler={readHandler} showClinicChat={showClinicChat}
                    />)}
                {list && list.length === 0 && (
                    <div className="text-center mt-2">
                        <p className="float-list-empty p-3">Все отлично! Вы обо всем уведомлены :)</p>
                    </div>)}
            </div>

            <div className="w-100 text-center">
                {visibleGroup === NOTIFICATION_GROUPS.UNREAD &&
                    <Button className="w-100 py-2" onClick={() => filter(NOTIFICATION_GROUPS.ALL)}>
                        <small>Все уведомления</small>
                    </Button>}
                {visibleGroup === NOTIFICATION_GROUPS.ALL &&
                    <Button className="w-100 py-2" onClick={() => filter(NOTIFICATION_GROUPS.UNREAD)}>
                        <small>Только новые</small>
                    </Button>}
            </div>
        </div>
    </>
);

export default NotificationsDropdown;
