import {
  call, put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { claimsApi } from '../api/claims.api';
import { actions } from './claims.reducer';
import { querystring } from '../../../store/utils/formatters/querystring';
import { actions as notifyActions } from '../../UI/Notificator/notificatorReducer';
import { actions as modalActions } from '../../UI/Modal/store/modalReducer';

export function* getClaimsByUserSaga(action) {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const pagination = yield select((state) => state.claims.pagination);
    const query = yield select((state) => state.claims.query);

    let q = { ...pagination, ...query };

    if (action?.payload?.status) {
      q = { ...q, status: action?.payload?.status };
    }

    const response = yield call(claimsApi.getClaimsByUser, { query: querystring(q) });

    yield put({
      type: actions.CLAIMS_BY_USER_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: 'HIDE_LOADER' });
    yield put({ type: actions.CLAIMS_BY_USER_REQUEST_FAILED });
  }
}

export function* createClaimSaga(action) {
  const response = yield call(claimsApi.createClaim, { ...action.payload });
  if (response) {
    yield put({
      type: actions.CLAIM_CREATE_REQUEST_SUCCESS,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Спасибо! Заявка успешно создана.',
    });
  } else {
    yield put({
      type: actions.CLAIM_CREATE_REQUEST_FAILED,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
    yield put({
      type: modalActions.HIDE_MODAL,
    });
  }
}

export const registerUserClaimsSagas = [
  takeLatest([
    actions.CLAIMS_BY_USER_REQUEST,
    actions.CLAIMS_BY_USER_CHANGE_PAGE,
    actions.CLAIMS_BY_USER_CHANGE_PAGE_RESET,
  ], getClaimsByUserSaga),
  takeEvery([actions.CLAIM_CREATE_REQUEST], createClaimSaga),
];
