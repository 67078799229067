import { call, put } from 'redux-saga/effects';
import { getDirections } from '../api';
import { actions } from './directionsReducer';

export function* getDirectionsSaga(payload) {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const response = yield call(getDirections, { ...payload });
    yield put({
      type: actions.DIRECTIONS_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.DIRECTIONS_REQUEST_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}
