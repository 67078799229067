import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import Col from 'react-bootstrap/cjs/Col';
import { notifyError, notifySuccess } from '../../UI/Notificator/notificatorActions';
import {
  getDoctorServices, getAllClaims, claimResetPage, claimSetPage, claimsWipe,
} from '../../root/Claim/store/claims.actions';
import SideBar from '../../../containers/UI/SideBarTabs/SideBar';
import SideBarTab from '../../../containers/UI/SideBarTabs/SideBarTab';
import SideBarComponent from '../../../containers/UI/SideBarTabs/SideBarComponent';
import Button from '../../../containers/UI/Buttons/Button';
import { hideModal, loadModal } from '../../UI/Modal/actions/ModalActions';
import { getDoctorProfile } from '../api/doctor.api';
import SideBatPortal from '../../../containers/UI/SideBarTabs/SideBarPortal';
import UserSelectContainer from '../../root/User/UserSelectContainer/UserSelectContainer';
import ClaimsContainer from '../../Claim/ClaimsContainer/ClaimsContainer';
import { CLAIM_STATUSES, CLAIM_TYPES } from '../../Claim/const/constants';

class DoctorClaims extends PureComponent {
  /**
   * To set core tab (and apply required filter) change active tab and filter condition
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      doctorId: '',
    };
  }

  componentDidMount() {
    this.props.getDoctorServices();
    this.fetchDocClaims();
  }

  fetchDocClaims = async () => {
    await getDoctorProfile(this.props?.account?.user?.id)
      .then((doc) => {
        this.setState({ doctorId: doc?.data?._id });
        this.props.getAllClaims({ ...this.props.query, doctor: doc?.data?._id, claimType: CLAIM_TYPES.SECOND_OPINION });
      });
  }

  fetchClaimsAndResetPage = (status) => {
    if (status && status.length !== 0) this.props.getAllClaims({ status, doctor: this.state.docId, claimType: CLAIM_TYPES.SECOND_OPINION });
    this.props.claimResetPage();
  }

  handleChangePage = (e) => {
    let value;

    if (e) {
      value = e.currentTarget.textContent;
      this.props.claimSetPage(+value - 1);
    }
  };

  render() {
    const { fetch, getAllClaims, claimResetPage } = this.props;
    const { doctorId, activeTab } = this.state;
    const isNewClaim = this.props.notifications.elements.some((elem) => elem.status === 0 && elem.type === 'NEW_CLAIM');
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-between">
            <div><h5>Текущие услуги</h5></div>
          </div>
          <hr/>
        </div>
        <SideBar changeTab={() => {
        }} defaultTab={activeTab}>
          <SideBatPortal id="claim-sidebar-portal" className="w-100">
            <Col className="mt-2">
              <h6>Поиск по пользователю</h6>
              <UserSelectContainer
                handleSelect={(id) => getAllClaims({
                  ...this.props.query, user: id, doctor: doctorId, claimType: CLAIM_TYPES.SECOND_OPINION,
                })}
              />
            </Col>
          </SideBatPortal>
          <SideBarTab sideEffects={[{
            fn: getAllClaims,
            args: [{ doctor: doctorId, claimType: CLAIM_TYPES.SECOND_OPINION, }],
          }, { fn: claimResetPage }]}>
            <div>
              Все заявки
            </div>
            <Button className="btn-outline-dark btn-sm" onClick={() => this.fetchClaimsAndResetPage()}>
              <FontAwesomeIcon icon={faSyncAlt}/>
            </Button>
          </SideBarTab>
          <SideBarComponent loading={fetch}>
            <ClaimsContainer
              {...this.props}
              handleChangePage={this.handleChangePage.bind(this)}
              fetch={this.fetchDocClaims.bind(this)}/>
          </SideBarComponent>
          <SideBarTab sideEffects={[{
            fn: getAllClaims,
            args: [{ status: CLAIM_STATUSES.SET_TO_DOCTOR, doctor: doctorId, claimType: CLAIM_TYPES.SECOND_OPINION, }],
          },
          { fn: claimResetPage },
          ]}>
            <div>
              Назначена врачу
            </div>
            <Button className="btn-outline-dark btn-sm" onClick={() => this.fetchClaimsAndResetPage(3)}>
              <FontAwesomeIcon icon={faSyncAlt}/>
            </Button>
          </SideBarTab>
          <SideBarComponent loading={fetch}>
            <ClaimsContainer
              {...this.props}
              handleChangePage={this.handleChangePage.bind(this)}
              fetch={this.fetchDocClaims.bind(this)}/>
          </SideBarComponent>
          <SideBarTab sideEffects={[{
            fn: getAllClaims,
            args: [{ status: CLAIM_STATUSES.AWAIT_PAYMENT, doctor: doctorId, claimType: CLAIM_TYPES.SECOND_OPINION, }],
          },
          { fn: claimResetPage }]}>
            <div>
              Ожидает оплаты
            </div>
            <Button className="btn-outline-dark btn-sm" onClick={() => this.fetchClaimsAndResetPage(5)}>
              <FontAwesomeIcon icon={faSyncAlt}/>
            </Button>
          </SideBarTab>
          <SideBarComponent loading={fetch}>
            <ClaimsContainer
              {...this.props}
              handleChangePage={this.handleChangePage.bind(this)}
              fetch={this.fetchDocClaims.bind(this)}/>
          </SideBarComponent>
          <SideBarTab sideEffects={[{
            fn: getAllClaims,
            args: [{ status: CLAIM_STATUSES.PAID, doctor: doctorId, claimType: CLAIM_TYPES.SECOND_OPINION, }],
          },
          { fn: claimResetPage }]}>
            <div>
              Оплачена (ожидает консультации)
            </div>
            <Button className="btn-outline-dark btn-sm" onClick={() => this.fetchClaimsAndResetPage(7)}>
              <FontAwesomeIcon icon={faSyncAlt}/>
            </Button>
          </SideBarTab>
          <SideBarComponent loading={fetch}>
            <ClaimsContainer
              {...this.props}
              handleChangePage={this.handleChangePage.bind(this)}
              fetch={this.fetchDocClaims.bind(this)}/>
          </SideBarComponent>
          <SideBarTab sideEffects={[{
            fn: getAllClaims,
            args: [{ status: CLAIM_STATUSES.DONE, doctor: doctorId, claimType: CLAIM_TYPES.SECOND_OPINION, }],
          },
          { fn: claimResetPage }]}>
            <div>
              Услуга оказана
            </div>
            <Button className="btn-outline-dark btn-sm" onClick={() => this.fetchClaimsAndResetPage(8)}>
              <FontAwesomeIcon icon={faSyncAlt}/>
            </Button>
          </SideBarTab>
          <SideBarComponent loading={fetch}>
            <ClaimsContainer
              {...this.props}
              handleChangePage={this.handleChangePage.bind(this)}
              fetch={this.fetchDocClaims.bind(this)}/>
          </SideBarComponent>
          <SideBarTab sideEffects={[{
            fn: getAllClaims,
            args: [{ status: CLAIM_STATUSES.CANCELLED_BY_USER, doctor: doctorId, claimType: CLAIM_TYPES.SECOND_OPINION, }],
          },
          { fn: claimResetPage }]}>
            <div>
              Отказ клиента
            </div>
            <Button className="btn-outline-dark btn-sm" onClick={() => this.fetchClaimsAndResetPage(9)}>
              <FontAwesomeIcon icon={faSyncAlt}/>
            </Button>
          </SideBarTab>
          <SideBarComponent loading={fetch}>
            <ClaimsContainer
              {...this.props}
              handleChangePage={this.handleChangePage.bind(this)}
              fetch={this.fetchDocClaims.bind(this)}/>
          </SideBarComponent>
          <SideBarTab sideEffects={[{
            fn: getAllClaims,
            args: [{ status: CLAIM_STATUSES.DELETED, doctor: doctorId, claimType: CLAIM_TYPES.SECOND_OPINION, }],
          },
          { fn: claimResetPage }]}>
            <div>
              Закрыта
            </div>
            <Button className="btn-outline-dark btn-sm" onClick={() => this.fetchClaimsAndResetPage(2)}>
              <FontAwesomeIcon icon={faSyncAlt}/>
            </Button>
          </SideBarTab>
          <SideBarComponent loading={fetch}>
            <ClaimsContainer
              {...this.props}
              handleChangePage={this.handleChangePage.bind(this)}
              fetch={this.fetchDocClaims.bind(this)}/>
          </SideBarComponent>
        </SideBar>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  appconfig: store.appconfig,
  account: store.account,
  ...store.root.claims,
  notifications: store.notifications,
});

const mapDispatchToProps = {
  notifySuccess,
  notifyError,
  getDoctorServices,
  hideModal,
  loadModal,
  getAllClaims,
  claimResetPage,
  claimSetPage,
  claimsWipe,
};

DoctorClaims.propTypes = {
  getDoctorServices: PropTypes.func.isRequired,
  getAllClaims: PropTypes.func.isRequired,
  claimSetPage: PropTypes.func.isRequired,
  claimResetPage: PropTypes.func.isRequired,
  claimsWipe: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  loadModal: PropTypes.func.isRequired,
  fetch: PropTypes.bool.isRequired,
  notifySuccess: PropTypes.func,
  notifyError: PropTypes.func,
  account: PropTypes.object.isRequired,
  appconfig: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorClaims);
