import AxiosClient from '../../../functional/Axios/AxiosClient';

export async function fetchNotifications() {
  return AxiosClient.get('/notifications/')
    .then((data) => data)
    .catch((error) => error);
}

export async function changeStatusNotification(data) {
  return AxiosClient.post(`/notifications/${data.notificationId}`, { status: data.status })
    .then((data) => data)
    .catch((error) => error);
}

export async function readAllNotifications() {
  return AxiosClient.post('/notifications/');
}
