import { toast } from 'react-toastify';
import AxiosClient from '../functional/Axios/AxiosClient';
import downloadBlob from './downloadBlob';
// todo: useless util, remove
export default function downloadFile(event, id, profileId) {
  AxiosClient.post('/integration/file', {
    profileId,
    fileId: id,
  }, { responseType: 'blob' })
    .then(({ data, headers }) => {
      downloadBlob(data, headers?.filename);
    })
    .catch((error) => {
      toast.error('Ошибка загрузки данных');
    })
    .then(() => {
      // always executed
    });
}
