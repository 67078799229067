import { call, put } from 'redux-saga/effects';
import { getBranchesAll } from './api';
import { actions } from './branchesAdminReducer';

export function* getAllBranchesAdmin(payload) {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const response = yield call(getBranchesAll, { ...payload });
    yield put({
      type: actions.BRANCHES_ALL_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.BRANCHES_ALL_REQUEST_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}
