import React from 'react';
import * as PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faUserMd } from '@fortawesome/free-solid-svg-icons/faUserMd';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

const ClaimItemService = (props) => {
  const { claim, account } = props;
  return (
    <div className="col-12">
      {claim.status !== 7 && claim.status !== 8 && claim.transaction
      && <div>
        {
          claim.status === 9 ? <b>Отказ пользователя.
            {account.user.role === 1
            && <div className="text-right"><FontAwesomeIcon icon={faTimes} size={'2x'} className="__color-red"/></div>}</b> : <>
            <div className="d-flex justify-content-between">
              <b>Услуга: </b> {claim.service && claim.service?.title}
              <b>Сумма: </b> {claim.service && claim.service?.price}
            </div>
            {account.user.role !== 1 && <b>Транзакция создана. Ожидается оплата клиента. Изменение или удаление
              заявки больше невозможно.</b>}
          </>
        }
      </div>}
      {claim.status === 7
      && <div>
        <div className="d-flex serviceInfo justify-content-between">
          <b>Услуга: </b> {claim.service && claim.service?.title}
          <b>Сумма: </b> {claim.service && claim.service?.price}
        </div>
      </div>}
      {claim.status === 8
      && <div>
        <div className="d-flex serviceInfo  justify-content-between">
          <b>Услуга: </b> {claim.service && claim.service?.title}
          <b>Сумма: </b>{claim.service && claim.service?.price}
        </div>
      </div>}
      {claim.status === 2
      && <div>
        {account.user.role !== 1 && <div className="deleteReason">
          <p><b>Закрыл: </b> {claim.cancelledBy}</p>
          <p><b>Результат: </b> {claim.cancelReason}</p>
        </div>}
      </div>}
      {(claim.status === 4 && account.user.role === 1)
      && <div className="text-right"><FontAwesomeIcon icon={faClock} size={'2x'}/></div>}
      {(claim.status === 3 && account.user.role === 1)
      && <div className="text-right"><FontAwesomeIcon icon={faUserMd} size={'2x'}/></div>}
      {(claim.status === 8 && account.user.role === 1)
      && <div className="text-right"><FontAwesomeIcon icon={faCheck} size={'2x'} className='__color-green'/>
      </div>}
    </div>
  );
};

ClaimItemService.propTypes = {
  claim: PropTypes.object.isRequired,
};

export default ClaimItemService;
