const MetaDirectoryMap = {
  clinic: 'Клиника',
  visits: 'История записей',
  analysis: 'История анализов',
  usersettings: 'Настройки пользователя',
  doctorsettings: 'Настройки врача',
  auth: 'Авторизация',
  forgotpassword: 'Восстановление пароля',
  register: 'Регистрация',
  validate: 'Подтверждение входа',
  bookoptions: 'Запись на прием',
  appointment: 'Запись на прием',
  transactions: 'Оплата услуг',
  doctor: 'Кабинет врача',
  claims: 'Заявки',
  chat: 'Консультация',
  root: 'Управление клиникой',
  info: 'Информация',
  settings: 'Настройки',
  reports: 'Отчеты',
  branches: 'Выбор филиала',
  directions: 'Направления',
  futurevisits: 'Плановые приемы',
  chats: 'Диалоги',
  calldoctor: 'Заявки на вызов врача',
  news: 'Новости',
  faq: 'Часто задаваемые вопросы',
  agreement: 'Пользовательское соглашение',
  reviews: 'Отзывы',
  management: 'Управление',
  branch: 'Филиалы',
  emr: 'Электронная медицинская карта',
  'claim-management': 'Управление заявками',
  'users-management': 'Управление пользователями',
  'doctors-management': 'Управление врачами',
  'directions-management': 'Управление направлениями',
  'chats-management': 'Управление чатами врачей',
  'clinic-chats-management': 'Управление чатами клиники',
  'branch-management': 'Управление филиалами',
  'news-management': 'Управление новостями',
  'faq-management': 'Управление FAQ',
  'chats-settings': 'Настройки чатов',
  'chats-statistics': 'Статистика чатов',
  'directions-settings': 'Настройки направлений',
  'directions-statistics': 'Статистика направлений',
  'services-statistics': 'Статистика услуг',
  'claim-statistics': 'Статистка заявок',
  'branch-statistics': 'Статистика филиалов',
  'news-statistics': 'Статистика новостей',
  'branch-settings': 'Настройки филиалов',
  'news-settings': 'Настройки новостей',
  'services-management': 'Управление услугами',
  'visit-request': 'Управление заявками на очный прием',
  'call-doctor': 'Управление заявками вызова врача',
  prepaid: 'Управление заявками на внесение средств',
  userfiles: 'Пользовательские файлы',
  patients: 'Пациенты',
};

const uuidLength = 24;

const MetaManager = {
  title: '',
  breadcrumbs: '',

  setCrumb(crumb) {
    const c = this.breadcrumbs;
    c.push(crumb);
  },

  getPage() {
    const location = window.location.pathname.split('/');
    if (location[2] in MetaDirectoryMap) return MetaDirectoryMap[location[2]];

    if (location[1] === 'chats' && location[2] && location[2].length === uuidLength) {
      return 'Чат с доктором';
    }

    if (location[1] === 'clinic' && location[2] && location[2].length === uuidLength) {
      return 'Карточка специалиста';
    }

    if (location[1] === 'news' && location[2] && location[2].length === uuidLength) {
      return 'Текст новости';
    }

    return location[2];
  },

  getSubPage() {
    const location = window.location.pathname.split('/');
    if (location[3] in MetaDirectoryMap) return MetaDirectoryMap[location[3]];
    return location[3];
  },

  getPageLink() {
    return `/${this.getPage()}`;
  },

  getDirectory(linkOnly = false) {
    const location = window.location.pathname.split('/');
    if (linkOnly) {
      return location[1];
    }
    if (location[1] in MetaDirectoryMap) return MetaDirectoryMap[location[1]];

    return location[1];
  },

  getDirectoryLink() {
    if (!this.getPage()) return '#';

    return `/${this.getDirectory(true)}`;
  },

  setTitle(t) {
    document.title = t;
  },

  isPageChanged() {

  },
};

export default MetaManager;
