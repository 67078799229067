import React from 'react';
import * as PropTypes from 'prop-types';
import AsyncListSelect from '../../../../containers/UI/ListSelect/AsyncListSelect';
import { ACCESS_ROLES } from '../../../../functional/roles';

const SelectUser = (props) => {
  const options = props.users.map((user, index) => ({
    value: user._id,
    label: `${user.currentProfile.surName} ${user.currentProfile.firstName} ${user.currentProfile.lastName}`,
    key: index,
  }));

  const fetchUsers = (name) => {
    if(props.fetchAdmins) { props.fetchUsers({ name, role: +ACCESS_ROLES.ADMIN }) }
    props.fetchUsers(name)
  }

  return (
    <div>
      <AsyncListSelect
        defaultValue={props.defaultValue}
        options={options}
        fetchData={fetchUsers}
        placeholder={props.placeholder ? props.placeholder : 'Начните вводить имя пользователя'}
        cb={props.handleSelect}
      />
    </div>
  );
};

SelectUser.propTypes = {
  users: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

export default SelectUser;
