import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { notifyWarning } from '../../../components/UI/Notificator/notificatorActions';
import { ALLOWED_MIMES } from '../../../components/Chat/types/ALLOWED_MIMES';

import './FileInput.css';

const FileInput = ({
  inputClassName,
  labelClassName,
  label,
  onChange,
  accept,
  notifyWarning,
  multiple,
  ...props
}) => {
  const onChangeFile = event => {
    event.preventDefault();

    const files = event.target.files;
    let isAllowed = true;

    Object.keys(files).map(key => {
      const file = files[key];
      if (!accept.includes(file.type)) {
        isAllowed = false;
      }
    });

    if (isAllowed) {
      onChange(event);
    } else {
      notifyWarning('Некорректный формат файла');
    }
  };

  return (
    <div className="input-group mt-2 mb-2">
      <div className="custom-file">
        <input
          type="file"
          {...props}
          accept={accept.join(',')}
          onChange={onChangeFile}
          multiple={multiple}
          className={`custom-file-input ${inputClassName}`}
          aria-describedby="inputGroupFileAddon01"
        />
        <label
          className={`custom-file-label ${labelClassName}`}
          htmlFor="inputGroupFile01"
        >
          {label}
        </label>
      </div>
    </div>
  );
};

FileInput.propTypes = {
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string,
  accept: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool
};

FileInput.defaultProps = {
  inputClassName: '',
  labelClassName: '',
  label: 'Выберите файл',
  accept: ALLOWED_MIMES,
  multiple: false
};

const mapDispatchToProps = {
  notifyWarning
};

export default connect(null, mapDispatchToProps)(FileInput);
