import React from 'react';
import './VisitHistory/VisitHistory.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { DateFormats } from '../../../../../const/dateFormats';
import 'react-datepicker/dist/react-datepicker.css';
import './Filters.css';

registerLocale('ru', ru);
const Filters = (props) => {
  const { startDate, endDate, onPeriodChange } = props;

  return (
      <div className="card filter">
        <div className="card-header">Фильтры:</div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <strong className="filter-label">Период посещений:</strong>
            <DatePicker
              locale="ru"
              isClearable
              selectsRange
              showMonthYearDropdown
              minDate={new Date('01.01.2020')}
              maxDate={new Date('01.01.2022')}
              startDate={startDate}
              endDate={endDate}
              dateFormat={DateFormats.DEFAULT_FOR_DATEPICKER}
              onChange={onPeriodChange}
            />
          </li>
        </ul>
      </div>
  );
};

export default Filters;
