import React from 'react';
import { connect } from 'react-redux';
import { getGlobalConfig } from '../root/AppConfig/store/appconfigActions';
import { hideBell } from '../UI/Header/store/headerActions';
import Registration from './RegistrationDefault/components/RegistrationForm/RegistrationForm';
import RegistrationWithSteps from './RegistrationWithSteps/components/RegistrationForm/RegistrationForm';

class RegistrationContainer extends React.Component {
  componentDidMount() {
    this.props.getAppConfig();
    this.props.hideBell();
  }

  render() {
    const { appconfig } = this.props;
    return (
      appconfig.appSettings?.enableStepperRegistration ? <RegistrationWithSteps /> : <Registration />
    );
  }
}

const mapStateToProps = (store) => ({
  appconfig: store.appconfig,
});

const mapDispatchToProps = {
  getAppConfig: getGlobalConfig,
  hideBell,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationContainer);
