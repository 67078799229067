import React, { useState } from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import CancelClaims from '../../../UI/Modal/Modals/CancelClaim/CancelClaim';
import ClaimItemButtons from '../ClaimItemButtons/ClaimItemButtons';
import ClaimItemService from '../ClaimItemService/ClaimItemService';
import ClaimItemDoctorAdminSelects from '../ClaimItemSelects/ClaimItemDoctorAdminSelects';
import ClaimItemDateTime from '../ClaimItemDateTime/ClaimItemDateTime';
import ClaimItemDirection from '../ClaimItemDirection/ClaimItemDirection';
import ClaimItemComment from '../ClaimItemComment/ClaimItemComment';
import ClaimItemPatient from '../ClaimItemPatient/ClaimItemPatient';
import ClaimItemDoctorInfo from '../ClaimItemDoctorInfo/ClaimItemDoctorInfo';
import ClaimItemStatus from '../ClaimItemStatus/ClaimItemStatus';
import { CLAIM_TYPES } from '../../const/constants';
import ClaimItemFiles from '../ClaimItemFiles/ClaimItemFiles';
import downloadBlob from '../../../../utils/downloadBlob';
import {
  claimAddService, refreshClaim, closeClaim, claimAddAdmin, claimDeleteAdmin, payClaim,
} from '../../../root/Claim/store/claims.actions';
import { hideModal, loadModal } from '../../../UI/Modal/actions/ModalActions';
import { claimsApi } from '../../../root/Claim/store/api/apiClaims';
import ClaimItemChevron from '../ClaimItemChevron/ClaimItemChevron';
import ClaimItemAdmins from '../ClaimItemAdmins/ClaimItemAdmins';
import ClaimItemActions from '../ClaimItemActions/ClaimItemActions';
import ClaimItemType from '../ClaimItemType/ClaimItemType';
import { roleChecker } from '../../../../utils/rolesChecker';

const RootClaimItem = (props) => {
  const {
    claim, fetch, docs,
    loadModal, hideModal,
    account, appconfig, services, claimAddService,
    refreshClaim, closeClaim, users,
    claimAddAdmin, claimDeleteAdmin, payClaim,
  } = props;
  const [showDoctorsList, setShowDoctorsList] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const secondOpinionServices = services && services.filter((s) => s.type === CLAIM_TYPES.SECOND_OPINION);
  const callDoctorServices = services && services.filter((s) => s.type === CLAIM_TYPES.CALL_DOCTOR);

  const admins = users && users.filter((user) => user.role === 3);

  const toggleClaimActions = () => {
    setShowActions(!showActions);
  };

  const refreshClaimRequest = () => {
    confirmAlert({
      title: 'Вы собираетесь сбросить состояние заявки до исходного. Вы уверены?',
      message: 'Это позволит создать новую транзакцию для пациента в случае отказа.'
        + ' Используйте осторожно, убедитесь, что по заявке получен отказ',
      buttons: [
        {
          label: 'Да',
          onClick: () => {
            refreshClaim(claim._id);
            return true;
          },
        },
        {
          label: 'Нет',
          onClick: () => false,
        },
      ],
    });
  };

  const payClaimRequest = () => {
    confirmAlert({
      title: 'После этого действия заявка будет считаться оплаченной. Вы уверены?',
      message: 'Это необходимо только для срочного одобрения заявки',
      buttons: [
        {
          label: 'Да',
          onClick: () => {
            payClaim(claim._id);
            return true;
          },
        },
        {
          label: 'Нет',
          onClick: () => false,
        },
      ],
    });
  };

  const selectService = (serviceId) => {
    confirmAlert({
      title: 'Назначить эту услугу?',
      message: 'Вы можете подтвердить и выставить счет или отменить выбор',
      buttons: [
        {
          label: 'Да',
          onClick: () => {
            claimAddService({ serviceId, claim });
            return true;
          },
        },
        {
          label: 'Нет',
          onClick: () => false,
        },
      ],
    });
  };

  const toggleModal = () => {
    loadModal({
      component: CancelClaims,
      args: {
        claim,
        fetch,
        hideModal,
      },
    });
  };

  const claimCloseSuccess = () => {
    confirmAlert({
      title: 'Успешно завершить услугу?',
      message: 'Успешное завершение услуги после ее корректного оказания.',
      buttons: [
        {
          label: 'Да',
          onClick: () => {
            closeClaim(claim._id);
            return true;
          },
        },
        {
          label: 'Нет',
          onClick: () => false,
        },
      ],
    });
  };

  const downloadFile = async (filename) => {
    try {
      const result = await claimsApi.claimDownloadFile(filename);
      const { data, headers } = result;
      downloadBlob(data, headers?.filename);
    } catch (err) {
      toast.error('Ошибка загрузки файла');
    }
  };

  const handlePopup = () => {
    setShowDoctorsList(!showDoctorsList);
  };

  const addAdminToClaim = (data) => {
    claimAddAdmin({ data });
  };

  return (
    <div key={claim._id} className="list-group mb-3">
      <Paper elevation={3} className={`list-group-item list-group-item-action wl__list-item
        ${(claim?.transaction && roleChecker.isAdministrative(account.user.role)) && '__light-warning'}
        ${(claim?.status === 7 && roleChecker.isAdministrative(account.user.role)) && '__light-success'}
        ${(claim?.status === 2 || claim?.status === 9) && (roleChecker.isAdministrative(account.user.role)) && '__light-danger'}
        ${(claim?.status === 8 && roleChecker.isAdministrative(account.user.role)) && '__success'}`}
             variant="outlined">
        <div className="row">
          <div className="col-12 mb-2">
            <ClaimItemType claim={claim}/>
          </div>
          <div className="col-6">
            {
              roleChecker.isAdministrative(account.user.role) &&
              <ClaimItemActions
                claim={claim}
                account={account}
                refreshClaim={refreshClaimRequest}
                payClaim={payClaimRequest}
                claimCloseSuccess={claimCloseSuccess}
              />
            }
          </div>
          <div className="col-6">
            {
              roleChecker.isAdministrative(account.user.role) &&
              <ClaimItemChevron
                showActions={showActions}
                toggleClaimActions={toggleClaimActions}
                account={account}
              />
            }
          </div>
          {
            showActions && roleChecker.isAdministrative(account.user.role) &&
            <ClaimItemDoctorAdminSelects
              claim={claim}
              account={account}
              admins={admins}
              docs={docs}
              handlePopup={handlePopup}
              addAdminToClaim={addAdminToClaim}
              fetch={fetch}
            />
          }
          <ClaimItemDateTime claim={claim}/>
          <ClaimItemPatient claim={claim} link={roleChecker.isAdministrative(account.user.role)}/>
          <ClaimItemDoctorInfo
            claim={claim}
            account={account}
            docs={docs}
            services={claim.claimType === CLAIM_TYPES.SECOND_OPINION ? secondOpinionServices : callDoctorServices}
            selectService={selectService}
          />
          <div className="col-12 d-sm-flex flex-row justify-content-between">
            <ClaimItemAdmins account={account} claim={claim} deleteAdmin={claimDeleteAdmin}/>
            { claim?.direction && <ClaimItemDirection claim={claim}/> }
          </div>
          <div className="col-12 p-2">
            <Divider/>
          </div>
          <ClaimItemComment appconfig={appconfig} claim={claim}/>
          {
            (claim?.attachedFiles && !_isEmpty(claim?.attachedFiles)) &&
            <ClaimItemFiles
              downloadFile={downloadFile}
              claim={claim}
            />
          }
          <div className="col-12 p-2">
            <Divider/>
          </div>
          <ClaimItemStatus claim={claim}/>
          {
            roleChecker.isAdministrative(account.user.role) &&
            <ClaimItemButtons
              claim = {claim}
              toggleModal={toggleModal}
              claimCloseSuccess={claimCloseSuccess}
            />
          }
          <ClaimItemService claim={claim} account={account}/>
        </div>
      </Paper>
      <Divider/>
    </div>
  );
};

const mapDispatchToProps = {
  hideModal,
  loadModal,
  claimAddService,
  refreshClaim,
  closeClaim,
  claimAddAdmin,
  claimDeleteAdmin,
  payClaim,
};

RootClaimItem.propTypes = {
  claim: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  docs: PropTypes.array,
  users: PropTypes.array,
  loadModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  appconfig: PropTypes.object,
  services: PropTypes.array,
  startPayment: PropTypes.func,
  confirmPayment: PropTypes.func,
  getService: PropTypes.func,
  claimAddService: PropTypes.func,
  refreshClaim: PropTypes.func,
  closeClaim: PropTypes.func,
  claimAddAdmin: PropTypes.func,
  payClaim: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(RootClaimItem);
