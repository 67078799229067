import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../css/style.scss';
import { Link } from 'react-router-dom';
import {
  notifyError,
  notifySuccess,
} from '../../../UI/Notificator/notificatorActions';
import { getGlobalConfig } from '../../../root/AppConfig/store/appconfigActions';
import { authLogin } from './store/login.actions';
import Button from '../../../../containers/UI/Buttons/Button';
import {
  hideBell,
  showBell,
} from '../../../UI/Header/store/headerActions';
import { InputBase } from '../../../../containers/Inputs/InputBase/inputBase';

/**
 * Component Authentication
 *
 * @param {Object} props - component props
 *
 * @returns {JSX.Element}
 */
class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      message: '',
      errors: [],
    };
  }

  componentDidMount() {
    this.props.getAppConfig();
    this.props.hideBell();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.account.auth
      && this.props.account.auth.token
      && this.props.account.auth.token !== prevProps.account.auth.token
    ) {
      this.loginSuccess();
    }
  }

  loginSuccess = () => {
    this.props.notifySuccess(
      `Здравствуйте, ${this.props.account.user.firstName}`,
    );
    this.props.history.push('/');
    this.props.showBell();
  };

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.onSubmit(e);
    }
  };

  onChange = (e) => {
    const { state } = this;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = (e) => {
    if (e) e.preventDefault();

    const { email, password } = this.state;
    this.props.authLogin({
      email,
      password,
    });
  };

  render() {
    const { email, password, message } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-md-offset-3 col-xs-12 col-sm-12 m-auto">
            <form
              className="form-signin"
              onSubmit={this.onSubmit}
              onKeyDown={this.onKeyDown}
            >
              {message && (
                <div
                  className="alert alert-warning alert-dismissible"
                  role="alert"
                >
                  {message}
                </div>
              )}
              <h2 className="pt-5">Войдите в систему</h2>
              <div className="form-group">
                <label htmlFor="inputEmail" className="sr-only">
                  Ваша почта
                </label>
                <InputBase
                  type="email"
                  className="form-control"
                  placeholder="Ваша почта"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputPassword" className="sr-only">
                  Пароль
                </label>
                <InputBase
                  type="password"
                  className="form-control"
                  placeholder="Пароль"
                  name="password"
                  value={password}
                  onChange={this.onChange}
                  required
                />
              </div>
              <Button className="btn-firm-filled w-100" type="submit">
                Войти
              </Button>
              <div className="form--additional-info d-flex justify-content-between mt-2">
                <p className="text-center">
                  Вы у нас впервые? <Link to="/register">Зарегистрируйтесь</Link>
                </p>
                <p className="text-center">
                  Забыли пароль? <Link to="/forgotpassword">Восстановить</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  authLogin: PropTypes.func.isRequired,
  notifySuccess: PropTypes.func.isRequired,
  getAppConfig: PropTypes.func.isRequired,
  hideBell: PropTypes.func.isRequired,
  showBell: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  account: PropTypes.shape({
    auth: PropTypes.shape({
      token: PropTypes.string,
    }).isRequired,
    user: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

Login.defaultProps = {
  authLogin: () => {},
  notifySuccess: () => {},
  getAppConfig: () => {},
  hideBell: () => {},
  showBell: () => {},
  history: {
    push: () => {},
  },
  account: {
    auth: {
      token: '',
    },
    user: {
      firstName: '',
    },
  },
};

const mapStateToProps = (store) => ({
  account: store.account,
});

const mapDispatchToProps = {
  notifySuccess,
  notifyError,
  authLogin,
  getAppConfig: getGlobalConfig,
  hideBell,
  showBell,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
