import React from 'react';
import * as PropTypes from 'prop-types';
import ListSelect from '../../../containers/UI/ListSelect/ListSelect';

const DoctorsSelect = (props) => {
  // filter array in order to delete null elems
  const filteredArr = props.docs.filter((el) => el.user != null);
  // setting options for Select
  const options = filteredArr.map((item, index) => ({
    label: `${item.user.currentProfile.surName} ${item.user.currentProfile.firstName} ${item.user.currentProfile.lastName}`,
    value: item._id,
    key: index,
  }));
  return (
    <div>
      <ListSelect
        fixed={props.fixed || false}
        options={options}
        cb={props.handleSelect}
        placeholder={'Начните вводить имя врача'}
        defaultValue={props.defaultValue}
      />
    </div>
  );
};

DoctorsSelect.propTypes = {
  docs: PropTypes.array,
  handleSelect: PropTypes.func,
  defaultValue: PropTypes.string,
};

export default DoctorsSelect;
