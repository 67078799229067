import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Chip from '@material-ui/core/Chip';
import Button from '../../../../../../../containers/UI/Buttons/Button';
import AxiosClient from '../../../../../../../functional/Axios/AxiosClient';
import { showLoader, hideLoader } from '../../../../../../UI/Loader/store/loaderActions';
import VisitListDetails from '../VisitListDetails/VisitListDetails';
import { DateFormats } from '../../../../../../../const/dateFormats';

class VisitListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      visitDetails: {},
    };
  }

  // TODO: to redux

  fetchVisitDetails = (visitId) => {
    const { profileId } = this.props;
    AxiosClient.post('/users/emrrecdet',
      {
        profileId,
        emrRecId: visitId,
      })
      .then((res) => this.setState({
        visitDetails: res.data,
        showDetails: true,
      }))
      .catch((err) => {
        toast.error('Ошибка загрузки данных');
      });
  }

  hideDetails = () => {
    this.setState({
      showDetails: false,
    });
  }

  render() {
    const { visitDetails, showDetails } = this.state;
    const { visit, profileId } = this.props;
    return (
      <div className="list-group-item mt-2" key={visit?.emrRecId}>
        <div className="d-flex flex-row justify-content-between">
          <small>
            Доктор: <strong>{visit?.docName}</strong>
          </small>
          <small>
            Дата визита: <strong>{moment(visit?.dateOf).format(DateFormats.DAY_MONTH_YEAR)}</strong>
          </small>
        </div>
        <Chip label={visit?.docSpecialisation} size={'small'} className="mt-2"/>
        {
          showDetails && <div className="mt-2">
            {
              visitDetails && <VisitListDetails
                visitDetails={visitDetails}
                hideDetails={this.hideDetails.bind(this)}
                profileId={profileId}
              />
            }
          </div>
        }
        {
          !showDetails && <div>
            {
              !visit?.isPcabBlocked && <div className="mt-2 text-right">Подробная информация недоступна</div>
            }
            <div className="text-right">
              {
                visit?.isPcabBlocked && <Button
                  className="btn-outline-primary btn-sm"
                  onClick={() => this.fetchVisitDetails(visit.emrRecId)}>
                  Детали осмотра
                </Button>
              }
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapDispatchToProps = {
  showLoader, hideLoader,
};

export default connect(null, mapDispatchToProps)(VisitListItem);
