import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { forgotPassword } from '../api/apiForgotPassword';
import { actions } from './ForgotPassword.actions';

const responses = {
  ok: {
    code: 200,
    msg: 'Письмо с инструкциями по восстановлению выслано успешно',
  },
  error: {
    code: 400,
    msg: 'Некорректный запрос',
  },
  serverError: {
    code: 500,
    msg: 'Ошибка соединения с удаленным сервером',
  },
};

export function* forgotPasswordSaga(action) {
  try {
    const data = { email: action.payload.email };
    const result = yield call(forgotPassword, data);
    if (result.status === responses.ok.code) {
      toast.success(result.data);
    } else if (result.response.status === responses.serverError.code) {
      toast.error(responses.serverError.msg);
    } else {
      toast.error(result.response.data.email || result.response.data);
    }
    yield put({ type: actions.FORGOT_PASSWORD_SUCCESS });
  } catch (errors) {
    yield put({ type: actions.FORGOT_PASSWORD_ERROR });
  }
}

export const forgotPasswordSagas = [
  takeLatest([actions.FORGOT_PASSWORD_REQUEST], forgotPasswordSaga),
];
