import {
  call, put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { newsApi } from '../api';
import { actions } from './news.reducer';
import { querystring } from '../../../store/utils/formatters/querystring';

export function* newsSaga() {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const role = yield select((state) => state.account.user.role);
    const response = yield call(newsApi.newsGet, role);
    yield put({
      type: actions.NEWS_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.NEWS_REQUEST_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}

export function* getNewsSaga(action) {
  try {
    const pagination = yield select((state) => state.news.pagination);
    const query = yield select((state) => state.news.query);

    const q = { ...pagination, ...query };

    const response = yield call(newsApi.getNews, { query: querystring(q) });

    yield put({
      type: actions.NEWS_REQUEST_PAGINATED_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.NEWS_REQUEST_PAGINATED_FAILED });
  }
}

export function* getLatestNewsSaga(payload) {
  try {
    const response = yield call(newsApi.getLatestNews, { ...payload });
    yield put({
      type: actions.NEWS_LATEST_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.NEWS_LATEST_REQUEST_FAILED });
  }
}

export const registerNewsSagas = [
  takeLatest([
    actions.NEWS_REQUEST_PAGINATED,
    actions.NEWS_CHANGE_PAGE,
    actions.NEWS_CHANGE_PAGE_RESET,
  ], getNewsSaga),
  takeEvery([actions.NEWS_REQUEST], newsSaga),
  takeEvery([actions.NEWS_LATEST_REQUEST], getLatestNewsSaga),
];
