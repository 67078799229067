/**
 * Login actions
 */
export const actions = {
  AUTH_REQUEST: 'AUTH:REQUEST',
  AUTH_REQUEST_SUCCESS: 'AUTH:REQUEST_SUCCESS',
  AUTH_REQUEST_FAILED: 'AUTH:REQUEST_FAILED',
  AUTH_TWOFACTOR: 'AUTH:TWOFACTOR',
  AUTH_TWOFACTOR_SUCCESS: 'AUTH:TWOFACTOR_SUCCESS',
  AUTH_TWOFACTOR_FAILED: 'AUTH:TWOFACTOR_FAILED',
  AUTH_USER_PROFILE_CHANGE: 'AUTH:USER_CHANGE_PROFILE',
  AUTH_USER_PROFILE_CHANGE_SUCCESS: 'AUTH:USER_CHANGE_PROFILE_SUCCESS',
  AUTH_USER_PROFILE_CHANGE_FAILED: 'AUTH:USER_CHANGE_PROFILE_FAILED',
};

/**
 * Login
 *
 * @param {Object} obj - action payload data
 *
 * @returns {actions.AUTH_REQUEST}
 */
export function authLogin(obj) {
  return {
    type: actions.AUTH_REQUEST,
    payload: { ...obj },
  };
}

/**
 * Change users profile
 *
 * @returns {actions.AUTH_USER_PROFILE_CHANGE}
 */
export function changeUserProfile(profileId) {
  return {
    type: actions.AUTH_USER_PROFILE_CHANGE,
    payload: { profileId },
  };
}
