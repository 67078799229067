import withPagination from '../../../../store/utils/mutators/withPagination';
import withQuery from '../../../../store/utils/mutators/withQuery';
import withFetch from '../../../../store/utils/mutators/withFetch';

export const defaultState = {
  elements: null,
  _elements: null, // cache
  filter: [],
  fetch: false,
  error: false,
};

const initialState = {
  ...defaultState,
  ...withQuery(defaultState),
  ...withPagination(defaultState, 12),
  ...withFetch(defaultState),
};

export const actions = {
  CLAIMS_REQUEST: 'CLAIMS:REQUEST',
  CLAIMS_REQUEST_SUCCESS: 'CLAIMS:REQUEST_SUCCESS',
  CLAIMS_REQUEST_FAILED: 'CLAIMS:REQUEST_FAILED',
  CLAIMS_FILTER: 'CLAIMS:FILTER',
  CLAIMS_RESET_FILTER: 'CLAIMS:RESET_FILTER',

  CLAIMS_WIPE: 'CLAIMS:WIPE',

  DOCTOR_SERVICES_REQUEST: 'DOCTOR_SERVICES:REQUEST',
  DOCTOR_SERVICES_REQUEST_SUCCESS: 'DOCTOR_SERVICES:REQUEST_SUCCESS',
  DOCTOR_SERVICES_REQUEST_FAILED: 'DOCTOR_SERVICES:REQUEST_FAILED',

  CLAIMS_CHANGE_PAGE: 'CLAIMS:CHANGE_PAGE',
  CLAIMS_CHANGE_PAGE_SUCCESS: 'CLAIMS:CHANGE_PAGE_SUCCESS',
  CLAIMS_CHANGE_PAGE_FAILED: 'CLAIMS:CHANGE_PAGE_FAILED',
  CLAIMS_CHANGE_PAGE_RESET: 'CLAIMS:CHANGE_PAGE_RESET',

  CLAIM_ADD_TRANSACTION_REQUEST: 'CLAIM_ADD_TRANSACTION:REQUEST',
  CLAIM_ADD_TRANSACTION_REQUEST_SUCCESS: 'CLAIM_ADD_TRANSACTION:REQUEST_SUCCESS',
  CLAIM_ADD_TRANSACTION_REQUEST_FAILED: 'CLAIM_ADD_TRANSACTION:REQUEST_FAILED',

  CLAIM_ADD_SERVICE_REQUEST: 'CLAIM_ADD_SERVICE:REQUEST',
  CLAIM_ADD_SERVICE_REQUEST_SUCCESS: 'CLAIM_ADD_SERVICE:REQUEST_SUCCESS',
  CLAIM_ADD_SERVICE_REQUEST_FAILED: 'CLAIM_ADD_SERVICE:REQUEST_FAILED',

  CLAIM_ADD_ADMIN_REQUEST: 'CLAIM_ADD_ADMIN:REQUEST',
  CLAIM_ADD_ADMIN_REQUEST_SUCCESS: 'CLAIM_ADD_ADMIN:REQUEST_SUCCESS',
  CLAIM_ADD_ADMIN_REQUEST_FAILED: 'CLAIM_ADD_ADMIN:REQUEST_FAILED',

  CLAIM_DELETE_ADMIN_REQUEST: 'CLAIM_DELETE_ADMIN:REQUEST',
  CLAIM_DELETE_ADMIN_REQUEST_SUCCESS: 'CLAIM_DELETE_ADMIN:REQUEST_SUCCESS',
  CLAIM_DELETE_ADMIN_REQUEST_FAILED: 'CLAIM_DELETE_ADMIN:REQUEST_FAILED',

  CLAIM_RESET_REQUEST: 'CLAIM_RESET:REQUEST',
  CLAIM_RESET_REQUEST_SUCCESS: 'CLAIM_RESET:REQUEST_SUCCESS',
  CLAIM_RESET_REQUEST_FAILED: 'CLAIM_RESET:REQUEST_FAILED',

  CLAIM_CLOSE_REQUEST: 'CLAIM_CLOSE:REQUEST',
  CLAIM_CLOSE_REQUEST_SUCCESS: 'CLAIM_CLOSE:REQUEST_SUCCESS',
  CLAIM_CLOSE_REQUEST_FAILED: 'CLAIM_CLOSE:REQUEST_FAILED',

  CLAIM_PAY_REQUEST: 'CLAIM_PAY:REQUEST',
  CLAIM_PAY_REQUEST_SUCCESS: 'CLAIM_PAY:REQUEST_SUCCESS',
  CLAIM_PAY_REQUEST_FAILED: 'CLAIM_PAY:REQUEST_FAILED',

  CLAIM_BY_ADMIN_CREATE_REQUEST: 'CLAIM_BY_ADMIN_CREATE:REQUEST',
  CLAIM_BY_ADMIN_CREATE_REQUEST_SUCCESS: 'CLAIM_BY_ADMIN_CREATE:REQUEST_SUCCESS',
  CLAIM_BY_ADMIN_CREATE_REQUEST_FAILED: 'CLAIM_BY_ADMIN_CREATE:REQUEST_FAILED',
};

function claimsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLAIMS_REQUEST:
      return {
        ...state,
        fetch: true,
        query: action.payload || '',
      };

    case actions.CLAIMS_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload.claims,
        _elements: action.payload.claims,
        fetch: false,
        pagination: {
          ...state.pagination,
          end: false,
          count: action.payload.count,
        },
      };

    case actions.CLAIMS_REQUEST_FAILED:
      return {
        ...state,
        fetch: false,
        elements: [],
        _elements: [],
      };

    case actions.CLAIMS_CHANGE_PAGE:
      return {
        ...state,
        fetch: true,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          limit: state.pagination.limit,
        },
      };

    case actions.CLAIMS_CHANGE_PAGE_RESET:
      return {
        ...state,
        fetch: true,
        pagination: {
          ...initialState.pagination,
        },
      };

    case actions.CLAIMS_CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        fetch: false,
        pagination: { ...state.pagination },
      };

    case actions.DOCTOR_SERVICES_REQUEST:
      return {
        ...state,
        doctorsServicesfetch: true,
      };

    case actions.DOCTOR_SERVICES_REQUEST_SUCCESS:
      return {
        ...state,
        doctorServices: action.payload,
        doctorsServicesfetch: false,
      };

    case actions.CLAIMS_RESET_FILTER:
      return {
        ...state,
        elements: state._claims,
        count: state._claims?.length,
      };

    case actions.CLAIMS_WIPE:
      return {
        elements: null,
        _elements: null,
        filter: [],
        fetch: false,
      };

    case actions.CLAIM_ADD_TRANSACTION_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.CLAIM_ADD_TRANSACTION_REQUEST_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.CLAIM_ADD_TRANSACTION_REQUEST_FAILED:
      return {
        ...state,
        fetch: false,
      };

    case actions.CLAIM_ADD_SERVICE_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.CLAIM_ADD_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.CLAIM_ADD_SERVICE_REQUEST_FAILED:
      return {
        ...state,
        fetch: false,
      };

    case actions.CLAIM_RESET_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.CLAIM_RESET_REQUEST_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.CLAIM_RESET_REQUEST_FAILED:
      return {
        ...state,
        fetch: false,
      };

    case actions.CLAIM_CLOSE_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.CLAIM_CLOSE_REQUEST_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.CLAIM_CLOSE_REQUEST_FAILED:
      return {
        ...state,
        fetch: false,
      };

    case actions.CLAIM_PAY_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.CLAIM_PAY_REQUEST_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.CLAIM_PAY_REQUEST_FAILED:
      return {
        ...state,
        fetch: false,
      };

    case actions.CLAIM_ADD_ADMIN_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.CLAIM_ADD_ADMIN_REQUEST_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.CLAIM_ADD_ADMIN_REQUEST_FAILED:
      return {
        ...state,
        fetch: false,
      };

    case actions.CLAIM_DELETE_ADMIN_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.CLAIM_DELETE_ADMIN_REQUEST_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.CLAIM_DELETE_ADMIN_REQUEST_FAILED:
      return {
        ...state,
        fetch: false,
      };

    case actions.CLAIM_BY_ADMIN_CREATE_REQUEST:
      return {
        ...state,
      };

    case actions.CLAIM_BY_ADMIN_CREATE_REQUEST_SUCCESS:
      return {
        ...state,
        error: false,
      };

    case actions.CLAIM_BY_ADMIN_CREATE_REQUEST_FAILED:
      return {
        ...state,
        error: true,
      };

    default:
      return { ...state };
  }
}

export default claimsReducer;
