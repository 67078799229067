import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import ClaimItemDoctorError from '../ClaimItemDoctorError/ClaimIlaitemDoctorError';
import ListSelect from '../../../../containers/UI/ListSelect/ListSelect';
import { CLAIM_TYPES } from '../../const/constants';

const ClaimItemDoctorInfo = (props) => {
  const {
    claim, account, docs, services, selectService,
  } = props;

  /**
   * Renders doctor info and doctor services (result depends on users role)
   * Should be refactored without filter docs (populate it in a right wat)
   * @param id
   */
  const _doctorInfo = (id) => {
    const renderServiceForAdminCondition = claim.status !== 7
        && claim.status !== 8
        && claim.status !== 2
        && claim.status !== 9 && claim.claimType !== CLAIM_TYPES.REQUEST_APPOINTMENT;

    if (account.user?.role === 1) {
      if (!isEmpty(docs)) {
        const d = docs.filter((doc) => doc._id === id);
        if (!isEmpty(d)) {
          return <div className="docName">
            <b>Доктор:</b> {d[0].user?.currentProfile?.surName} {d[0].user?.currentProfile?.firstName} {d[0].user?.currentProfile?.lastName}
          </div>;
        }
        return <ClaimItemDoctorError title="Врач назначен, но информация о нем недоступна."/>;
      }
    }
    if (account.user?.role === 2 && services
      && claim.status !== 7
      && claim.status !== 8
      && claim.status !== 2
      && claim.status !== 9) {
      return <div>
        <div className="font-weight-bold mt-1">
          {(claim.status === 5 || claim.status === 1) ? 'Переназначить услугу: ' : 'Назначить услугу:  '}
        </div>
        <ListSelect
          options={services.map((s) => ({
            value: s._id,
            label: s.title,
          }))}
          cb={selectService}/>
      </div>;
    }
    if (account.user?.role === 3 || account.user?.role === 99) {
      if (!isEmpty(docs)) {
        const d = docs.filter((doc) => doc._id === id);
        if (!isEmpty(d)) {
          return <div>
            <b>Доктор:</b> {d[0].user?.currentProfile?.surName} {d[0].user?.currentProfile?.firstName} {d[0].user?.currentProfile?.lastName}
            {((account.user?.role === 99 || account.user?.role === 3 || account.user?.role === 2)
              && d[0].services && renderServiceForAdminCondition)
            && <div>
              <div className="font-weight-bold mt-1">
                {(claim.status === 5 || claim.status === 1) ? 'Переназначить услугу: ' : 'Назначить услугу:  '}
              </div>
              <ListSelect
                options={d[0].services.map((s) => ({
                  value: s._id,
                  label: s.title,
                }))}
                cb={selectService}/>
            </div>}
          </div>;
        }
        return <ClaimItemDoctorError title="Врач назначен, но информация о нем недоступна."/>;
      }
    }
  };
  return (
    <div className="col-6">
      <div>
        {claim.doctor && _doctorInfo(claim.doctor)}
      </div>
    </div>
  );
};

ClaimItemDoctorInfo.propTypes = {
  claim: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  docs: PropTypes.array,
  services: PropTypes.array,
  selectService: PropTypes.func.isRequired,
};

export default ClaimItemDoctorInfo;
