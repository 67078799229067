import { call, put } from 'redux-saga/effects';
import { actions } from './directionsReducer';
import { actions as notifyActions } from '../../UI/Notificator/notificatorReducer';
import { getDirectionDaysById } from '../api';

export function* directionGetDaysByIdSaga(payload) {
  try {
    const response = yield call(getDirectionDaysById, { ...payload });
    yield put({
      type: actions.DIRECTIONS_GET_DAYS_SUCCESS,
      payload: response.response.data,
    });
  } catch (error) {
    yield put({ type: actions.DIRECTIONS_GET_DAYS_FAILED });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка загрузки данных',
    });
  }
}
