export const initialState = {
  elements: [],
  elementsFiltered: [],
  fetching: false,
  availableDays: [],
  daysFetching: false,
};

export const actions = {
  DIRECTIONS_REQUEST: ' DIRECTIONS:REQUEST',
  DIRECTIONS_REQUEST_SUCCESS: ' DIRECTIONS:REQUEST_SUCCESS',
  DIRECTIONS_REQUEST_FAILED: ' DIRECTIONS:REQUEST_FAILED',
  DIRECTIONS_UPDATE: 'DIRECTIONS:UPDATE',
  DIRECTIONS_UPDATE_SUCCESS: 'DIRECTIONS:UPDATE_SUCCESS',
  DIRECTIONS_UPDATE_FAILED: 'DIRECTIONS:UPDATE_FAILED',
  DIRECTIONS_GET_DAYS_REQUEST: 'DIRECTIONS_GET_DAYS:REQUEST',
  DIRECTIONS_GET_DAYS_SUCCESS: 'DIRECTIONS_GET_DAYS:REQUEST_SUCCESS',
  DIRECTIONS_GET_DAYS_FAILED: 'DIRECTIONS_GET_DAYS:REQUEST_FAILED',
};

export function directionsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.DIRECTIONS_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.DIRECTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        elementsFiltered: action.payload,
        fetching: false,
      };

    case actions.DIRECTIONS_UPDATE:
      return {
        ...state,
        fetching: true,
      };

    case actions.DIRECTIONS_UPDATE_SUCCESS:
      return {
        ...state,
        elementsFiltered: action.payload,
        fetching: false,
      };

    case actions.DIRECTIONS_GET_DAYS_REQUEST:
      return {
        ...state,
        daysFetching: true,
      };

    case actions.DIRECTIONS_GET_DAYS_SUCCESS:
      return {
        ...state,
        availableDays: action.payload,
        daysFetching: false,
      };

    case actions.DIRECTIONS_GET_DAYS_FAILED:
      return {
        ...state,
        availableDays: action.payload,
        daysFetching: false,
      };

    default:
      return { ...state };
  }
}

export default directionsReducer;
