import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery/useMediaQuery.hook';
import { getWidgetsLayout } from '../utils';
import { screenSizes } from './const';

const ResponsiveGridLayout = WidthProvider(Responsive);

const WidgetsGridContainer = (props) => {
  const isDesktop = useMediaQuery(screenSizes.DESKTOP);
  const isTablet = useMediaQuery(screenSizes.TABLET);
  const isMobile = useMediaQuery(screenSizes.MOBILE);

  const widgetsToRender = getWidgetsLayout({ isDesktop, isTablet, isMobile }, props);

  const layouts = {
    lg: [
      ...widgetsToRender
    ],
    xs: [
      ...widgetsToRender
    ],
    md: [
      ...widgetsToRender
    ],
  };

  return (
      <ResponsiveGridLayout
          className="layout"
          layouts={layouts}
          containerPadding={[0, 0]}
          rowHeight={50}
          isResizable={false}
          isDraggable={false}
          allowOverlap={false}
          margin={[30, 15]}
          breakpoints={{
            lg: 1200, md: 800, xs: 480,
          }}
          cols={{
            lg: 12, md: 12, xs: 6,
          }}>
      {
          widgetsToRender?.map((widget) => (
          <div key={widget.i} className="react-resizable">
            {widget.component}
          </div>
          ))
      }
    </ResponsiveGridLayout>
  );
};
export default WidgetsGridContainer;
