import React from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import CancelClaims from '../../../UI/Modal/Modals/CancelClaim/CancelClaim';
import ClaimItemButtons from '../ClaimItemButtons/ClaimItemButtons';
import ClaimItemService from '../ClaimItemService/ClaimItemService';
import ClaimItemDateTime from '../ClaimItemDateTime/ClaimItemDateTime';
import ClaimItemDirection from '../ClaimItemDirection/ClaimItemDirection';
import ClaimItemComment from '../ClaimItemComment/ClaimItemComment';
import ClaimItemPatient from '../ClaimItemPatient/ClaimItemPatient';
import ClaimItemDoctorInfo from '../ClaimItemDoctorInfo/ClaimItemDoctorInfo';
import ClaimItemStatus from '../ClaimItemStatus/ClaimItemStatus';
import { CLAIM_TYPES } from '../../const/constants';
import ClaimItemFiles from '../ClaimItemFiles/ClaimItemFiles';
import downloadBlob from '../../../../utils/downloadBlob';
import { claimAddService, closeClaim } from '../../../root/Claim/store/claims.actions';
import { hideModal, loadModal } from '../../../UI/Modal/actions/ModalActions';
import { claimsApi } from '../../../root/Claim/store/api/apiClaims';
import { ACCESS_ROLES, GROUPS, RBAC } from '../../../../functional/roles';
import ClaimItemType from '../ClaimItemType/ClaimItemType';

const DoctorClaimItem = (props) => {
  const {
    claim, fetch, docs,
    loadModal, hideModal,
    account, appconfig, services,
    claimAddService, closeClaim,
  } = props;

  const secondOpinionServices = services && services.filter((s) => s.type === CLAIM_TYPES.SECOND_OPINION);
  const callDoctorServices = services && services.filter((s) => s.type === CLAIM_TYPES.CALL_DOCTOR);

  const selectService = (serviceId) => {
    confirmAlert({
      title: 'Назначить эту услугу?',
      message: 'Вы можете подтвердить и выставить счет или отменить выбор',
      buttons: [
        {
          label: 'Да',
          onClick: () => {
            claimAddService({ serviceId, claim });
            return true;
          },
        },
        {
          label: 'Нет',
          onClick: () => false,
        },
      ],
    });
  };

  const toggleModal = () => {
    loadModal({
      component: CancelClaims,
      args: {
        claim,
        fetch,
        hideModal,
      },
    });
  };

  const claimCloseSuccess = () => {
    confirmAlert({
      title: 'Успешно завершить услугу?',
      message: 'Успешное завершение услуги после ее корректного оказания.',
      buttons: [
        {
          label: 'Да',
          onClick: () => {
            closeClaim(claim._id);
            return true;
          },
        },
        {
          label: 'Нет',
          onClick: () => false,
        },
      ],
    });
  };

  const downloadFile = async (filename) => {
    try {
      const result = await claimsApi.claimDownloadFile(filename);
      const { data, headers } = result;
      downloadBlob(data, headers?.filename);
    } catch (err) {
      toast.error('Ошибка загрузки файла');
    }
  };

  const isUserAdministrative = RBAC(account, GROUPS.ADMINISTRATIVE);

  return (
    <div key={claim._id} className="list-group mb-3">
      <Paper elevation={3} className={`list-group-item list-group-item-action wl__list-item
        ${(claim?.transaction && account?.user?.role !== 1) && '__light-warning'}
        ${(claim?.status === 7 && account?.user?.role !== 1) && '__light-success'}
        ${(claim?.status === 2 || claim?.status === 9) && (account.user.role !== 1) && '__light-danger'}
        ${(claim?.status === 8 && account?.user?.role !== 1) && '__success'}`}
             variant="outlined">
        <div className="row">
          <div className="col-12 mb-2">
            <ClaimItemType claim={claim}/>
          </div>
          <ClaimItemDateTime claim={claim}/>

          <div className="col-12 d-sm-flex flex-row p-0">
            <ClaimItemPatient claim={claim} link={isUserAdministrative}/>
            <ClaimItemDoctorInfo
                claim={claim}
                account={account}
                docs={docs}
                services={claim.claimType === CLAIM_TYPES.SECOND_OPINION ? secondOpinionServices : callDoctorServices}
                selectService={selectService}
            />
          </div>
          <div className="col-12 d-sm-flex flex-row justify-content-between">
            {
              claim?.direction && <ClaimItemDirection claim={claim}/>
            }
          </div>
          <div className="col-12 p-2">
            <Divider/>
          </div>
          <ClaimItemComment appconfig={appconfig} claim={claim}/>
          {
            (claim?.attachedFiles && !_isEmpty(claim?.attachedFiles))
            && <ClaimItemFiles
              downloadFile={downloadFile}
              claim={claim}
            />
          }
          <div className="col-12 p-2">
            <Divider/>
          </div>
          <ClaimItemStatus claim={claim}/>
          {
            account?.user?.role !== +ACCESS_ROLES.USER
            && <ClaimItemButtons
              claim = {claim}
              toggleModal={toggleModal}
              claimCloseSuccess={claimCloseSuccess}
            />
          }
          <ClaimItemService claim={claim} account={account}/>
        </div>
      </Paper>
      <Divider/>
    </div>
  );
};

const mapDispatchToProps = {
  hideModal,
  loadModal,
  claimAddService,
  closeClaim,
};

DoctorClaimItem.propTypes = {
  claim: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  docs: PropTypes.array,
  loadModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  appconfig: PropTypes.object,
  services: PropTypes.array,
  startPayment: PropTypes.func,
  confirmPayment: PropTypes.func,
  getService: PropTypes.func,
  claimAddService: PropTypes.func,
  closeClaim: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(DoctorClaimItem);
