import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { notifyError, notifySuccess } from '../../Notificator/notificatorActions';
import { changeUserProfile } from '../../../Auth/components/Login/store/login.actions';
import { getUser } from '../../../Auth/store/auth.actions';
import { roleChecker } from '../../../../utils/rolesChecker';
import '../header.scss';
import {
  showBell,
  userLogout,
  userMenuHide,
  userMenuToggle,
  userProfilesToggle,
} from '../store/headerActions';

import SessionStatusBar from '../../Session';
import { screenSizes } from '../../Widgets/Widgets/WidgetsGridContainer/const';

const ProfileListItem = ({ elem, index, handler }) => {
  if (elem._id) {
    return (
      <div>
        <a href="#" className="header--dropdown-profile-select text-dark"
           onClick={() => handler(elem._id)}>
          {index + 1}. {elem.firstName} {elem.surName}
        </a>
      </div>
    );
  }
  return '';
};

const ProfileList = ({ account, changeProfile }) => {
  if (account && account.user && account.user.profiles && account.user.profiles.length > 0) {
    return (
      <div>
        {account.user.profiles.map((elem, index) => <ProfileListItem key={elem._id} elem={elem}
                                                                     index={index}
                                                                     handler={changeProfile}/>)}
      </div>
    );
  }
  return <div className="header--dropdown-profile-select text-danger"
              style={{ width: '15.5rem' }}>Профили отсутствуют</div>;
};

const Dropdown = ({
  toggleUserProfiles, userProfilesVisible, logout, account, changeProfile,
}) => (
    <div className="header--dropdown" style={{ width: '15.5rem' }}>
      {
        roleChecker.isUser(account.user.role) &&
        <button className="btn btn-link btn-block __nb text-dark text-left"
                onClick={toggleUserProfiles}>
          Сменить профиль
        </button>
      }

      {userProfilesVisible && <ProfileList account={account} changeProfile={changeProfile}/>}

      {roleChecker.isRoot(account.user.role) && <Link to={'/root/settings'}
                                                      className="btn-block btn btn-link __nb text-dark text-left">Настройки</Link>}

      {roleChecker.isRoot(account.user.role) && <Link to={'/root/info'}
                                                      className="btn-block btn btn-link __nb text-dark text-left">Информация</Link>}

      {roleChecker.isUser(account.user.role) && <Link to={'/usersettings'}
                                                    className="btn-block btn btn-link __nb text-dark text-left">Настройки</Link>}

      {roleChecker.isDoctor(account.user.role) && <Link to={'/doctorsettings'}
                                                      className="btn-block btn btn-link __nb text-dark text-left">Настройки</Link>}

      {roleChecker.isUser(account.user.role) && <Link to={'/userfiles'}
                                                      className="btn-block btn btn-link __nb text-dark text-left">Файлы</Link>}

      <button className="btn text-danger btn-link" onClick={logout}>Выйти</button>
    </div>
);

class UserMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      matches: window.matchMedia(screenSizes.DESKTOP).matches
    };
  }

  componentDidMount() {
    this.props.showBell();
    const handler = e => this.setState({ matches: e.matches });
    window.matchMedia(screenSizes.DESKTOP).addEventListener('change', handler);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.props.userMenuHide();
    }
  };

  componentDidUpdate() {
    if (this.props.header.menuVisible === false) {
      document.removeEventListener('click', this.handleClickOutside, true);
    }
  }

  handleClick = () => {
    this.props.userMenuToggle();
    if (!this.props.header.menuVisible) {
      document.addEventListener('click', this.handleClickOutside, true);
    }
  };

  render() {
    const {
      account, session, header,
      userProfilesToggle, userLogout, changeUserProfile
    } = this.props;
    const { matches } = this.state;

    return (
      <div>
        {
          (session && matches) && <button
            className="mt-1 btn-outline-dark btn-sm btn d-flex justify-content-between align-content-center"
            onClick={this.handleClick}>
            <SessionStatusBar/>
            <Tooltip title="Вы оффлайн" arrow open={!session}>
              <div className="text-nowrap">{account.user.firstName} {account.user.surName}</div>
            </Tooltip>
          </button>
        }
        {
          (session && !matches) && <div className="mt-2" onClick={this.handleClick} role="button">
            <FontAwesomeIcon icon={faBars}/></div>
        }
        {
          header.menuVisible && <Dropdown
            toggleUserProfiles={userProfilesToggle}
            userMenuVisible={header.menuVisible}
            userProfilesVisible={header.changeProfileVisible}
            account={account}
            logout={userLogout}
            changeProfile={changeUserProfile}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  account: store.account,
  header: store.components.header,
  session: store.account.session.online,
});

const mapDispatchToProps = {
  notifySuccess,
  notifyError,
  changeUserProfile,
  userMenuToggle,
  userMenuHide,
  userProfilesToggle,
  userLogout,
  getUser,
  showBell,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
