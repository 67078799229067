import { actions } from '../store/sidePanel.reducer';

/**
 * Action creator
 * @param payload
 * @returns {{payload, type: string}}
 */
export function loadSidePanel(payload) {
  return {
    type: actions.LOAD_SIDE_PANEL,
    payload: { ...payload },
  };
}

/**
 * Action creator
 * @returns {{type: string}}
 */
export function hideSidePanel() {
  return {
    type: actions.HIDE_SIDE_PANEL,
  };
}

/**
 * Action creator
 * @returns {{type: string}}
 */
export function showSidePanel() {
  return {
    type: actions.SHOW_SIDE_PANEL,
  };
}
