import React, { Component } from 'react';
import Peer from 'peerjs';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SocketManager } from '../../../../../../ioc/socketManager';
import { VideoInitiator } from './components/VideoInitiator';
import { VideoContainer } from './components/VideoContainer';
import { webrtcStatus } from './constants/webrtcStatus';
import { VideoFinisher } from './components/VideoFinisher';
import { videoChatSocketEvents } from './constants/videoChatSocketEvents';
import { hideDialog, loadDialog, showDialog } from '../../../../../UI/Dialog/actions/dialog.actions';
import { DoctorIsReadyText, UserIsReadyText } from './components/VideoChatDialogPlaceholders';

class VideoChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webrtcConnectionStatus: 0,
      isVideoGoes: false,
      isHost: false,

      peerToken: null,
      peerServer: null,
    };

    this.localStream = null;
  }

  componentDidMount() {
    SocketManager.ws.emit(videoChatSocketEvents.GET_PEER_SERVER);
    SocketManager.ws.on(videoChatSocketEvents.GET_PEER_SERVER, (data) => {
      this.setState({ peerServer: data });
    });

    SocketManager.ws.on(videoChatSocketEvents.WEBRTC_REJECT_CALL, () => {
      this.rejectCall();
      toast.info('Пользователь отклонил звонок');
    });

    SocketManager.ws.on(videoChatSocketEvents.WEBRTC_SYNC, (data) => {
      console.log('Syncing between participants...');
      this.props.loadDialog({
        title: 'Входящий звонок',
        message: <DoctorIsReadyText/>,
        buttons: [
          {
            label: 'Отклонить',
            onClick: () => {
              this.rejectCall(true);
              this.props.hideDialog();
            },
          },
          {
            label: 'Начать',
            onClick: () => {
              if (!this.peer) {
                this.initPeerConnection();
                this.callWithVideo(data.peerToken);
              } else {
                this.callWithVideo(data.peerToken);
              }
              this.props.hideDialog();
            },
          },
        ],
      });
    });

    SocketManager.ws.on(videoChatSocketEvents.WEBRTC_CANCEL, () => {
      this.rejectCall();
    });
  }

  componentWillUnmount() {
    this.cancelCall();
  }

  /**
   * Used while answering to call
   * @param call
   * @returns {Promise<void>}
   */
  answerCall = async (call) => {
    console.log('Incoming call...');

    this.props.loadDialog({
      title: 'Мы почти готовы...',
      message: <UserIsReadyText/>,
      buttons: [
        {
          label: 'Отклонить',
          onClick: () => {
            this.rejectCall(true);
            this.props.hideDialog();
          },
        },
        {
          label: 'Начать!',
          onClick: () => {
            this.setState({ webrtcConnectionStatus: 2 }, async () => {
              call.on('stream', (rstream) => {
                this.playVideo(rstream, 'incoming');
              });

              const localStream = await this.getLocalStream();
              if (localStream) {
                this.localStream = localStream;

                this.setState({
                  webrtcConnectionStatus: 3,
                  isVideoGoes: true,
                }, () => {
                  call.answer(localStream);
                  this.playVideo(localStream, 'outcoming');
                });
              } else {
                toast.error('Нет разрешения пользователя на получение видео от браузера. Проверьте настройки');
                this.peer.disconnect();
              }
              this.props.hideDialog();
            });
          },
        },
      ],
    });
  };

  initVideoChat = () => {
    this.initPeerConnection();
  }

  /**
   * Starter function, initiate peer connection and make call after
   * @returns {boolean}
   */
  initPeerConnection = () => {
    const peer = this.initPeer();

    if (!peer) return false;
    /**
     * On open sync tokens...
     */
    peer.on('open', (id) => {
      console.log('Peer opened');
      this.setState({
        peerToken: id,
        webrtcConnectionStatus: 1,
      });
      // host
      if (this.props.account.user.role === 2) {
        SocketManager.ws.emit(videoChatSocketEvents.WEBRTC_SYNC_HOST, {
          peerToken: id,
          chatId: this.props.webrtc.id,
        });
      }

      peer.on('call', async (call) => {
        if (window.location.pathname.includes('chats')) {
          await this.answerCall(call);
        } else {
          this.destroyPeer();
        }
      });
    });

    peer.on('close', () => {
      this.cancelCall();
    });
  };

  /**
   * Initialize peer connection
   * @returns {Peer|null|boolean}
   */
  initPeer = () => {
    const { peerServer } = this.state;

    console.log('Initialized peer...');

    if (peerServer) {
      const peerPath = this.state.peerServer.split('/');
      this.peer = new Peer({
        host: peerPath[0],
        path: `${peerPath[1]}/${peerPath[2]}`,
        secure: true,
      });

      this.peer.on('error', (e) => {
        toast.error('Не удалось установить соединение с сервером. Видеосвязь невозможна');
        console.log(e);
      });
      return this.peer;
    }
    toast.error('В настройках приложения не обнаружен адрес сервера. Видео-связь невозможна');
    return false;
  };

  /**
   * Initiate call with video
   * @param peerToken
   */
  callWithVideo = async (peerToken) => {
    console.log('Calling...');
    const localStream = await this.getLocalStream();
    if (localStream) {
      this.localStream = localStream;

      this.setState({ webrtcConnectionStatus: 2 });
      const call = this.peer.call(peerToken, localStream);

      if (call) {
        call.on('stream', (rstream) => {
          this.setState({
            webrtcConnectionStatus: 3,
            isVideoGoes: true,
          }, () => {
            this.playVideo(rstream, 'incoming');
            this.playVideo(localStream, 'outcoming');
          });
        });

        call.on('close', () => {
          this.stopStreams();
        });

        call.on('error', () => {
          this.stopStreams();
        });
      }
    } else {
      toast.error('Невозможно получить видео и аудио с вашего устройства.');
      this.setState({ isVideoGoes: false });
      this.destroyPeer();
    }
  };

  /**
   * Reject call handler
   * Used while users did decline call
   * @param emit
   */
  rejectCall = (emit) => {
    this.setState({
      isVideoGoes: false,
      webrtcConnectionStatus: 0,
    }, () => {
      this.stopStreams();
      this.destroyPeer();
    });

    if (emit) {
      SocketManager.ws.emit(videoChatSocketEvents.WEBRTC_REJECT_CALL, { chatId: this.props.webrtc.id });
    }
  };

  /**
   * Disconnect and destroy peer
   */
  destroyPeer = () => {
    if (this.peer && this.peer.open) {
      this.peer.disconnect();
      this.peer.destroy();
      this.peer = null;
    }
  };

  /**
   * Event fired while peer is disconnected or destroyed
   * Do not use manually
   */
  cancelCall = () => {
    console.log('Cancel call');
    const video = document.getElementById('outcoming');
    if (video) {
      const stream = video.srcObject;

      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((t) => {
          t.stop();
        });

        video.srcObject = null;
      }

      this.stopStreams();
    }

    this.setState({
      isVideoGoes: false,
      webrtcConnectionStatus: 0,
    }, () => {
      this.destroyPeer();
      toast.info('Видео-звонок окончен');
      SocketManager.ws.emit(videoChatSocketEvents.WEBRTC_CANCEL, { chatId: this.props.webrtc.id });
    });
  };

  stopStreams = () => {
    if (this.localStream) {
      this.localStream.getTracks()
        .forEach((track) => {
          track.stop();
        });
    }
    this.localStream = null;
  };

  playVideo = (stream, selector) => {
    console.log('take video...');

    const video = document.getElementById(selector);
    if (video) {
      console.log(stream);
      if ('srcObject' in video) {
        video.srcObject = stream;
      } else {
        video.src = window.URL.createObjectURL(stream); // for older browsers
      }
      setTimeout(() => {
        video.play()
          .then();
      }, 400);
    }
  };

  /**
   * Returns false or media stream
   * @returns {Promise<MediaStream|boolean>}
   */
  getLocalStream = async () => {
    const constraints = {};

    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      console.log(devices);
      for (let i = 0; i < devices.length; i++) {
        if (devices[i] && devices[i].kind === 'audioinput') {
          constraints.audio = true;
        }
        if (devices[i] && devices[i].kind === 'videoinput') {
          constraints.video = {
            width: { ideal: 1280 },
            height: { ideal: 720 }
          };
        }
      }

      if (!constraints.audio || !constraints.video) {
        toast.error('Ошибка: не найдены устройства аудио-видео. Видео связь невозможна.');
        return false;
      }
      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      if (stream) {
        return stream;
      }
    } catch (e) {
      return false;
    }
  };

  render() {
    const { isVideoGoes, webrtcConnectionStatus } = this.state;
    const { account } = this.props;
    return (
      <>
        {
          isVideoGoes && <div className="chat-container--video">
            <VideoContainer id="incoming" visible={webrtcConnectionStatus === 3}/>
            <VideoContainer id="outcoming" visible={webrtcConnectionStatus === 3}/>
          </div>
        }
        <VideoInitiator startCallback={this.initVideoChat} webrtcStatus={webrtcConnectionStatus} userRole={account.user.role}/>
        <VideoFinisher videoChat={isVideoGoes} webrtcStatus={webrtcConnectionStatus} cancelCall={this.destroyPeer}/>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  account: store.account,
});

const mapDispatchToProps = {
  showDialog,
  hideDialog,
  loadDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoChat);

VideoChat.propTypes = {
  webrtc: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  account: PropTypes.shape({
    user: PropTypes.shape({
      role: PropTypes.number.isRequired,
    }),
  }),

  hideDialog: PropTypes.func,
  loadDialog: PropTypes.func,
};
