import React, { useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons/faTimesCircle';
import PropTypes from 'prop-types';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import randomBytes from '../../../../functional/randomBytes';

const ChatFile = (props) => {
  const {
    isUploading,
    file,
  } = props;
  const hash = randomBytes();

  function onHover() {
    const fileContainer = document.getElementsByClassName(hash)[0];
    fileContainer.classList.remove('text-black-50');
  }

  function onOut() {
    const fileContainer = document.getElementsByClassName(hash)[0];
    fileContainer.classList.add('text-black-50');
  }

  if (typeof props.cb !== 'function') throw new Error('Callback should provided');
  if (props.delete && typeof props.deletecb !== 'function') throw new Error('Delete callback should provided');

  if (isUploading) return <Skeleton/>;

  return (
    <div style={{ textDecoration: 'underline' }}
         className="file-container d-flex justify-content-between" >
      <small style={{ cursor: 'pointer' }} className="file-container-title"
             onClick={() => props.preview(file?.thumbnail)}>
        {file.filename.length > 50 ? `${file.filename?.substring(0, 30)}...` : file.filename}
      </small>
      <div>
        <span data-alias={file.alias}
              title={file.filename}
              className={`file-container-remove ${hash} text-black-50`}
              onMouseEnter={onHover}
              onMouseLeave={onOut}
              onClick={props?.cb}>
        <FontAwesomeIcon icon={faSave}/>
      </span>&nbsp;
        {props.delete
        && <span data-file={props.file.alias} className={`file-container-remove ${hash} text-black-50`}
                 onMouseEnter={onHover}
                 onMouseLeave={onOut}
                 onClick={props.deletecb}>
        <FontAwesomeIcon icon={faTimesCircle}/>
      </span>}
      </div>
    </div>);
};
export default ChatFile;

ChatFile.propTypes = {
  deletecb: PropTypes.func,
  cb: PropTypes.func,
  delete: PropTypes.bool,
  file: PropTypes.shape({
    _id: PropTypes.string, title: PropTypes.string, filename: PropTypes.string, alias: PropTypes.string,
  }),
  isUploading: PropTypes.bool,
};
