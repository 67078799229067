import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';

const Chevron = (props) => {
  const { showActions, toggleChevron } = props;
  return (
    <div>
      <div role="button" onClick={() => toggleChevron()}>
        {showActions ? <FontAwesomeIcon icon={faChevronUp}/> : <FontAwesomeIcon icon={faChevronDown}/>}
      </div>
    </div>
  );
};

Chevron.propTypes = {
  showActions: PropTypes.bool.isRequired,
  toggleChevron: PropTypes.func.isRequired,
};

export default Chevron;
