import AxiosClient from '../../../../../functional/Axios/AxiosClient';

export function updateUserProfile(profileId, fields) {
  return AxiosClient.post(`/users/profile/update/${profileId}`, {
    ...fields,
  });
}

export function uploadUserAvatar(data) {
  const { config, profileId, formData } = data.payload;
  return AxiosClient.post(`/users/download/profile/${profileId}`, formData, config)
    .then((response) => ({ response }))
    .catch((error) => (error));
}

export function userChangePassword({ ...data }) {
  const {
    password,
    password2,
    passwordCurrent,
  } = data.payload;
  return AxiosClient.post('/users/passwordchangedbyuser/', {
    password,
    password2,
    passwordCurrent,
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function updateUser({ ...data }) {
  const {
    phone, email, userId, code,
  } = data.payload;
  return AxiosClient.put(`/users/${userId}`, {
    phone, email, code,
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function update2Fa({ ...data }) {
  const {
    enableTwoFactor, userId,
  } = data.payload;
  return AxiosClient.post('/users/enableTwoFactor', {
    enableTwoFactor, userId,
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function updateEmrAccess({ ...data }) {
  const {
    allowEmrAccess,
    profileId,
    userId,
  } = data.payload;
  return AxiosClient.post('/users/allowEmrAccess', {
    allowEmrAccess,
    profileId,
    userId,
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function cancelProfileVerification({ ...data }) {
  const {
    id,
  } = data.payload;
  return AxiosClient.post('/integration/Profile/verification', {
    id,
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function verifyProfile({ ...data }) {
  const {
    id,
    verified,
  } = data.payload;
  return AxiosClient.post('/users/Profile/verify', {
    id,
    verified,
  })
    .then((result) => ({ result }))
    .catch((err) => ({ err }));
}

export function codeVerification() {
  return AxiosClient.post('/users/codeVerification')
    .then((result) => ({ result }))
    .catch((err) => ({ err }));
}

export function updateUserNotifications({ ...payload }) {
  return AxiosClient.put('/users/notifications', {
    payload,
  })
    .then((response) => (response));
}
