import Drawer from '@material-ui/core/Drawer/Drawer';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { hideSidePanel, loadSidePanel, showSidePanel } from './actions/sidePanel.actions';
import './SidePanel.css';

/**
 * Render passed component and pass SidePanel control methods to it
 */
class SidePanel extends Component {
  render() {
    return (
      <Drawer open={this.props.visible} anchor={this.props.side} onClose={this.props.hideSidePanel}>
        {/* eslint-disable-next-line no-undef */}
        {this.props.component && <this.props.component {...this.props.args} hideSidePanel={this.props.hideSidePanel}/>}
      </Drawer>
    );
  }
}

const mapStateToProps = (store) => ({
  ...store.components.sidePanel,
});

const mapDispatchToProps = {
  loadSidePanel,
  showSidePanel,
  hideSidePanel,
};

SidePanel.propTypes = {
  side: PropTypes.string,
  hideSidePanel: PropTypes.func,
  component: PropTypes.node,
  visible: PropTypes.bool,
  args: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
