export const initialState = {
  elements: [],
  fetching: false,
};

export const actions = {
  WIDGETS_USER_REQUEST: 'WIDGETS_USER:REQUEST',
  WIDGETS_USER_REQUEST_SUCCESS: 'WIDGETS_USER:REQUEST_SUCCESS',
  WIDGETS_USER_REQUEST_FAILED: 'WIDGETS_USER:REQUEST_FAILED',
};

function widgetsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.WIDGETS_USER_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.WIDGETS_USER_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        fetching: false,
      };

    default:
      return { ...state };
  }
}

export default widgetsReducer;
