import React from 'react';
import PropTypes from 'prop-types';

export const ChatMessageContainer = ({ children }) => (
  <div>
    {children}
  </div>
);

ChatMessageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
