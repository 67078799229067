export const initialState = {
  error: false,
  apiCallResult: [],
  reserveId: '',
};

export const actions = {
  BOOKING_BOOK_VISIT_REQUEST: 'BOOKING_BOOK_VISIT:REQUEST',
  BOOKING_BOOK_VISIT_SUCCESS: 'BOOKING_BOOK_VISIT:SUCCESS',
  BOOKING_BOOK_VISIT_ERROR: 'BOOKING_BOOK_VISIT:ERROR',

  BOOKING_APPROVE_VISIT_REQUEST: 'BOOKING_APPROVE_VISIT:REQUEST',
  BOOKING_APPROVE_VISIT_SUCCESS: 'BOOKING_APPROVE_VISIT:SUCCESS',
  BOOKING_APPROVE_VISIT_ERROR: 'BOOKING_APPROVE_VISIT:ERROR',
};

export function bookingReducer(state = initialState, action) {
  switch (action.type) {
    case actions.BOOKING_BOOK_VISIT_REQUEST:
      return {
        ...state,
      };

    case actions.BOOKING_BOOK_VISIT_SUCCESS:
      return {
        ...state,
        error: false,
        reserveId: action.payload?.data?.schRecId,
      };

    case actions.BOOKING_BOOK_VISIT_ERROR:
      return {
        ...state,
        error: true,
      };

    case actions.BOOKING_APPROVE_VISIT_REQUEST:
      return {
        ...state,
      };

    case actions.BOOKING_APPROVE_VISIT_SUCCESS:
      return {
        ...state,
        error: false,
        apiCallResult: action.payload,
      };

    case actions.BOOKING_APPROVE_VISIT_ERROR:
      return {
        ...state,
        error: true,
      };

    default:
      return { ...state };
  }
}
