import axios from 'axios';
import {
  all, put, takeEvery, takeLatest, delay,
} from 'redux-saga/effects';
import { registerLoginSagas } from '../components/Auth/components/Login/store/login.saga';
import { registerRegisterSagas } from '../components/Registration/store/registration-form.saga';
import { registerValidateSagas } from '../components/Auth/components/AuthValidate/store/authValidate.saga';
import { registerTransactionsSagas } from '../components/Transactions/store/transactions.saga';
import { getClaimSaga, registerClaimsSagas } from '../components/root/Claim/store/claims.saga';
import { registerUserClaimsSagas } from '../components/Claim/store/claims.saga';
import { registerDoctorsRootSagas } from '../components/root/Doctor/store/doctors.root.saga';
import { registerWidgetSagas } from '../components/root/Widgets/store/widgetsAdmin.saga';
import { registerUserWidgetsSagas } from '../components/UI/Widgets/store/widgets.saga';
import { registerAdminNewsSagas } from '../components/root/News2.0/store/newsAdmin.saga';
import { registerAdminFaqSagas } from '../components/root/Faq/store/faqAdmin.saga';
import { registerRootChatsSagas } from '../components/root/Chats/store/rootChats.saga';
import { registerDoctorSagas } from '../components/Doctor/store/doctors.saga';
import { registerUserSettingsSagas } from '../components/User/components/UserSettings/store/userSettings.saga';
import { registerNewsSagas } from '../components/News/store/news.saga';
import { registerConfigSagas } from '../components/root/AppConfig/store/appconfig.saga';
import { registerTemplatesSagas } from '../components/root/Templates/store/templates.saga';
import { registerReplacementsSagas } from '../components/root/Replacements/store/replacements.saga';
import { registerUsersSagas } from '../components/root/User/store/users.saga';
import { registerReviewsSagas } from '../components/Reviews/store/reviews.saga';
import { registerReviewsRootSagas } from '../components/root/Reviews/store/reviews.saga';
import { registerServicesRootSagas } from '../components/root/Service/store/services.root.saga';
import { registerRootDirectionsSagas } from '../components/root/Directions/store/directions.root.saga';
import { registerRightsRootSagas } from '../components/root/RightsManager/store/rightsManager.saga';
import { registerSettingsRootSagas } from '../components/root/RootSettings/store/settings.root.saga';
import { registerUserProfileSagas } from '../components/User/components/UserProfileAdd/store/userProfile.saga';
import { registerEmrRecordsSagas } from '../components/EmrRecords/store/emrRecords.saga';
import { registerUserEmrSagas } from '../components/User/components/UserEmr/store/userEmr.saga';
import { faqGetAllSaga } from '../components/Faq/store/faqGetAllSaga';
import { getDirectionsSaga } from '../components/Directions/store/getDirectionsSaga';
import { updateDirectionsSaga } from '../components/Directions/store/updateDirectionsSaga';
import { getAllBranchesAdmin } from '../components/root/Branches/store/branchesAdminGetSaga';
import { sendTaskModalSaga } from '../components/UI/Modal/Modals/SendTask/store/SendTaskModalSaga';
import { directionGetDaysByIdSaga } from '../components/Directions/store/directionGetDaysByIdSaga';
import { getChatUserSaga } from '../components/Chat/components/ChatRoom/chatUserGetSaga';

import { actions as notifyActions } from '../components/UI/Notificator/notificatorReducer';
import { actions as notificationActions } from '../components/Notifications/store/notificationsReducer';
import { actions as transactionActions } from '../components/Transactions/store/transactions.reducer';
import { actions as claimActions } from '../components/root/Claim/store/claims.reducer';
import { actions as chatRoomActions } from '../components/Chat/components/ChatRoom/chatRoomReducer';
import { actions as activeChatsActions } from '../components/Chat/components/Chats/activeChats.reducer';
import { actions as configActions } from '../components/root/AppConfig/store/appconfigReducer';
import { actions as loaderActions } from '../components/UI/Loader/store/loaderReducer';
import { actions as userAdminActions } from '../components/root/User/store/users.reducer';
import { actions as branchesAdminActions } from '../components/root/Branches/store/branchesAdminReducer';
import { actions as faqActions } from '../components/Faq/store/faqReducer';
import { actions as directionsActions } from '../components/Directions/store/directionsReducer';
import { actions as sendTaskModalActions } from '../components/UI/Modal/Modals/SendTask/store/SendTaskModalReducer';
import { actions as userEmrActions } from '../components/User/components/UserEmr/store/userEmr.reducer';
import { actions as authActions } from '../components/Auth/store/auth.actions';

import { registerNotificationSagas } from '../components/Notifications/store/notificationsSaga';
import { registerChatRoomSagas } from '../components/Chat/components/ChatRoom/chatRoomSaga';
import { registerChatsSagas } from '../components/Chat/components/Chats/chatsSaga';
import { forgotPasswordSagas } from '../components/ForgotPassword/store/ForgotPassword.saga';
import { resetPasswordSagas } from '../components/ResetPassword/store/ResetPassword.saga';
import { registerBookingSagas } from '../components/UI/Modal/Modals/BookingModal/store/booking.saga';

// TODO::refactor move this to log component log saga later
export function log() {
  axios
    .post('/api/log/', {})
    .then((data) => data)
    .catch((error) => error);

  return true;
}

export function* watchLoaderStopSaga() {
  yield put({
    type: 'LOADER:STOP',
    payload: true,
  });
}

export function* watchLoaderStartSaga() {
  yield put({
    type: 'LOADER:START',
    payload: false,
  });
}
function* kekSaga() {
  yield put({ type: 'SHOW_LOADER' });
  yield delay(2000);
  yield put({ type: 'HIDE_LOADER' });
}

export function* fetchIndicatorSagaStart() {
  yield put({ type: loaderActions.FETCH_INDICATOR_SHOW });
}

export function* fetchIndicatorSagaStop() {
  yield delay(1000);
  yield put({ type: loaderActions.FETCH_INDICATOR_HIDE });
}

export default function* rootSaga() {
  // notifications
  yield takeEvery(Object.values(notifyActions), log);

  const startFetchSagaMap = [
    transactionActions.TRANSACTIONS_CHANGE_PAGE,
    transactionActions.TRANSACTIONS_REQUEST,
    claimActions.CLAIMS_REQUEST,
    configActions.CONFIG_REQUEST,
    notificationActions.READ_NOTIFICATION,
    userAdminActions.USERS_ADMIN_REQUEST,
    userAdminActions.USERS_ADMIN_CHANGE_PAGE,
    claimActions.CLAIMS_FILTER,
    activeChatsActions.CHAT_UPDATE_REQUEST,
    activeChatsActions.CHATS_GET_REQUEST,
    activeChatsActions.CHAT_GET_REQUEST,
    authActions.SET_OFFLINE
  ];
  const stopFetchSagaMap = [
    transactionActions.TRANSACTIONS_REQUEST_SUCCESS,
    transactionActions.TRANSACTIONS_REQUEST_FAILED,
    claimActions.CLAIMS_REQUEST_SUCCESS,
    claimActions.CLAIMS_REQUEST_FAILED,
    configActions.CONFIG_REQUEST_SUCCESS,
    notificationActions.READ_NOTIFICATION_SUCCESS,
    notificationActions.READ_NOTIFICATION_FAILED,
    userAdminActions.USERS_ADMIN_REQUEST_SUCCESS,
    userAdminActions.USERS_ADMIN_REQUEST_FAILED,
    claimActions.CLAIMS_FILTER,
    userAdminActions.USERS_ADMIN_CHANGE_PAGE,
    activeChatsActions.CHAT_UPDATE_SUCCESS,
    activeChatsActions.CHATS_GET_SUCCESS,
    activeChatsActions.CHATS_GET_FAILED,
    activeChatsActions.CHAT_GET_SUCCESS,
    activeChatsActions.CHAT_GET_FAILED,
    authActions.SET_ONLINE
  ];

  const fetchClaimsSagaMap = [
    claimActions.CLAIM_CLOSE_REQUEST_SUCCESS,
    claimActions.CLAIM_RESET_REQUEST_SUCCESS,
    claimActions.CLAIM_PAY_REQUEST_SUCCESS,
    claimActions.CLAIM_ADD_ADMIN_REQUEST_SUCCESS,
    claimActions.CLAIM_DELETE_ADMIN_REQUEST_SUCCESS,
  ];

  yield takeLatest(fetchClaimsSagaMap, getClaimSaga);

  yield takeLatest(startFetchSagaMap, fetchIndicatorSagaStart);
  yield takeLatest(stopFetchSagaMap, fetchIndicatorSagaStop);

  yield takeLatest(
    [
      userAdminActions.USERS_ADMIN_REQUEST,
      userAdminActions.USERS_ADMIN_CHANGE_PAGE,
    ],
    kekSaga,
  );

  /**
   * Services root sagas
   */
  yield all(registerServicesRootSagas);

  yield takeEvery(
    [branchesAdminActions.BRANCHES_ALL_REQUEST],
    getAllBranchesAdmin,
  );

  yield takeEvery([faqActions.FAQ_REQUEST], faqGetAllSaga);
  /**
   * Modals sagas;
   */
  yield takeEvery(
    [sendTaskModalActions.SENDTASK_MODAL_POST_REQUEST],
    sendTaskModalSaga,
  );
  /**
   * NotificationsTypes saga;
   */
  yield all(registerNotificationSagas);

  // AUTH
  yield all(registerLoginSagas);
  yield all(registerValidateSagas);
  // REGISTER
  yield all(registerRegisterSagas);

  yield takeEvery([directionsActions.DIRECTIONS_REQUEST], getDirectionsSaga);
  yield takeEvery([directionsActions.DIRECTIONS_UPDATE], updateDirectionsSaga);
  yield takeEvery(
    [directionsActions.DIRECTIONS_GET_DAYS_REQUEST],
    directionGetDaysByIdSaga,
  );
  // CHAT SAGAS
  yield all(registerChatRoomSagas);
  yield all(registerChatsSagas);

  yield takeEvery(
    [chatRoomActions.CHAT_GET_CHAT_REQUEST],
    watchLoaderStartSaga,
  );
  yield takeEvery([chatRoomActions.CHAT_GET_CHAT_SUCCESS], watchLoaderStopSaga);
  yield takeEvery([chatRoomActions.CHAT_GET_USER], getChatUserSaga);

  // claim sagas
  yield all(registerClaimsSagas);

  // doctor sagas
  yield all(registerDoctorSagas);

  // transactions saga
  yield all(registerTransactionsSagas);

  // userClaims saga
  yield all(registerUserClaimsSagas);

  // userSettings saga
  yield all(registerUserSettingsSagas);

  // userEmr saga
  yield all(registerUserEmrSagas);

  // forgotPassword saga
  yield all(forgotPasswordSagas);

  // forgotPassword saga
  yield all(resetPasswordSagas);

  // news saga
  yield all(registerNewsSagas);

  // widgets saga
  yield all(registerUserWidgetsSagas);

  // reviews saga
  yield all(registerReviewsSagas);

  // booking saga
  yield all(registerBookingSagas);

  // user profiles saga
  yield all(registerUserProfileSagas);

  // root doctors saga
  yield all(registerDoctorsRootSagas);

  // root widgets saga
  yield all(registerWidgetSagas);

  // root news saga
  yield all(registerAdminNewsSagas);

  // root faq saga
  yield all(registerAdminFaqSagas);

  // root chats saga
  yield all(registerRootChatsSagas);

  // root config saga
  yield all(registerConfigSagas);

  // root templates saga
  yield all(registerTemplatesSagas);

  // root replacements saga
  yield all(registerReplacementsSagas);

  // root users saga
  yield all(registerUsersSagas);

  // reviews root saga
  yield all(registerReviewsRootSagas);

  // emrRecords saga
  yield all(registerEmrRecordsSagas);

  // directions root saga
  yield all(registerRootDirectionsSagas);

  // rights root saga
  yield all(registerRightsRootSagas);

  // settings root saga
  yield all(registerSettingsRootSagas);
}
