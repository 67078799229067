import React, { lazy } from 'react';

const WidgetSelectDoctors = lazy(() => import('../WidgetSelectDoctors/WidgetSelectDoctors'));
const WidgetCallDoctor = lazy(() => import('../WidgetCallDoctor/WidgetCallDoctor'));
const WidgetNewsFeed = lazy(() => import('../WidgetNewsFeed/WidgetNewsFeed'));
const WidgetMenu = lazy(() => import('../WidgetMenu/WidgetMenu'));
const WidgetTotalClaim = lazy(() => import('../WidgetTotalClaims/WidgetTotalClaim'));
const WidgetTotalUsers = lazy(() => import('../WidgetTotalUsers/WidgetTotalUsers'));
const UsersOnlineWidget = lazy(() => import('../UsersOnline/UsersOnlineWidget'));
const WidgetChats = lazy(() => import('../WidgetChats/WidgetChats'));
const WidgetPinnedAdminChats = lazy(() => import('../WidgetPinnedAdminChats/WidgetPinnedAdminChats'));
const WidgetCreateClaim = lazy(() => import('../WidgetCreateClaim/WidgetCreateClaim'));
const WidgetPromotions = lazy(() => import('../WidgetPromotions/WidgetPromotions'));
const WidgetInstructions = lazy(() => import('../WidgetInstructions/WidgetInstructions'));
const WidgetClaimsRadialBar = lazy(() => import('../WidgetClaimsRadialBar/WidgetClaimsRadialBar'));
const WidgetUsersLinearGraph = lazy(() => import('../WidgetUsersLinearGraph/WidgetUsersLinearGraph'));
const WidgetDoctorClaims = lazy(() => import('../WidgetDoctorClaims/WidgetDoctorClaims'));
const WidgetDoctorClaimsStat = lazy(() => import('../WidgetDoctorClaimsStat/WidgetDoctorClaimsStat'));
const WidgetTodayDate = lazy(() => import('../WidgetTodayDate/WidgetTodayDate'));
const WidgetDoctorClaimsLinearGraph = lazy(() => import('../WidgetDoctorClaimsLinearGraph/WidgetDoctorClaimsLinearGraph'));

export const screenSizes = {
  DESKTOP: '(min-width:1200px)',
  TABLET: '(min-width:800px)',
  MOBILE: '(min-width:480px)',
};

export const widgetsMap = (props) => (
  {
    WidgetSelectDoctors: <WidgetSelectDoctors {...props}/>,
    WidgetCallDoctor: <WidgetCallDoctor {...props}/>,
    WidgetNewsFeed: <WidgetNewsFeed {...props}/>,
    WidgetMenu: <WidgetMenu {...props}/>,
    WidgetTotalClaim: <WidgetTotalClaim {...props}/>,
    WidgetTotalUsers: <WidgetTotalUsers {...props}/>,
    UsersOnlineWidget: <UsersOnlineWidget {...props}/>,
    WidgetChats: <WidgetChats {...props}/>,
    WidgetPinnedAdminChats: <WidgetPinnedAdminChats {...props}/>,
    WidgetCreateClaim: <WidgetCreateClaim {...props}/>,
    WidgetPromotions: <WidgetPromotions {...props}/>,
    WidgetInstructions: <WidgetInstructions {...props}/>,
    WidgetClaimsRadialBar: <WidgetClaimsRadialBar {...props}/>,
    WidgetUsersLinearGraph: <WidgetUsersLinearGraph {...props}/>,
    WidgetDoctorClaims: <WidgetDoctorClaims {...props}/>,
    WidgetDoctorClaimsStat: <WidgetDoctorClaimsStat {...props}/>,
    WidgetTodayDate: <WidgetTodayDate {...props}/>,
    WidgetDoctorClaimsLinearGraph: <WidgetDoctorClaimsLinearGraph {...props}/>
  }
);
