import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './admin.css';
import SideBar from '../../../containers/UI/SideBarTabs/SideBar';
import SideBarTab from '../../../containers/UI/SideBarTabs/SideBarTab';
import SideBarComponent from '../../../containers/UI/SideBarTabs/SideBarComponent';
import { getAdminAppConfig } from '../AppConfig/store/appconfigActions';
import LocalLoader from '../../../containers/UI/LocalLoader';
import WidgetsGrid from '../../UI/Widgets/WidgetsGrid';
import { checkUserRights } from '../RightsManager/store/rightsManager.actions';

const DirectionsManagementContainer = lazy(() => import('../../../containers/root/DirectionsManagementContainer'));
const ChatManagementContainer = lazy(() => import('../../../containers/root/ChatManagementContainer'));
const UserManagementContainer = lazy(() => import('../../../containers/root/UsersManagementContainer'));
const ClaimManagementContainer = lazy(() => import('../../../containers/root/ClaimManagementContainer'));
const ServicesManagementContainer = lazy(() => import('../../../containers/root/ServicesManagementContainer'));
const ReviewsManagementContainer = lazy(() => import('../../../containers/root/ReviewsManagementContainer'));
const BranchManagementContainer = lazy(() => import('../../../containers/root/BranchManagementContainer'));
const NewsManagementContainer = lazy(() => import('../../../containers/root/NewsManagementContainer'));
const FaqManagementContainer = lazy(() => import('../../../containers/root/FaqManagementContainer'));
const ReportsManagementContainer = lazy(() => import('../../../containers/root/ReportsManagementContainer'));

class Admin extends Component {
  componentDidMount() {
    const { history } = this.props;
    this.props.checkUserRights(history);
    this.props.getAdminAppConfig();
  }

  render() {
    const { userIsAllowed } = this.props;
    if (!userIsAllowed) return <LocalLoader title="Проверяем права..."/>;

    return (
      <div className="container">
        <SideBar historyEnabled={true} tabsOnly={true} sideBarSize="col-12 col-lg-2 mb-3" containerSize="col-12 col-lg-10">
          <SideBarTab>
            Главная
          </SideBarTab>
          <SideBarComponent>
            <WidgetsGrid/>
          </SideBarComponent>
          <SideBarTab>
            Пользователи
          </SideBarTab>
          <SideBarComponent><UserManagementContainer/></SideBarComponent>
          <SideBarTab>
            Заявки
          </SideBarTab>
          <SideBarComponent><ClaimManagementContainer/></SideBarComponent>
          <SideBarTab>
            Чаты
          </SideBarTab>
          <SideBarComponent>
            <ChatManagementContainer/>
          </SideBarComponent>
          <SideBarTab>
            Направления
          </SideBarTab>
          <SideBarComponent><DirectionsManagementContainer/></SideBarComponent>
          <SideBarTab>
            Услуги
          </SideBarTab>
          <SideBarComponent><ServicesManagementContainer/></SideBarComponent>
          <SideBarTab>
            Филиалы
          </SideBarTab>
          <SideBarComponent><BranchManagementContainer/></SideBarComponent>
          <SideBarTab>
            Новости
          </SideBarTab>
          <SideBarComponent><NewsManagementContainer/></SideBarComponent>
          <SideBarTab>
            FAQ
          </SideBarTab>
          <SideBarComponent><FaqManagementContainer/></SideBarComponent>
          <SideBarTab>
            Отчеты
          </SideBarTab>
          <SideBarComponent><ReportsManagementContainer/></SideBarComponent>
          <SideBarTab>
            Отзывы
          </SideBarTab>
          <SideBarComponent><ReviewsManagementContainer/></SideBarComponent>
        </SideBar>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getAdminAppConfig,
  checkUserRights,
};

const mapStateToProps = (store) => ({
  ...store.rightsManager,
});

Admin.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  getAdminAppConfig: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
