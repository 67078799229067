import { actions as registerActions } from './registration-form.actions';
import { DATA_TYPES } from '../types/VALIDATE';

/**
 * Registration Reducer Initial State
 */
export const initialState = {
  errors: [],

  emailConfirmed: false,
  phoneConfirmed: false,

  verifyEmailVisible: false,
  verifyPhoneVisible: false,
  verifyInProcess: false,

  personalDataAgreement: false,
  ofertaAgreement: false,
  showPolicyModal: false,
  showOfertaModal: false,
};

/**
 * Registration Reducer
 *
 * @param {initialState} state - initial state
 * @param {Object} action - action
 *
 * @returns {initialState}
 */
export function registrationFormReducer(state = initialState, action) {
  switch (action.type) {
    case registerActions.REGISTER_REQUEST_FAILED:
      return {
        ...state,
        errors: action.payload.errors,
      };

    case registerActions.VERIFY_REQUEST: {
      const { type, UID, code } = action.payload;

      return {
        ...state,
        verifyInProcess: true,
        email: type === DATA_TYPES.email ? UID : '',
        phone: type === DATA_TYPES.phone ? UID : '',
        code,
      };
    }

    case registerActions.VERIFY_REQUEST_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        verifyInProcess: false,
        emailConfirmed: data.type === `validate-${DATA_TYPES.email}`,
        verifyEmailVisible: !(data.type === `validate-${DATA_TYPES.email}`),
        phoneConfirmed: data.type === `validate-${DATA_TYPES.phone}`,
        verifyPhoneVisible: !(data.type === `validate-${DATA_TYPES.phone}`),
      };
    }

    case registerActions.VERIFY_REQUEST_FAILED:
      return {
        ...state,
        verifyInProcess: false,
        errors: action.payload.errors || [action.payload.message],
      };

    default:
      return { ...state };
  }
}
