export const CLAIM_STATUSES = {
  SERVICE_ASSIGNED: 1,
  DELETED: 2,
  SET_TO_DOCTOR: 3,
  IN_PROCESS: 4,
  AWAIT_PAYMENT: 5,
  CLOSED_FAILURE: 6,
  PAID: 7,
  DONE: 8,
  CANCELLED_BY_USER: 9,
  EXPIRED: 10,
};

export const CLAIM_TYPES = {
  SECOND_OPINION: 1,
  CALL_DOCTOR: 2,
  PREPAID: 3,
  REQUEST_APPOINTMENT: 4,
};

export const CLAIM_TYPES_TITLES = {
  SECOND_OPINION: 'Мнение врача',
  CALL_DOCTOR: 'Вызов врача',
  PREPAID: 'Внесение средств',
  REQUEST_APPOINTMENT: 'Запрос очного визита',
};

export const ClaimStatus = {
  1: 'Услуга назначена',
  2: 'Закрыта',
  3: 'Назначен врач',
  4: 'В процессе обработки',
  5: 'Ожидает оплаты',
  6: 'Закрыта с ошибкой',
  7: 'Оплачена',
  8: 'Выполнена успешно',
  9: 'Отменена пользователем',
  10: 'Истек срок',
};

export const CLAIMS_MAP = [
  {
    id: 1,
    title: 'Мнение врача',
  },
  {
    id: 2,
    title: 'Вызов врача',
  },
  {
    id: 3,
    title: 'Внесение средств',
  },
  {
    id: 4,
    title: 'Запрос очного визита',
  },
];
