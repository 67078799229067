import AxiosClient from '../../../functional/Axios/AxiosClient';
import { DATA_TYPES } from '../types/VALIDATE';

const apiRegisterPath = '/users';

/**
 * Registration users API methods
 * Patch: /users
 *
 * @param {setProfileVerificationStatus} function
 * @param {registerUser} function
 * @param {verify} function
 * @param {profileVerification} function
 */
export const apiRegistration = {
  /**
   * Set profile verification status
   * Request URL: /Profile/verify
   * Request Method: POST
   *
   * @param {Object} data - response payload params
   * @param {string} data.profileId - users email address
   * @param {string} data.status - users password
   *
   * @returns {Promise<T | *>}
   */
  setProfileVerificationStatus: (data) => AxiosClient.post(`${apiRegisterPath}/Profile/verify`, {
    id: data.profileId,
    verified: data.status,
  }),

  /**
   * Register users
   * Request URL: /register
   * Request Method: POST
   *
   * @param {Object} state - response payload params
   * @param {string} state.avatar - avatar
   * @param {string} state.birthday - birthday
   * @param {string} state.email - email address
   * @param {array} state.errors - errors
   * @param {string} state.firstName - first name
   * @param {string} state.lastName - last name
   * @param {string} state.medCard - med card
   * @param {string} state.password - password
   * @param {string} state.password2 - repeat password
   * @param {string} state.phone - phone number
   * @param {string} state.sex - gender
   * @param {string} state.surName - sur name
   * @param {string} state.profileId - profile Id
   * @param {string} state.status - status
   *
   * @returns {Promise<T | *>}
   */
  registerUser: (state) => AxiosClient.post(`${apiRegisterPath}/register`, {
    ...state,
  }),

  /**
   * Verification users data
   * Request URL: /verify
   * Request Method: POST
   *
   * @param {Object} data - response payload params
   * @param {string} data.code - verify code
   * @param {string} data.UID - users identifier data
   * @param {string<DATA_TYPES>} data.type - data type
   *
   * @returns {Promise<T | *>}
   */
  verify: (data) => {
    const { code, type } = data;
    const request = {
      code,
      type,
    };
    if (type.includes(DATA_TYPES.phone)) {
      request.phone = data.UID;
    } else if (type.includes(DATA_TYPES.email)) {
      request.email = data.UID;
    }
    return AxiosClient.post(`${apiRegisterPath}/verify`, { ...request });
  },

  /**
   * Profile verification (Integration)
   * Request URL: /integration/profileverification
   * Request Method: POST
   *
   * @param {Object} profile - response payload params
   * @param {string} profile.birthday - birthday
   * @param {string} profile.email - email
   * @param {string} profile.firstName - first name
   * @param {string} profile.lastName - last name
   * @param {string} profile.phonenum - phone number
   * @param {string} profile.profileId - profileId
   * @param {string} profile.sex - gender
   * @param {string} profile.surName - sur name
   *
   * @returns {Promise<T | *>}
   */
  profileVerification: (profile) => AxiosClient.post('/integration/profileverification', { ...profile }),
};
