import React from 'react';
import PropTypes from 'prop-types';
import { parseLinks } from '../../../../../../utils/parseLinks';

export const ChatMessageText = (props) => {
  const { text } = props
  return(
    <small className="chat--message-text" style={{ maxWidth: '450px', wordWrap: 'break-word' }}>{parseLinks(text)}</small>
  )
}

ChatMessageText.propTypes = {
  text: PropTypes.string.isRequired,
};
