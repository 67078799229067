export const THEMES = [
  {
    instance: 'Mig',
    name: 'MigTheme',
    css: 'mig.scss',
  },
  {
    instance: 'Rassvet',
    name: 'RassvetTheme',
    css: 'rassvet.scss',
  },
  {
    instance: 'Olimp',
    name: 'OlimpTheme',
    css: 'olimp.scss',
  },
  {
    instance: 'Default',
    name: 'DefaultTheme',
    css: 'default.scss',
  },
];
