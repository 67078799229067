export const actions = {
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD:REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD:SUCCESS',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD:ERROR',
};

export function userForgotPassword(email) {
  return {
    type: actions.FORGOT_PASSWORD_REQUEST,
    payload: { email },
  };
}
