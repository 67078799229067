import AxiosClient from '../../../../../../functional/Axios/AxiosClient';

export function sendTask({ ...payload }) {
  const {
    comment, id, docName,
  } = payload.payload;
  return AxiosClient.post('/integration/task/', {
    comment, id, docName,
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
