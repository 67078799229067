export const initialState = {
  elements: [],
  fetch: false,
  isNew: false,
};

export const actions = {
  PUSH_NOTIFICATION: 'WS:PUSH_NOTIFICATION',
  PUSH_WEB_NOTIFICATION: 'WEB:PUSH_NOTIFICATION',
  PUSH_WEB_NOTIFICATION_SUCCESS: 'WEB:PUSH_NOTIFICATION_SUCCESS',
  RECEIVE_NOTIFICATIONS: 'WS:RECEIVE_NOTIFICATIONS',
  GET_NOTIFICATIONS: 'HTTP:GET_NOTIFICATIONS',
  GET_NOTIFICATIONS_SUCCESS: 'HTTP:GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILED: 'HTTP:GET_NOTIFICATIONS_FAILED',

  READ_NOTIFICATION: 'HTTP:NOTIFICATION_READ',
  READ_NOTIFICATION_SUCCESS: 'HTTP:NOTIFICATION_READ_SUCCESS',
  READ_NOTIFICATION_FAILED: 'HTTP:NOTIFICATION_READ_FAILED',

  READ_NOTIFICATIONS: 'HTTP:NOTIFICATIONS_READ',
  READ_NOTIFICATIONS_SUCCESS: 'HTTP:NOTIFICATIONS_READ_SUCCESS',
  READ_NOTIFICATIONS_FAILED: 'HTTP:NOTIFICATIONS_READ_FAILED',

  DELETE_NOTIFICATION: 'HTTP:NOTIFICATION_DELETE',
  DELETE_NOTIFICATION_SUCCESS: 'HTTP:NOTIFICATION_DELETE_SUCCESS',
  DELETE_NOTIFICATION_FAILED: 'HTTP:NOTIFICATION_DELETE_FAILED',
};

export function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.READ_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        elements: [...state.elements].map((e) => {
          e.status = 1;
          return e;
        }),
        isNew: false,
      };
    }
    case actions.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        elements: [...state.elements, ...action.payload],
        isNew: action.payload?.some((e) => e.status === 0),
      };

    case actions.PUSH_NOTIFICATION:
      return {
        ...state,
        elements: [...state.elements, action.payload],
        isNew: [...state.elements, action.payload].some((e) => e.status !== 1),
      };

    case actions.PUSH_WEB_NOTIFICATION:
      return {
        ...state,
      };

    case actions.PUSH_WEB_NOTIFICATION_SUCCESS:
      return {
        ...state,
      };

    case actions.RECEIVE_NOTIFICATIONS:
      return { ...state };

    case actions.READ_NOTIFICATION:
      return {
        ...state,
        fetch: true,
      };

    case actions.DELETE_NOTIFICATION:
      return {
        ...state,
        fetch: true,
      };

    case actions.READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        elements: [...state.elements].map((e) => {
          if (e._id === action.payload.id) e.status = 1;
          return e;
        }),
        isNew: state.elements.some((e) => e.status !== 1),
        fetch: false,
      };

    case actions.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        elements: [...state.elements].filter((e) => {
          if (e._id === action.payload.id) return false;
          return e;
        }),
        fetch: false,
      };

    default:
      return { ...state };
  }
}

export default notificationsReducer;
