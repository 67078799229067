import {
  call, put, takeEvery,
} from 'redux-saga/effects';
import { widgetsApi } from '../api';
import { actions } from './widgets.reducer';
import SessionStorageCrypt from '../../../../functional/SessionStorageCrypt';

const USER_WIDGETS_KEY = 'userMenuWidgets';

export function* getUserWidgets() {
  try {
    let _data = SessionStorageCrypt.getItem(USER_WIDGETS_KEY);

    if (!_data) {
      const { data } = yield call(widgetsApi.getWidgets);
      SessionStorageCrypt.setItem(USER_WIDGETS_KEY, data);
      _data = data;
    }

    yield put({
      type: actions.WIDGETS_USER_REQUEST_SUCCESS,
      payload: _data,
    });
  } catch (error) {
    yield put({ type: actions.WIDGETS_USER_REQUEST_FAILED });
  }
}

export const registerUserWidgetsSagas = [
  takeEvery([actions.WIDGETS_USER_REQUEST], getUserWidgets),
];
