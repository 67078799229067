import React from 'react';

const FetchIndicator = () => (
  <div>
    <div className="d-none d-md-block spinner-grow text-secondary mr-2" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export default FetchIndicator;
