import React, { PureComponent } from 'react';
import { Pagination as P } from '@material-ui/lab';
import Button from '../UI/Buttons/Button';

const NextPrevNavigation = ({
  handleChange, count, page, className,
}) => (
  <div className={`row justify-content-center mt-2 ${className}`}>
    <P count={count} onChange={handleChange} page={page} hidePrevButton hideNextButton/>
  </div>
);

const InfiniteLoadNavigation = ({ nextPage, disabled }) => (<div className="container mt-3">
  <div className="row justify-content-center">
    <Button className="btn-outline-dark" disabled={disabled} onClick={(e) => {
      if (e) {
        const k = e.currentTarget;
        k.disabled = true;
        setTimeout(() => {
          k.disabled = false;
        }, 2000);
      }
      nextPage();
    }}>Загрузить
      еще</Button>
  </div>
  <div className="row justify-content-center text-black-50">
    {disabled && <div>Данных больше нет</div>}
  </div>
</div>);

class Pagination extends PureComponent {
  render() {
    const {
      type, nextPage, disabled, handleChange, count, page, className,
    } = this.props;
    if (type === 'infinite') {
      return <InfiniteLoadNavigation nextPage={nextPage}
                                     disabled={disabled}/>;
    }
    return <NextPrevNavigation page={page} count={count} handleChange={handleChange} className={className}/>;
  }
}

export default Pagination;
