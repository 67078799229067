export const initialState = {
  messages: [],
  status: [],
  participants: [],
  createdAt: 0,
  updatedAt: 0,
  fetch: true,
  error: '',
  userInfo: {},
};

export const actions = {
  CHAT_SET_USER: 'CHAT:SET_CLIENT',
  CHAT_SET_DOCTOR: 'CHAT:SET_DOCTOR',

  CHAT_GET_USER: 'CHAT:GET_USER_REQUEST',
  CHAT_GET_USER_SUCCESS: 'CHAT:GET_USER_REQUEST_SUCCESS',
  CHAT_GET_USER_FAILED: 'CHAT:GET_USER_REQUEST_FAILED',

  CHAT_INIT: 'CHAT:INIT',
  CHAT_INIT_SUCCESS: 'CHAT:INIT_SUCCESS',
  CHAT_INIT_FAILED: 'CHAT:INIT_FAILED',

  CHAT_UPDATE_MESSAGES: 'CHAT:UPDATE_MESSAGES',

  CHAT_GET_CHAT_REQUEST: 'CHAT:GET_CHATS_REQUEST',
  CHAT_GET_CHAT_SUCCESS: 'CHAT:GET_CHATS_SUCCESS',

  CHAT_GET_CHAT_ERROR: 'CHAT:GET_CHATS_ERROR',
  CHAT_GET_CHAT_ARCHIVE_REQUEST: 'CHAT:GET_CHATS_ARCHIVE_REQUEST',
  CHAT_GET_CHAT_ARCHIVE_SUCCESS: 'CHAT:GET_CHATS_ARCHIVE_SUCCESS',
  CHAT_GET_CHAT_ARCHIVE_ERROR: 'CHAT:GET_CHATS_ARCHIVE_ERROR',
};

export function chatRoomReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CHAT_INIT:
      return {
        ...action.payload,
        fetch: true,
      };

    case actions.CHAT_INIT_SUCCESS:
      return {
        ...action.payload,
        fetch: false,
      };

    case actions.CHAT_UPDATE_MESSAGES: {
      let messages = [];
      if (state.messages.length > 0) {
        messages = state.messages.slice(0);
      }
      messages.push(action.payload);
      return {
        ...state,
        messages,
      };
    }
    case actions.CHAT_INIT_FAILED:
      return { ...state, fetch: false, error: 'Чат не существует' };

    case actions.CHAT_GET_CHAT_ERROR:
      return { ...state };

    case actions.CHAT_GET_CHAT_ARCHIVE_REQUEST:
      return { ...state };

    case actions.CHAT_GET_CHAT_ARCHIVE_SUCCESS:
      return {
        ...state,
        archiveChats: action.payload,
      };

    case actions.CHAT_GET_CHAT_ARCHIVE_ERROR:
      return { ...state };

    case actions.CHAT_GET_USER:
      return { ...state };

    case actions.CHAT_GET_USER_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
      };

    default:
      return { ...state };
  }
}
