import { call, put, takeLatest } from 'redux-saga/effects';
import { apiRootDoctor } from './api';
import { actions } from './doctors.root.reducer';

export function* getDoctorAdminSaga() {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const response = yield call(apiRootDoctor.getAdminDoctors);
    yield put({
      type: actions.DOCTORS_ALL_REQUEST_SUCCESS,
      payload: response.data.filter((el) => (el.user !== null)),
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.DOCTORS_ALL_REQUEST_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}

export function* updateDoctorAdminSaga(payload) {
  try {
    yield put({ type: 'SHOW_LOADER' });
    yield put({
      type: actions.DOCTORS_ALL_UPDATE_SUCCESS,
      payload: payload.payload.elementsFiltered,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.DOCTORS_ALL_UPDATE_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}

export const registerDoctorsRootSagas = [
  takeLatest([actions.DOCTORS_ALL_REQUEST], getDoctorAdminSaga),
  takeLatest([actions.DOCTORS_ALL_UPDATE], updateDoctorAdminSaga),
];
