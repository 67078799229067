export const NOTIFICATION_GROUPS = {
  UNREAD: 0,
  READ: 1,
  DELETED: 99,
  ALL: 100,
};

export const NOTIFICATION_TYPES = {
  NEW_CLAIM: 'NEW_CLAIM',
  NEW_MESSAGE: 'NEW_MESSAGE',
  NEW_MESSAGE_CLINIC: 'NEW_MESSAGE_CLINIC',
};
