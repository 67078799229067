import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { actions } from './services.root.reducer';
import { apiServices } from '../api/apiServices';
import { querystring } from '../../../../store/utils/formatters/querystring';
import { hideSidePanel } from '../../../UI/SidePanel/actions/sidePanel.actions';

export function* getServicesSaga(action) {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const query = yield select((state) => state.root.services.query);

    let q = { ...query };

    if (action?.payload?.status) {
      q = { ...q, status: action?.payload?.status };
    }

    const response = yield call(apiServices.fetchServices, { query: querystring(q) });
    yield put({
      type: actions.GET_SERVICES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_SERVICES_FAILED });
  } finally {
    yield put({ type: 'HIDE_LOADER' });
  }
}

export function* createServiceSaga(action) {
  try {
    yield put({ type: 'SHOW_LOADER' });

    yield call(apiServices.addService, action.payload);
    yield call(getServicesSaga);
    yield put({ type: actions.CREATE_SERVICE_SUCCESS });
    yield toast.success('Услуга добавлена');
  } catch (error) {
    yield put({ type: actions.CREATE_SERVICE_FAILED });
    yield toast.error('Ошибка добавления услуги');
  } finally {
    yield put({ type: 'HIDE_LOADER' });
    yield put(hideSidePanel());
  }
}

export function* updateServiceSaga(action) {
  try {
    yield put({ type: 'SHOW_LOADER' });

    yield call(apiServices.updateService, action.payload);
    yield call(getServicesSaga);
    yield put({
      type: actions.UPDATE_SERVICE_SUCCESS,
    });
    yield toast.success('Услуга обновлена');
  } catch (error) {
    yield put({ type: actions.UPDATE_SERVICE_FAILED });
    yield toast.error('Ошибка обновления услуги');
  } finally {
    yield put({ type: 'HIDE_LOADER' });
    yield put(hideSidePanel());
  }
}

export function* deleteServiceSaga(action) {
  try {
    yield put({ type: 'SHOW_LOADER' });
    yield call(apiServices.deleteServices, action.payload);
    yield call(getServicesSaga);
    yield toast.success('Услуга удалена');
  } catch (error) {
    yield put({ type: actions.DELETE_SERVICE_FAILED });
    yield toast.success('Ошибка удаления услуги');
  } finally {
    yield put({ type: 'HIDE_LOADER' });
  }
}

export const registerServicesRootSagas = [
  takeLatest([actions.GET_SERVICES], getServicesSaga),
  takeLatest([actions.CREATE_SERVICE], createServiceSaga),
  takeLatest([actions.UPDATE_SERVICE], updateServiceSaga),
  takeLatest([actions.DELETE_SERVICE], deleteServiceSaga),
];
