import React from 'react';
import PropTypes from 'prop-types';
import { transactionStatus } from '../../../Transactions/constants/transactionsStatus';
import { CLAIM_STATUSES, CLAIM_TYPES } from '../../const/constants';

export const ClaimItemTransactionPanel = (props) => {
  const {
    transaction, claim, pay, confirmPay, getService,
  } = props;

  const renderPay = transaction.status === transactionStatus.CREATED && claim.status === CLAIM_STATUSES.AWAIT_PAYMENT;
  const renderCancelPayment = transaction.status === transactionStatus.CREATED && claim.status === CLAIM_STATUSES.AWAIT_PAYMENT;
  const renderGetService = transaction.status === transactionStatus.PAID && claim.claimType === CLAIM_TYPES.SECOND_OPINION
      && claim.status === CLAIM_STATUSES.PAID;
  const renderServicePaid = transaction.status === transactionStatus.PAID && claim.claimType !== CLAIM_TYPES.SECOND_OPINION;

  return (
    <div className="d-flex flex-row mt-2 col-12 justify-content-end">
    <div>
      {
        renderPay &&
          <button className="btn btn-sm btn-firm-filled mt-2 mb-2"
                  onClick={() => pay(transaction._id, transaction.claimId, claim?.service?.price,
                    claim.claimType === CLAIM_TYPES.SECOND_OPINION ? 1 : 2)}>
            Оплатить
          </button>
      }&nbsp;
    </div>
      {
          renderCancelPayment &&
          <button className="btn btn-custom btn-sm btn-secondary mt-2 mb-2"
                  onClick={() => confirmPay(transaction._id, CLAIM_STATUSES.IN_PROCESS)}>
            Отменить
          </button>
      }
    <div>
      {
        renderGetService &&
          <div className="btn-group mt-2">
            <button className="btn btn-sm btn-firm-filled mr-1 mt-2 mb-2" onClick={() => getService(transaction._id)}>
              Получить услугу
            </button>
          </div>
      }
      {
        renderServicePaid &&
          <div>
            <strong>Услуга оплачена</strong>
          </div>
      }
    </div>
  </div>
  );
};

ClaimItemTransactionPanel.propTypes = {
  transaction: PropTypes.shape({
    status: PropTypes.number.isRequired,
    _id: PropTypes.string.isRequired,
    claimId: PropTypes.string.isRequired,
  }).isRequired,
  claim: PropTypes.shape({
    status: PropTypes.number.isRequired,
    claimType: PropTypes.number.isRequired,
    service: PropTypes.shape({
      price: PropTypes.number.isRequired,
    }),
  }).isRequired,
  pay: PropTypes.func.isRequired,
  confirmPay: PropTypes.func.isRequired,
  getService: PropTypes.func.isRequired,

};
