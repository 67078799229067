export const initialState = {
  error: false,
  apiCallResult: [],
};

export const actions = {
  SENDTASK_MODAL_POST_REQUEST: 'SENDTASK_MODAL_POST:REQUEST',
  SENDTASK_MODAL_POST_SUCCESS: 'SENDTASK_MODAL_POST:SUCCESS',
  SENDTASK_MODAL_POST_ERROR: 'SENDTASK_MODAL_POST:ERROR',
};

export function sendTaskModalReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SENDTASK_MODAL_POST_REQUEST:
      return {
        ...state,
      };

    case actions.SENDTASK_MODAL_POST_SUCCESS:
      return {
        ...state,
        error: false,
        apiCallResult: action.payload,
      };

    case actions.SENDTASK_MODAL_POST_ERROR:
      return {
        ...state,
        error: true,
        apiCallResult: action.payload,
      };

    default:
      return { ...state };
  }
}
