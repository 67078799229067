import receiptCreator from './receiptCreator';

// TODO: add organization model - new feature admin will be able to choose who will pay for services,
//  add payer to transaction and modify it here in order to configure payment data

export default async function cloudPaymentsDataCreator(transactionId, claimId, sum, typeOfOrganization, user, config) {
  const data = {};
  const receipt = await receiptCreator(sum, user, typeOfOrganization, config);

  data.cloudPayments = {
    Inn: typeOfOrganization === 1 ? config.clinicSettings?.financial?.innSecondOpinion : config.clinicSettings?.financial?.innMedical,
    Type: 'Income',
    CultureName: 'ru-RU',
    InvoiceId: transactionId,
    AccountId: user.id,
    customerReceipt: receipt,
  };

  return data;
}
