import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/roboto';

import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import store from './App/store';
import './index.scss';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import ThemeProvider from './App/components/UI/Themes/ThemeProvider';

Sentry.init({
  dsn: 'https://3218edd4197540f7921f18a045c53d35@o1123170.ingest.sentry.io/6160823',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

render((
  <Provider className="app" store={store}>
    <BrowserRouter>
      <ThemeProvider/>
      <App/>
    </BrowserRouter>
  </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about services workers: http://bit.ly/CRA-PWA
serviceWorker.register();
