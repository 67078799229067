import React from 'react';
import PropTypes from 'prop-types';

export const ExternalLink = (props) => {
  const { url, hideModal } = props
  return(
    <div className="p-4 text-center">
      <h3>Переход по внешней ссылке</h3>
      <strong>Внимание, вы собираетесь покинуть сайт</strong>
      <div className="mt-2">
        <button
          className="btn btn-success btn-custom btn-sm mr-3">
          <a href={`${url}`} className="text-decoration-none text-white">Перейти</a>
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-custom btn-sm"
          onClick={hideModal}>Отменить
        </button>
      </div>
    </div>
  )
}

ExternalLink.propTypes = {
  url: PropTypes.string.isRequired,
};
