import withQuery from '../../../../store/utils/mutators/withQuery';
import withPagination from '../../../../store/utils/mutators/withPagination';
import withFetch from '../../../../store/utils/mutators/withFetch';

export const defaultState = {
  elements: [],
  _elements: null, // cache
  filter: [],
  fetching: false,
  error: false,
};

const initialState = {
  ...defaultState,
  ...withQuery(defaultState),
  ...withPagination(defaultState, 12),
  ...withFetch(defaultState),
};

export const actions = {
  REVIEWS_REQUEST: 'REVIEWS:REQUEST',
  REVIEWS_REQUEST_SUCCESS: 'REVIEWS:REQUEST_SUCCESS',
  REVIEWS_REQUEST_FAILED: 'REVIEWS:REQUEST_FAILED',

  REVIEWS_CHANGE_PAGE: 'REVIEWS:CHANGE_PAGE',
  REVIEWS_CHANGE_PAGE_SUCCESS: 'REVIEWS:CHANGE_PAGE_SUCCESS',
  REVIEWS_CHANGE_PAGE_FAILED: 'REVIEWS:CHANGE_PAGE_FAILED',
  REVIEWS_CHANGE_PAGE_RESET: 'REVIEWS:CHANGE_PAGE_RESET',
};

function reviewsRootReducer(state = initialState, action) {
  switch (action.type) {
    case actions.REVIEWS_REQUEST:
      return {
        ...state,
        fetching: true,
        query: action.payload || '',
      };

    case actions.REVIEWS_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload.reviews,
        _elements: action.payload.reviews,
        fetching: false,
        pagination: {
          ...state.pagination,
          end: false,
          count: action.payload.count,
        },
      };

    case actions.REVIEWS_CHANGE_PAGE:
      return {
        ...state,
        fetching: true,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          limit: state.pagination.limit,
        },
      };

    case actions.REVIEWS_CHANGE_PAGE_RESET:
      return {
        ...state,
        fetching: true,
        pagination: {
          ...initialState.pagination,
        },
      };

    case actions.REVIEWS_CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        fetching: false,
        pagination: { ...state.pagination },
      };

    default:
      return { ...state };
  }
}

export default reviewsRootReducer;
