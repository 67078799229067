import { actions } from './users.reducer';

export function getAllUsersAdmin(payload) {
  return {
    type: actions.USERS_ADMIN_REQUEST,
    payload,
  };
}

export function getAllUsersWithoutPagination(payload) {
  return {
    type: actions.USERS_ADMIN_NO_PAGINATION_REQUEST,
    payload,
  };
}

export function updateUsersAdmin(obj) {
  return {
    type: actions.USERS_ADMIN_UPDATE,
    payload: { ...obj },
  };
}

export function setPage(page) {
  return {
    type: actions.USERS_ADMIN_CHANGE_PAGE,
    payload: page,
  };
}

export function wipeUsers() {
  return {
    type: actions.USERS_ADMIN_WIPE,
  };
}

export function addUser(payload) {
  return {
    type: actions.USERS_ADMIN_ADD_USER,
    payload,
  };
}

export function userChangeRole(payload) {
  return {
    type: actions.USERS_ADMIN_CHANGE_ROLE_USER,
    payload,
  };
}

export function deleteUser(payload) {
  return {
    type: actions.USERS_ADMIN_DELETE_USER,
    payload,
  };
}

export function getUserInfo(payload) {
  return {
    type: actions.USERS_ADMIN_GET_USER,
    payload,
  };
}

export function changeLimit(payload) {
  return {
    type: actions.USERS_ADMIN_CHANGE_LIMIT,
    payload,
  };
}
