import { call, put } from 'redux-saga/effects';
import { actions } from './SendTaskModalReducer';
import { sendTask } from '../api';
import { actions as notifyActions } from '../../../../Notificator/notificatorReducer';
import { actions as modalActions } from '../../../store/modalReducer';

export function* sendTaskModalSaga(payload) {
  const { response, error } = yield call(sendTask, { ...payload });
  if (response) {
    yield put({
      type: actions.SENDTASK_MODAL_POST_SUCCESS,
      payload: response,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Спасибо! Заявка отправлена',
    });
    yield put({
      type: modalActions.HIDE_MODAL,
    });
  } else {
    yield put({
      type: actions.SENDTASK_MODAL_POST_ERROR,
      payload: error,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
    yield put({
      type: modalActions.HIDE_MODAL,
    });
  }
}
