import AxiosClient from '../../../functional/Axios/AxiosClient';
import randomBytes from '../../../functional/randomBytes';

const apiChatPath = '/chats/v2';

export const chatsApi = {
  fetchChats: ({ query }) => AxiosClient.get(`${apiChatPath}?${query}`),

  fetchClinicChats: ({ query }) => AxiosClient.get(`${apiChatPath}/clinic?${query}}`),

  fetchClinicChat: (id) => AxiosClient.get(`${apiChatPath}/clinic/${id}?${randomBytes()}`),

  fetchArchiveChats: ({ query }) => AxiosClient.get(`${apiChatPath}/archive?${query}`),

  fetchChat: (id) => AxiosClient.get(`${apiChatPath}/${id}?${randomBytes()}`),

  fetchUserInfo: (id) => AxiosClient.get(`/users/${id}`),

  fetchFile: (chatId, fileAlias) => AxiosClient.get(`/chats/${chatId}/${fileAlias}`, { responseType: 'blob' }),

  /**
 *
 * @param d Object {userId, doctorId}
 * @returns {Promise<T | *>}
 */
  createChatOrGet: ({ ...d }) => AxiosClient.put(`${apiChatPath}/`, { ...d }),

};
