import React, { Component } from 'react';
import { connect } from 'react-redux';
import './loader.scss';

class Loader extends Component {
  render() {
    const height = document.body.scrollHeight;

    if (this.props.loader.visible) {
      return (
        <div className="globalLoader text-center" style={{ height, backgroundColor: this.props.loader.block ? 'white' : '' }}>
          <div className="lds-heart">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (store) => ({
  loader: store.loader,
});

export default connect(mapStateToProps, {})(Loader);
