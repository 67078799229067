import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './components/UI/Themes/DefaultTheme/variables.scss';

import { ToastContainer } from 'react-toastify';

import { PrivateRoute } from './components/helpers/PrivateRoute/PrivateRoute';
import Breadcrumbs from './containers/Breadcrumbs/Breadcrumbs';
import Login from './components/Auth/components/Login/Login';
import AuthValidate from './components/Auth/components/AuthValidate/AuthValidate';
import NotFound from './components/NotFound/NotFound';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ResetPassword/ResetPassword';
import Chat from './components/Chat/Chat';
import Footer from './components/UI/Footer/Footer';
import Loader from './components/UI/Loader';
import Header from './components/UI/Header/Header';
import DoctorClaims from './components/Doctor/DoctorClaims/DoctorClaims';
import CallDoctorClaims from './components/Doctor/CallDoctorClaims/CallDoctorClaims';
import SidePanel from './components/UI/SidePanel/SidePanel';
import RegistrationContainer from './components/Registration/RegistrationContainer';
import RootPageRouter from './components/root/RootPageRouter';
import ModalContainer from './components/UI/Modal/Modal';
import Dialog from './components/UI/Dialog/Dialog';
import UserClaimsNew from './components/User/components/UserClaims/UserClaims2.0';
import ErrorBoundary from './components/UI/ErrorBoundary/ErrorBoundary';

const FaqContainer = lazy(() => import('./components/Faq/FaqContainer/FaqContainer'));

const Appointment = lazy(() => import('./components/UI/BookOptions/BookOptions'));
const ReviewsContainer = lazy(() => import('./components/Reviews/ReviewsContainer/ReviewsContainer'));
const UserEmr = lazy(() => import('./components/User/components/UserEmr/UserEmr'));
const ClinicContainer = lazy(() => import('./components/Clinic/ClinicContainer/ClinicContainer'));
const UserSettingsContainer = lazy(() => import('./components/User/components/UserSettings/UserSettings'));
const DoctorSettingsContainer = lazy(() => import('./components/Doctor/DoctorSettings/DoctorSettings'));
const UserFilesContainer = lazy(() => import('./components/User/components/UserFiles/UserFiles'));
const Chats = lazy(() => import('./components/Chat/components/Chats/Chats'));
const DoctorInfo = lazy(() => import('./components/Doctor/DoctorInfo/DoctorInfo'));
const DoctorPatients = lazy(() => import('./components/Doctor/DoctorPatients/DoctorPatients'));
const DoctorPatientInfo = lazy(() => import('./components/Doctor/DoctorPatients/DoctorPatientInfo/DoctorPatientInfo'));
const Home = lazy(() => import('./components/Home/Home'));

const ApplicationContainer = () => (
  <Switch>
    <PrivateRoute exact path="/" component={Home}/>

    <Route path="/auth" component={Login}/>
    <Route path="/auth/validate" component={AuthValidate}/>
    <Route exact path="/register" component={RegistrationContainer}/>
    <Route exact path="/forgotpassword" component={ForgotPassword}/>
    <Route exact path="/resetpassword/:token" component={ResetPassword}/>

    <PrivateRoute
      exact
      path="/transactions"
      component={UserClaimsNew}
    />
    <PrivateRoute exact path="/clinic" component={ClinicContainer}/>
    <PrivateRoute exact path="/clinic/:id" component={DoctorInfo}/>

    <PrivateRoute exact path="/claims" component={DoctorClaims} />
    <PrivateRoute exact path="/patients" component={DoctorPatients}/>
    <PrivateRoute exact path="/patients/:id" component={DoctorPatientInfo}/>
    <PrivateRoute exact path="/calldoctor" component={CallDoctorClaims} />
    <PrivateRoute exact path='/appointment' component={Appointment}/>
    <PrivateRoute exact path='/usersettings' component={UserSettingsContainer}/>
    <PrivateRoute exact path='/doctorsettings' component={DoctorSettingsContainer}/>
    <PrivateRoute exact path='/userfiles' component={UserFilesContainer}/>
    <PrivateRoute exact path='/faq' component={FaqContainer}/>
    <PrivateRoute exact path='/reviews' component={ReviewsContainer}/>
    <PrivateRoute exact path='/emr' component={UserEmr}/>

    <PrivateRoute exact path="/chats" component={Chats}/>
    <PrivateRoute path="/chats/:id" component={Chat}/>

    <PrivateRoute exact path="/appointment" component={Appointment}/>
    <PrivateRoute
      exact
      path="/usersettings"
      component={UserSettingsContainer}
    />
    <PrivateRoute
      exact
      path="/userfiles"
      component={UserFilesContainer}
    />
    <PrivateRoute exact path="/faq" component={FaqContainer}/>
    <PrivateRoute exact path="/news"><Redirect to="/" /></PrivateRoute>
    <PrivateRoute exact path="/reviews" component={ReviewsContainer}/>
    <PrivateRoute exact path="/emr" component={UserEmr}/>

    <PrivateRoute path="/root" component={RootPageRouter}/>
    <Route path="*" component={NotFound}/>
  </Switch>
);
function App() {
  return (
    <ErrorBoundary>
      <div className="app pb-5 mb-5">
        <Header />
        <ToastContainer style={{ marginTop: '3rem' }} autoClose={5000} position="top-left" pauseOnHover={false}/>
        <Breadcrumbs />
        <Suspense fallback={<Loader />}>
          <ApplicationContainer />
        </Suspense>
        <Loader />
        <Footer />
        <SidePanel />
        <ModalContainer />
        <Dialog />
      </div>
    </ErrorBoundary>
  );
}

export default App;
