import cloneDeep from 'lodash/cloneDeep';

export const initialState = {
  visible: false,
};

export const dialogActions = {
  SHOW_DIALOG: 'UI:SHOW_DIALOG',
  HIDE_DIALOG: 'UI:HIDE_DIALOG',
  LOAD_DIALOG: 'UI:LOAD_DIALOG',
};

export function dialogReducer(state = initialState, action) {
  switch (action.type) {
    /**
     * Just show modal w/o load
     */
    case dialogActions.SHOW_DIALOG:

      return {
        ...cloneDeep(state),
        visible: true,
      };

    /**
     * Hide and reset state
     */
    case dialogActions.HIDE_DIALOG:
      // todo::move this side effect somewhere else later
      setTimeout(() => ({
        ...initialState,
        visible: false,
      }), 1000);

      return {
        ...state,
        visible: false,
      };

    /**
     * Load dialog with component
     */
    case dialogActions.LOAD_DIALOG:
      return {
        ...cloneDeep(state),
        ...action.payload,
        visible: true,
      };

    default:
      return { ...state };
  }
}
