import AxiosClient from '../../../../../functional/Axios/AxiosClient';

export const replacementsAdminApi = {
  getReplacements: () => AxiosClient.get('/root/replacements/'),

  addReplacement: ({ ...payload }) => {
    const {
      id, replacement,
    } = payload.payload;
    return AxiosClient.post('/root/replacements/', {
      id, replacement,
    })
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  },
};
