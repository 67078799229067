/**
 * Create new state to add pagination fields
 * @param initialState object
 * @param limit
 */
export default function withPagination(initialState, limit = 10) {
  if (Object.prototype.hasOwnProperty.call(initialState, 'pagination')) {
    return initialState;
  }

  return {
    ...initialState,
    pagination: {
      page: 0,
      limit,
      end: false,
      count: 0,
    },

  };
}
