import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import {
  getApiDoctorInfo, getDoctorInfo, getDoctorInfoById, getDoctorProfiles, getDoctorsByBranch, getDoctors, getDoctorsByDirection,
} from '../api/doctor.api';
import { actions } from './doctors.reducer';
import { actions as notifyActions } from '../../UI/Notificator/notificatorReducer';
import { actions as modalActions } from '../../UI/Modal/store/modalReducer';
import { querystring } from '../../../store/utils/formatters/querystring';

export function* getApiDoctorInfoSaga(action) {
  try {
    const response = yield call(getApiDoctorInfo, { ...action.payload });
    yield put({
      type: actions.DOCTOR_GET_API_INFO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.DOCTOR_GET_API_INFO_ERROR });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Врач недоступен для записи',
    });
    yield put({
      type: modalActions.HIDE_MODAL,
    });
  }
}

export function* getDoctorInfoByIdSaga(action) {
  yield put({ type: 'SHOW_LOADER' });
  const response = yield call(getDoctorInfoById, { ...action.payload });
  if (response) {
    yield put({ type: 'SHOW_LOADER' });
    yield put({
      type: actions.DOCTOR_GET_INFO_ID_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } else {
    yield put({
      type: actions.DOCTOR_GET_INFO_ID_ERROR,
      payload: 'Ошибка загрузки врача',
    });
    yield put({ type: 'HIDE_LOADER' });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка загрузки данных',
    });
  }
}

export function* getDoctorInfoSaga(action) {
  yield put({ type: 'SHOW_LOADER' });
  const response = yield call(getDoctorInfo, { ...action.payload });
  if (response) {
    yield put({ type: 'SHOW_LOADER' });
    yield put({
      type: actions.DOCTOR_GET_INFO_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } else {
    yield put({
      type: actions.DOCTOR_GET_INFO_ERROR,
      payload: 'Ошибка загрузки данных',
    });
    yield put({ type: 'HIDE_LOADER' });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка загрузки данных',
    });
  }
}

export function* doctorsAsProfilesUpdate(action) {
  try {
    yield put({ type: 'SHOW_LOADER' });
    yield put({
      type: actions.DOCTORS_ASPROFILES_UPDATE_SUCCESS,
      payload: action.payload.elementsFiltered,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.DOCTORS_ASPROFILES_UPDATE_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}

export function* getDoctorAsProfilesSaga() {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const response = yield call(getDoctorProfiles);
    yield put({
      type: actions.DOCTORS_ASPROFILES_REQUEST_SUCCESS,
      payload: response.data.filter((el) => (el.user !== null)),
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.DOCTORS_ASPROFILES_REQUEST_FAILED });
    yield put({ type: 'HIDE_LOADER' });
  }
}

export function* getDoctorsByBranchSaga(action) {
  yield put({ type: 'SHOW_LOADER' });
  try {
    const response = yield call(getDoctorsByBranch, { ...action.payload });
    yield put({
      type: actions.DOCTORS_GET_BY_BRANCH_SUCCESS,
      payload: response.data.filter((el) => (el.user !== null)),
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: actions.DOCTORS_GET_BY_BRANCH_ERROR });
    yield put({ type: 'HIDE_LOADER' });
  }
}

export function* getDoctorsByDirectionSaga(action) {
  try {
    const response = yield call(getDoctorsByDirection, { ...action.payload });
    yield put({
      type: actions.DOCTORS_GET_BY_DIRECTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.DOCTORS_GET_BY_DIRECTION_ERROR });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка загрузки данных',
    });
    yield put({
      type: modalActions.HIDE_MODAL,
    });
  }
}

export function* getDoctorsSaga() {
  try {
    const pagination = yield select((state) => state.doctors.pagination);
    const query = yield select((state) => state.doctors.query);

    const q = { ...pagination, ...query };

    const response = yield call(getDoctors, { query: querystring(q) });

    yield put({
      type: actions.DOCTORS_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.DOCTORS_GET_ERROR });
  }
}

export const registerDoctorSagas = [
  takeEvery([actions.DOCTOR_GET_API_INFO_REQUEST], getApiDoctorInfoSaga),
  takeEvery([actions.DOCTOR_GET_INFO_ID_REQUEST], getDoctorInfoByIdSaga),
  takeEvery([actions.DOCTOR_GET_INFO_REQUEST], getDoctorInfoSaga),
  takeEvery([actions.DOCTORS_ASPROFILES_UPDATE], doctorsAsProfilesUpdate),
  takeEvery([actions.DOCTORS_ASPROFILES_REQUEST], getDoctorAsProfilesSaga),
  takeEvery([actions.DOCTORS_GET_BY_BRANCH_REQUEST], getDoctorsByBranchSaga),
  takeEvery([actions.DOCTORS_GET_BY_DIRECTION_REQUEST], getDoctorsByDirectionSaga),
  takeEvery([actions.DOCTORS_GET_REQUEST], getDoctorsSaga),
];
