export const initialState = {
  rights: {},
  fetch: false,
  userIsAllowed: false,
};

export const actions = {
  USER_RIGHTS_UPDATE_REQUEST: 'ADMIN:RIGHTS-MANAGER:UPDATE-REQUEST',
  USER_RIGHTS_UPDATE_SUCCESS: 'ADMIN:RIGHTS-MANAGER:UPDATE-SUCCESS',
  USER_RIGHTS_UPDATE_FAILED: 'ADMIN:RIGHTS-MANAGER:UPDATE-FAILED',

  USER_RIGHTS_REQUEST: 'ADMIN:RIGHTS-MANAGER:RIGHTS-REQUEST',
  USER_RIGHTS_SUCCESS: 'ADMIN:RIGHTS-MANAGER:RIGHTS-SUCCESS',
  USER_RIGHTS_FAILED: 'ADMIN:RIGHTS-MANAGER:RIGHTS-FAILED',

  USER_CHECK_RIGHTS_REQUEST: 'ADMIN:RIGHTS-MANAGER:CHECK_RIGHTS-REQUEST',
  USER_CHECK_RIGHTS_SUCCESS: 'ADMIN:RIGHTS-MANAGER:CHECK_RIGHTS-SUCCESS',
  USER_CHECK_RIGHTS_FAILED: 'ADMIN:RIGHTS-MANAGER:CHECK_RIGHTS-FAILED',

  USER_RIGHTS_DEFAULT_REQUEST: 'ADMIN:RIGHTS-MANAGER:DEFAULT_RIGHTS-REQUEST',
  USER_RIGHTS_DEFAULT_SUCCESS: 'ADMIN:RIGHTS-MANAGER:DEFAULT_RIGHTS-SUCCESS',
  USER_RIGHTS_DEFAULT_FAILED: 'ADMIN:RIGHTS-MANAGER:DEFAULT_RIGHTS-FAILED',
};

export function rightsManagerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.USER_RIGHTS_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.USER_RIGHTS_SUCCESS:
      return {
        ...state,
        fetch: false,
        rights: action.payload.rights,
      };

    case actions.USER_RIGHTS_FAILED:
      return {
        ...state,
        fetch: false,
      };

    case actions.USER_RIGHTS_UPDATE_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.USER_RIGHTS_UPDATE_SUCCESS:
      return {
        ...state,
        fetch: false,
        rights: action.payload.rights,
      };

    case actions.USER_RIGHTS_UPDATE_FAILED:
      return {
        ...state,
        fetch: false,
      };

    case actions.USER_RIGHTS_DEFAULT_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.USER_RIGHTS_DEFAULT_SUCCESS:
      return {
        ...state,
        fetch: false,
        rights: action.payload.rights,
      };

    case actions.USER_RIGHTS_DEFAULT_FAILED:
      return {
        ...state,
        fetch: false,
      };

    case actions.USER_CHECK_RIGHTS_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.USER_CHECK_RIGHTS_SUCCESS:
      return {
        ...state,
        fetch: false,
        userIsAllowed: true,
      };

    case actions.USER_CHECK_RIGHTS_FAILED:
      return {
        ...state,
        fetch: false,
      };

    default:
      return { ...state };
  }
}
