import React from 'react';
import PropTypes from 'prop-types';

const ClaimItemButtons = (props) => {
  const {
    claim, toggleModal,
  } = props;
  return (
    <div className="col-12">
      <div className="row d-sm-flex justify-content-end">
        <div className="text-right">
          {
            !claim.transaction && claim.status !== 2
            && <button
              type="button"
              className="btn-custom btn btn-sm btn-danger m-1"
              onClick={toggleModal}>
              Закрыть заявку
            </button>
          }
        </div>
      </div>
    </div>
  );
};

ClaimItemButtons.propTypes = {
  claim: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  claimCloseSuccess: PropTypes.func.isRequired,
};

export default ClaimItemButtons;
