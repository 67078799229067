import withQuery from '../../../store/utils/mutators/withQuery';
import withPagination from '../../../store/utils/mutators/withPagination';
import withFetch from '../../../store/utils/mutators/withFetch';

export const defaultState = {
  elements: [],
  elementsFiltered: [],
  doctorProfile: {},
  fetching: false,
  error: false,
  doctorInfo: {},
  apiInfo: [],
  apiFetching: false,
  filter: [],
};

const initialState = {
  ...defaultState,
  ...withQuery(defaultState),
  ...withPagination(defaultState, 10),
  ...withFetch(defaultState),
};

export const actions = {
  DOCTORS_ASPROFILES_REQUEST: 'DOCTORS_ASPROFILES:REQUEST',
  DOCTORS_ASPROFILES_REQUEST_SUCCESS: 'DOCTORS_ASPROFILES:REQUEST_SUCCESS',
  DOCTORS_ASPROFILES_REQUEST_FAILED: 'DOCTORS_ASPROFILES:REQUEST_FAILED',

  DOCTORS_ASPROFILES_UPDATE: 'DOCTORS_ASPROFILES_UPDATE:UPDATE',
  DOCTORS_ASPROFILES_UPDATE_SUCCESS: 'DOCTORS_ASPROFILES_UPDATE:UPDATE_SUCCESS',
  DOCTORS_ASPROFILES_UPDATE_FAILED: 'DOCTORS_ASPROFILES_UPDATE:UPDATE_FAILED',

  DOCTORS_GET_BY_BRANCH_REQUEST: 'DOCTORS_GET_BY_BRANCH:REQUEST',
  DOCTORS_GET_BY_BRANCH_SUCCESS: 'DOCTORS_GET_BY_BRANCH:SUCCESS',
  DOCTORS_GET_BY_BRANCH_ERROR: 'DOCTORS_GET_BY_BRANCH:ERROR',

  DOCTOR_GET_INFO_REQUEST: 'DOCTOR_GET_INFO:REQUEST',
  DOCTOR_GET_INFO_SUCCESS: 'DOCTOR_GET_INFO:SUCCESS',
  DOCTOR_GET_INFO_ERROR: 'DOCTOR_GET_INFO:ERROR',

  DOCTOR_GET_API_INFO_REQUEST: 'DOCTOR_GET_API_INFO:REQUEST',
  DOCTOR_GET_API_INFO_SUCCESS: 'DOCTOR_GET_API_INFO:SUCCESS',
  DOCTOR_GET_API_INFO_ERROR: 'DOCTOR_GET_API_INFO:ERROR',

  DOCTORS_GET_BY_DIRECTION_REQUEST: 'DOCTORS_GET_BY_DIRECTION:REQUEST',
  DOCTORS_GET_BY_DIRECTION_SUCCESS: 'DOCTORS_GET_BY_DIRECTION:SUCCESS',
  DOCTORS_GET_BY_DIRECTION_ERROR: 'DOCTORS_GET_BY_DIRECTION:ERROR',

  DOCTOR_GET_INFO_ID_REQUEST: 'DOCTOR_GET_INFO_ID:REQUEST',
  DOCTOR_GET_INFO_ID_SUCCESS: 'DOCTOR_GET_INFO_ID:SUCCESS',
  DOCTOR_GET_INFO_ID_ERROR: 'DOCTOR_GET_INFO_ID:ERROR',

  DOCTORS_GET_REQUEST: 'DOCTORS_GET:REQUEST',
  DOCTORS_GET_SUCCESS: 'DOCTORS_GET:SUCCESS',
  DOCTORS_GET_ERROR: 'DOCTORS_GET:ERROR',

  DOCTORS_CHANGE_PAGE: 'DOCTORS:CHANGE_PAGE',
  DOCTORS_CHANGE_PAGE_SUCCESS: 'DOCTORS:CHANGE_PAGE_SUCCESS',
  DOCTORS_CHANGE_PAGE_FAILED: 'DOCTORS:CHANGE_PAGE_FAILED',
  DOCTORS_CHANGE_PAGE_RESET: 'DOCTORS:CHANGE_PAGE_RESET',
};

export function doctorsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.DOCTORS_ASPROFILES_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.DOCTORS_ASPROFILES_REQUEST_SUCCESS:
      return {
        ...state,
        elements: action.payload,
        elementsFiltered: action.payload,
        fetching: false,
      };

    case actions.DOCTORS_ASPROFILES_UPDATE:
      return {
        ...state,
        fetching: true,
      };

    case actions.DOCTORS_ASPROFILES_UPDATE_SUCCESS:
      return {
        ...state,
        elementsFiltered: action.payload,
        fetching: false,
      };

    case actions.DOCTOR_GET_INFO_REQUEST:
      return {
        ...state,
      };

    case actions.DOCTOR_GET_INFO_SUCCESS:
      return {
        ...state,
        error: false,
        doctorInfo: action.payload,
      };

    case actions.DOCTOR_GET_INFO_ERROR:
      return {
        ...state,
        error: true,
      };

    case actions.DOCTOR_GET_API_INFO_REQUEST:
      return {
        ...state,
        apiFetching: true,
      };

    case actions.DOCTOR_GET_API_INFO_SUCCESS:
      return {
        ...state,
        error: false,
        apiFetching: false,
        apiInfo: action.payload,
      };

    case actions.DOCTOR_GET_API_INFO_ERROR:
      return {
        ...state,
        error: true,
        apiFetching: false,
      };

    case actions.DOCTORS_GET_BY_DIRECTION_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.DOCTORS_GET_BY_DIRECTION_SUCCESS:
      return {
        ...state,
        error: false,
        fetching: false,
        doctors: action.payload,
      };

    case actions.DOCTORS_GET_BY_DIRECTION_ERROR:
      return {
        ...state,
        error: true,
        fetching: false,
      };

    case actions.DOCTORS_GET_BY_BRANCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case actions.DOCTORS_GET_BY_BRANCH_SUCCESS:
      return {
        ...state,
        error: false,
        fetching: false,
        elementsFiltered: action.payload,
      };

    case actions.DOCTORS_GET_BY_BRANCH_ERROR:
      return {
        ...state,
        error: true,
        fetching: false,
      };

    case actions.DOCTOR_GET_INFO_ID_REQUEST:
      return {
        ...state,
      };

    case actions.DOCTOR_GET_INFO_ID_SUCCESS:
      return {
        ...state,
        error: false,
        doctorInfo: action.payload,
      };

    case actions.DOCTOR_GET_INFO_ID_ERROR:
      return {
        ...state,
        error: true,
      };

    case actions.DOCTORS_GET_REQUEST:
      return {
        ...state,
        fetching: true,
        query: action.payload || '',
      };

    case actions.DOCTORS_GET_SUCCESS:
      return {
        ...state,
        elements: action.payload.doctors,
        elementsFiltered: action.payload.doctors,
        fetching: false,
        pagination: {
          ...state.pagination,
          end: false,
          count: action.payload.count,
        },
      };

    case actions.DOCTORS_GET_ERROR:
      return {
        ...state,
        fetching: false,
        elements: [],
        elementsFiltered: [],
      };

    case actions.DOCTORS_CHANGE_PAGE:
      return {
        ...state,
        fetching: true,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          limit: state.pagination.limit,
        },
      };

    case actions.DOCTORS_CHANGE_PAGE_RESET:
      return {
        ...state,
        fetching: true,
        pagination: {
          ...initialState.pagination,
        },
      };

    case actions.DOCTORS_CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        fetching: false,
        pagination: { ...state.pagination },
      };

    default:
      return { ...state };
  }
}

export default doctorsReducer;
