import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '../../../containers/Pagination/Pagination';
import RootClaimItem from '../ClaimItem/RootClaimItem/RootClaimItem';
import AdminClaimItem from '../ClaimItem/AdminClaimItem/AdminClaimItem';
import DoctorClaimItem from '../ClaimItem/DoctorClaimItem/DoctorClaimItem';
import UserClaimItem from '../ClaimItem/UserClaimItem/UserClaimItem';
import { ACCESS_ROLES } from '../../../functional/roles';

const ClaimsContainer = props => {
  const {
    elements,
    pagination,
    appconfig,
    doctorServices,
    account,
    hideModal,
    loadModal,
    fetch,
    handleChangePage,
    doctors,
    startPayment,
    confirmPayment,
    getService,
    users
  } = props;

  if (elements && elements.length > 0) {
    return (
      <>
        <small>Всего заявок: {pagination.count}</small>
        {elements &&
          elements.map(claim => {
            const baseClaimProps = {
              claim,
              appconfig,
              account,
              getService,
              startPayment,
              confirmPayment,
              docs: doctors
            };
            return (
              <div key={claim._id} className="list-group claimscontainer">
                {claim.user && (
                  <div key={claim._id}>
                    {account?.user?.role === +ACCESS_ROLES.ROOT && (
                      <RootClaimItem
                        {...baseClaimProps}
                        hideModal={hideModal}
                        loadModal={loadModal}
                        fetch={fetch}
                        users={users}
                      />
                    )}
                    {account?.user?.role === +ACCESS_ROLES.ADMIN && (
                      <AdminClaimItem
                        {...baseClaimProps}
                        hideModal={hideModal}
                        loadModal={loadModal}
                        fetch={fetch}
                      />
                    )}
                    {account?.user?.role === +ACCESS_ROLES.DOCTOR && (
                      <DoctorClaimItem
                        {...baseClaimProps}
                        hideModal={hideModal}
                        loadModal={loadModal}
                        fetch={fetch}
                        services={doctorServices}
                      />
                    )}
                    {account?.user?.role === +ACCESS_ROLES.USER && (
                      <UserClaimItem
                        {...baseClaimProps}
                        services={doctorServices}
                      />
                    )}
                  </div>
                )}
              </div>
            );
          })}
        <div className="col-12 text-center">
          {
            <Pagination
              className="mt-0 mb-2"
              page={pagination.page + 1}
              count={Math.ceil(pagination.count / pagination.limit)}
              handleChange={handleChangePage}
            />
          }
        </div>
      </>
    );
  }
  return <div className="claimscontainer-nocontent">Пока здесь ничего нет</div>;
};

ClaimsContainer.propTypes = {
  elements: PropTypes.array,
  pagination: PropTypes.object.isRequired,
  appconfig: PropTypes.object.isRequired,
  doctorServices: PropTypes.array,
  users: PropTypes.array,
  account: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  loadModal: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func,
  doctors: PropTypes.array,
  startPayment: PropTypes.func,
  confirmPayment: PropTypes.func,
  getService: PropTypes.func
};

export default ClaimsContainer;
