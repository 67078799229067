import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../../../../containers/UI/Buttons/Button';

export function VideoFinisher(props) {
  const { videoChat, webrtcStatus, cancelCall } = props;
  return (
    <div>
      {
        (videoChat === true || webrtcStatus > 0) && <>
          <Button onClick={cancelCall} className="btn-danger btn-sm">Завершить звонок</Button>
        </>
      }
    </div>);
}
VideoFinisher.propTypes = {
  videoChat: PropTypes.bool.isRequired,
  webrtcStatus: PropTypes.number.isRequired,
  cancelCall: PropTypes.func.isRequired,
};
