import React from 'react';
import ReactDOM from 'react-dom';
import { Badge } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { connect } from 'react-redux';
import Button from '../../../containers/UI/Buttons/Button';
import { notifyError, notifySuccess } from '../../UI/Notificator/notificatorActions';
import { userNotificationsToggle } from '../../UI/Header/store/headerActions';
import { getUser } from '../../Auth/store/auth.actions';
import { changeStatusNotificationAction, readAll } from '../store/notificationsActions';
import { showClinicChat } from '../../Chat/components/ChatModal/chatModalActions';
import { NOTIFICATION_GROUPS } from '../const/constants';
import NotificationsDropdown from '../NotificationsDropdown/NotificationsDropdown';
import LocalLoader from '../../../containers/UI/LocalLoader';

const FetchNotificationsLoader = () => (
    <div className="header--dropdown shadow-sm notification-container bg-white">
      <LocalLoader relative={true}/>
    </div>);

class NotificationsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleGroup: NOTIFICATION_GROUPS.UNREAD,
    };
  }

    handleClickOutside = (event) => {
      const domNode = ReactDOM.findDOMNode(this);

      if (!domNode || !domNode.contains(event.target)) {
        this.props.userNotificationsToggle();
      }
    };

    componentDidUpdate() {
      if (this.props.header.notificationsVisible === false) {
        document.removeEventListener('click', this.handleClickOutside, true);
      }
    }

    handleClick = () => {
      this.props.userNotificationsToggle();
      if (!this.props.header.notificationsVisible) {
        document.addEventListener('click', this.handleClickOutside, true);
      }
    };

    /**
     *
     * @param condition
     * @returns {Array}
     */
    filter = (condition) => {
      if (typeof condition === 'number') {
        this.setState({ visibleGroup: condition });
        return [];
      }

      if (this.props.notifications && this.props.notifications.length > 0) {
        if (this.state.visibleGroup === NOTIFICATION_GROUPS.ALL) return this.props.notifications;
        return this.props.notifications.filter(((notification) => notification.status === this.state.visibleGroup));
      }

      return [];
    };

    statusHandler = (notificationId) => {
      switch (this.state.visibleGroup) {
        case NOTIFICATION_GROUPS.ALL:
          return this.props.changeStatusNotificationAction(NOTIFICATION_GROUPS.DELETED, notificationId);

        case NOTIFICATION_GROUPS.UNREAD:
          return this.props.changeStatusNotificationAction(NOTIFICATION_GROUPS.READ, notificationId);

        default:
          return null;
      }
    };

    render() {
      const {
        fetch, notifications, header, readAll, showClinicChat,
      } = this.props;

      return (
            <div>
                {fetch && <FetchNotificationsLoader/>}
                {
                  header.bellVisible &&
                    <Button onClick={this.handleClick} className="__nb disabled mr-2">
                      <Badge badgeContent={notifications.filter((n) => n.status === 0).length} color="primary" overlap="rectangular">
                        <span>
                          <FontAwesomeIcon icon={faBell} title="Центр уведомлений"/>
                        </span>
                      </Badge>
                    </Button>
                }
                {header.notificationsVisible && (
                    <NotificationsDropdown
                        list={this.filter()}
                        filter={this.filter}
                        readAll={readAll}
                        readHandler={this.statusHandler}
                        visibleGroup={this.state.visibleGroup}
                        showClinicChat={showClinicChat}
                    />)}
            </div>
      );
    }
}

const mapStateToProps = (store) => ({
  account: store.account,
  header: store.components.header,
  notifications: store.notifications.elements,
  newNotifications: store.notifications.isNew,
  fetch: store.notifications.fetch,
});

const mapDispatchToProps = {
  notifySuccess,
  notifyError,
  userNotificationsToggle,
  getUser,
  changeStatusNotificationAction,
  readAll,
  showClinicChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPanel);
