import AxiosClient from '../../../../../functional/Axios/AxiosClient';

export function fetchHistoryVisits({ ...payload }) {
  const {
    profileId,
  } = payload.payload;
  return AxiosClient.post('/users/emrrecsall', {
    profileId,
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function fetchHistoryVisitDetails({ ...payload }) {
  const {
    profileId, emrRecId,
  } = payload.payload;
  return AxiosClient.post('/users/emrrecdet', {
    profileId, emrRecId,
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function fetchFutureVisits({ ...payload }) {
  const {
    profileId,
  } = payload.payload;
  return AxiosClient.post('/users/futurevisits', {
    profileId,
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function cancelFutureVisit({ ...payload }) {
  const {
    id, profileId, dateOf, recId, docName, enableDeleteVisitsFromSchedule,
  } = payload.payload;
  return AxiosClient.post('/schedule/visit/delete', {
    id, profileId, dateOf, recId, docName, enableDeleteVisitsFromSchedule,
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function fetchUserFiles({ ...payload }) {
  const {
    profileId,
  } = payload.payload;
  return AxiosClient.post('/users/files', {
    profileId,
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}
