export default async function receiptCreator(sum, user, typeOfOrganization, config) {
  const receipt = {
    Items: [
      {
        label: typeOfOrganization === 1 ? 'Услуга: Второе мнение' : 'Предоплата медицинской услуги',
        price: sum,
        quantity: 1,
        amount: sum,
        vat: typeOfOrganization === 1 ? config.clinicSettings.vatSecondOpinion : config.clinicSettings.vatMedical,
        method: 1,
        object: 4,
      },
    ],
    amounts: {
      electronic: sum,
    },
    taxationSystem: typeOfOrganization === 1 ? 1 : 0, // система налогообложения; необязательный, если у вас одна система налогообложения
    email: user.email, // e-mail покупателя, если нужно отправить письмо с чекомк
  };

  return receipt;
}
