/**
 * client representation of status enums;
 * @link TRANSACTION_STATUS server/services/Transactions.services.ts
 */
export const transactionStatus = {
  CREATED: 0,
  IN_PROCESS: 1,
  FAIL: 2,
  EXPIRED: 3,
  CANCELED_BY_USER: 4,
  CANCELED_BY_DOCTOR: 5,
  CANCELED_BY_ADMIN: 6,
  PAID: 7,
  DONE: 8,
};

export const TransactionStatus = {
  0: 'Создана',
  1: 'В процессе обработки',
  2: 'Ошибка',
  3: 'Истек срок',
  4: 'Завершена пользователем',
  5: 'Завершена врачом',
  6: 'Завершена администратором',
  7: 'Оплачена',
  8: 'Выполнена',
};
