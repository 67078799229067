import {
  call, put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { emrRecordsApi } from '../api';
import { actions } from './emrRecords.reducer';
import { actions as notifyActions } from '../../UI/Notificator/notificatorReducer';
import { querystring } from '../../../store/utils/formatters/querystring';
import { actions as sidePanelActions } from '../../UI/SidePanel/store/sidePanel.reducer';

export function* emrRecordsGetSaga() {
  try {
    yield put({ type: 'SHOW_LOADER' });
    const pagination = yield select((state) => state.emrRecords.pagination);
    const query = yield select((state) => state.emrRecords.query);

    const q = { ...pagination, ...query };
    const response = yield call(emrRecordsApi.emrRecordsGet, { query: querystring(q) });
    yield put({
      type: actions.EMR_RECORDS_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({ type: 'HIDE_LOADER' });
  } catch (error) {
    yield put({ type: 'HIDE_LOADER' });
    yield put({ type: actions.EMR_RECORDS_REQUEST_FAILED });
  }
}

export function* createEmrRecordSaga(action) {
  const response = yield call(emrRecordsApi.emrRecordCreate, { ...action.payload });
  if (response) {
    yield put({
      type: actions.EMR_RECORDS_CREATE_REQUEST_SUCCESS,
    });
    yield put({
      type: sidePanelActions.HIDE_SIDE_PANEL,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Запись успешно создана',
    });
  } else {
    yield put({
      type: actions.EMR_RECORDS_CREATE_REQUEST_FAILED,
    });
  }
}

export function* updateEmrRecordSaga(action) {
  const response = yield call(emrRecordsApi.emrRecordUpdate, { ...action.payload });
  if (response) {
    yield put({
      type: actions.EMR_RECORDS_UPDATE_REQUEST_SUCCESS,
    });
    yield put({
      type: sidePanelActions.HIDE_SIDE_PANEL,
    });
    yield put({
      type: notifyActions.NOTIFICATION_SUCCESS,
      payload: 'Запись успешно обновлена',
    });
  } else {
    yield put({
      type: actions.EMR_RECORDS_UPDATE_REQUEST_FAILED,
    });
    yield put({
      type: notifyActions.NOTIFICATION_ERROR,
      payload: 'Ошибка отправки данных',
    });
  }
}

export const registerEmrRecordsSagas = [
  takeLatest([
    actions.EMR_RECORDS_REQUEST,
    actions.EMR_RECORDS_CHANGE_PAGE,
  ], emrRecordsGetSaga),
  takeEvery([actions.EMR_RECORDS_CREATE_REQUEST], createEmrRecordSaga),
  takeEvery([actions.EMR_RECORDS_UPDATE_REQUEST], updateEmrRecordSaga),
];
