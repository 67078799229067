import React from 'react';
import Select from 'react-select';
import _isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
/**
 * Component for react-select to provide fast selection in array of items
 * @selectedId id of selected element
 * @change onChange callback function
 * @options - array of options { label, value }
 */
class ListSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: '',
    };
  }

  change = (selectedOption) => {
    if (_isFunction(this.props.cb)) {
      if (this.props.name) { this.props.cb(selectedOption.value, this.props.name); } else {
        this.props.cb(selectedOption.value);
      }
    }
    this.setState({ selectedValue: selectedOption.value });
  };

  render() {
    const { defaultValue, options, fixed } = this.props;
    const { selectedValue } = this.state;
    const _selectedValue = selectedValue || defaultValue;
    const _selectedValues = options?.filter((o) => o.value === _selectedValue);

    if (fixed) {
      return (
        <Select
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          key={options && options.value}
          onChange={this.change}
          options={options && options}
          isSearchable={true}
          placeholder={this.props.placeholder}
          defaultValue="Выберите услугу..."
          value={_selectedValues}
          noOptionsMessage={() => 'Ничего не найдено'}
        />
      );
    }
    return (
      <Select
        key={options && options.value}
        onChange={this.change}
        options={options && options}
        isSearchable={true}
        placeholder={this.props.placeholder}
        defaultValue="Выберите услугу..."
        value={_selectedValues}
        noOptionsMessage={() => 'Ничего не найдено'}
      />
    );
  }
}
ListSelect.propTypes = {
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  cb: PropTypes.func,
};
export default ListSelect;
