import AxiosClient from '../../../../../functional/Axios/AxiosClient';

export const templatesAdminApi = {
  getTemplates: () => AxiosClient.get('/root/templates/'),

  addTemplate: ({ ...data }) => {
    const {
      emailTemplate, key, name, emailSubject, pushTemplate, pushType,
    } = data.payload;
    return AxiosClient.post('/root/templates/', {
      emailTemplate, key, name, emailSubject, pushTemplate, pushType,
    })
      .then((response) => ({ response }));
  },

  updateTemplate: ({ ...data }) => {
    const {
      emailTemplate, key, name, emailSubject, pushTemplate, id, pushType,
    } = data.payload;
    return AxiosClient.put('/root/templates/', {
      emailTemplate, key, name, emailSubject, pushTemplate, id, pushType,
    })
      .then((response) => ({ response }));
  },

  addReplacementsToTemplate: ({ ...data }) => {
    const {
      replacements, id,
    } = data.payload;
    return AxiosClient.post('/root/templates/replacement', {
      replacements, id,
    })
      .then((response) => ({ response }));
  },
};
