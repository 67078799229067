import { actions } from './news.reducer';

export function getLatestNews(obj) {
  return {
    type: actions.NEWS_LATEST_REQUEST,
    payload: { ...obj },
  };
}

export function newsGetAll() {
  return {
    type: actions.NEWS_REQUEST,
  };
}

export function getNews(payload) {
  return {
    type: actions.NEWS_REQUEST_PAGINATED,
    payload,
  };
}

export function newsSetPage(page) {
  return {
    type: actions.NEWS_CHANGE_PAGE,
    payload: { page },
  };
}

export function newsResetPage() {
  return {
    type: actions.NEWS_CHANGE_PAGE_RESET,
  };
}
