import { put } from 'redux-saga/effects';
import { actions } from './headerReducer';

export function* hideUserMenuSaga() {
  try {
    yield put({ type: actions.HIDE_MENU });
  } catch (error) {
    console.log(error);
  }
}

export function* showUserMenuSaga() {
  try {
    yield put({ type: actions.SHOW_MENU });
  } catch (error) {
    console.log(error);
  }
}
