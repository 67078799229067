import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import './css/style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { connect } from 'react-redux';
import { DATA_TYPES } from '../types/VALIDATE';
import { InputBase } from '../../../containers/Inputs/InputBase/inputBase';

/**
 * Component Confirm User Information
 *
 * @param {Object} props - component props
 * @param {string} props.name - component instance name
 * @param {string} props.type - component instance type
 * @param {function} props.handler - callback function
 * @param {Object} props.register - store state
 *
 * @returns {JSX.Element}
 */
class Confirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
    };
  }

  onChange = (event) => {
    this.setState({
      code: event.target.value,
    });
  };

  render() {
    const {
      type, refresh, handler, register,
    } = this.props;
    const { code } = this.state;

    return (
      <div className="col-12 b-confirm border border-warning mb-3">
        <div className="row">
          <div className="col-12 pb-3">
            <small hidden={type === DATA_TYPES.email}>
              Нам нужно удостовериться, что ваш номер телефона актуален. <br />&emsp;
              На указанный телефон мы отправили код, введите его, пожалуйста, в
              поле ниже и нажмите &quot;Подтвердить&quot;.
            </small>
            <small hidden={type === DATA_TYPES.phone}>
              Нам нужно удостовериться, что ваша почта актуальна. <br /> На
              указанный ящик мы отправили код, введите его, пожалуйста, в поле
              ниже и нажмите &quot;Подтвердить&quot;.
            </small>
          </div>
          <div className="col-12">
            <div className="form-group">
              <InputBase
                type="text"
                className="form-control"
                placeholder="Код подтверждения"
                name={`${type}-confirm`}
                value={code}
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center mb-3">
            <div>
              <button
                type="button"
                className="btn btn-info btn-sm mr-2"
                onClick={(e) => {
                  refresh();
                  e.target.disabled = true;
                }}
              >
                <FontAwesomeIcon icon={faSync} />
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-success btn-sm"
                disabled={register.verifyInProcess || !code}
                onClick={() => handler(type, code)}
              >
                Подтвердить {type === DATA_TYPES.email ? 'почту' : 'телефон'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Confirm.propTypes = {
  name: PropTypes.string,
  type: PropTypes.oneOf([DATA_TYPES.email, DATA_TYPES.phone]).isRequired,
  refresh: PropTypes.func,
  handler: PropTypes.func,
  register: PropTypes.shape({
    verifyInProcess: PropTypes.bool,
  }),
};

Confirm.defaultProps = {
  name: '',
  type: '',
  handler: () => {},
  register: {
    verifyInProcess: false,
  },
};

const mapStateToProps = (store) => ({
  register: store.register,
});

export default connect(mapStateToProps)(Confirm);
