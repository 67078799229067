import React from 'react';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';

// async select for a big data shit pieces (all users for example)

/**
 * Kek
 * @selectedId id of selected element
 * @change onChange callback function
 * @options - array of options
 */
class AsyncListSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: '',
      isLoading: false,
    };
    const wait = 500; // milliseconds
    const loadOptions = (inputValue) => this.getAsyncOptions(inputValue);
    this.debouncedLoadOptions = _.debounce(loadOptions, wait);

    const fetchData = (inputValue) => this.props.fetchData(inputValue)
    this.debouncedFetch = _.debounce(fetchData, wait)
  }

  getAsyncOptions(inputValue) {
    this.setState({ isLoading: true });
    if (inputValue.length >= 3) {
      this.debouncedFetch(inputValue)
    }
    return new Promise((resolve, reject) => {
      const filtered = _.filter(this.props.options, (o) => _.startsWith(_.toLower(o.label), _.toLower(inputValue)));
      resolve(filtered.slice(0, 3));
      this.setState({ isLoading: false });
    });
  }

  change = (selectedOption) => {
    this.props.cb(selectedOption.value);
    this.setState({ selectedValue: selectedOption.value });
  };

  onBlur = () => {
    this.setState({ isLoading: false });
  }

  render() {
    const { defaultValue, options } = this.props;
    const { selectedValue } = this.state;
    const _selectedValue = selectedValue || defaultValue;
    const _selectedValues = options?.filter((o) => o.value === _selectedValue);

    return (
      <AsyncSelect
        cacheOptions
        key={options && options.value}
        onChange={this.change}
        loadOptions={(inputValue) => this.getAsyncOptions(inputValue)}
        isSearchable={true}
        placeholder={this.props.placeholder}
        defaultValue="Выберите услугу..."
        value={_selectedValues}
        onBlur={this.onBlur}
        isLoading={this.state.isLoading}
        noOptionsMessage={() => 'Ничего не найдено'}
      />
    );
  }
}

export default AsyncListSelect;
