import { toast } from 'react-toastify';
import { FilesApi } from '../../User/components/UserFiles/api/files.api';
import downloadBlob from '../../../utils/downloadBlob';

export const downloadChatFile = (e) => {
  const target = e.currentTarget;

  if (target?.dataset?.alias && target?.dataset?.filename) {
    FilesApi.downloadFile(target.dataset.alias)
      .then(({ data }) => {
        downloadBlob(data, target.dataset.filename);
      }).catch((e) => {
        console.log(e);
        toast.error('Файл был удален или больше вам недоступен');
      });
  }
};
