export const ALLOWED_MIMES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel'
];

export const IMAGES_MIMES = [
  'image/jpeg',
  'image/png',
  'image/svg+xml'
];

export const EXTENSION_TO_MIME = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  exel: 'application/vnd.ms-excel'
};
