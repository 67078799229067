function b64E(str) {
  return btoa(encodeURIComponent(str)
    .replace(/%([0-9A-F]{2})/g,
      (match, p1) => String.fromCharCode(`0x${p1}`)));
}

function b64D(str) {
  return decodeURIComponent(atob(str)
    .split('')
    .map((c) => `%${(`00${c.charCodeAt(0)
      .toString(16)}`).slice(-2)}`)
    .join(''));
}

class CSessionStorageCrypt {
  getItem(key) {
    const s = window.sessionStorage;

    if (!s.getItem(key)) return null;
    return JSON.parse(b64D(s.getItem(key)));
  }

  setItem(key, obj) {
    const s = window.sessionStorage;
    s.setItem(key, b64E(JSON.stringify(obj)));
  }

  remove(key) {
    window.sessionStorage.removeItem(key);
  }
}

const SessionStorageCrypt = new CSessionStorageCrypt();

export default SessionStorageCrypt;
