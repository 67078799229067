import withQuery from '../../../../store/utils/mutators/withQuery';
import withPagination from '../../../../store/utils/mutators/withPagination';
import withFetch from '../../../../store/utils/mutators/withFetch';

const defaultState = {
  elements: [],
  filter: [],
  fetch: false,
};

const initialState = {
  ...defaultState,
  ...withQuery(defaultState),
  ...withPagination(defaultState, 12),
  ...withFetch(defaultState),
};

export const actions = {
  CHATS_ADMIN_GET_REQUEST: 'CHATS_ADMIN:REQUEST',
  CHATS_ADMIN_GET_SUCCESS: 'CHATS_ADMIN:SUCCESS',
  CHATS_ADMIN_GET_FAILED: 'CHATS_ADMIN:FAILED',

  CHATS_ADMIN_CHANGE_PAGE: 'CHATS_ADMIN:CHANGE_PAGE',
  CHATS_ADMIN_CHANGE_PAGE_SUCCESS: 'CHATS_ADMIN:CHANGE_PAGE_SUCCESS',
  CHATS_ADMIN_CHANGE_PAGE_FAILED: 'CHATS_ADMIN:CHANGE_PAGE_FAILED',

  CHATS_CLINIC_ADMIN_GET_REQUEST: 'CHATS_CLINIC_ADMIN:REQUEST',
  CHATS_CLINIC_ADMIN_GET_SUCCESS: 'CHATS_CLINIC_ADMIN:SUCCESS',
  CHATS_CLINIC_ADMIN_GET_FAILED: 'CHATS_CLINIC_ADMIN:FAILED',

  CHATS_ADMIN_CLINIC_CHANGE_PAGE: 'CHATS_ADMIN_CLINIC:CHANGE_PAGE',
  CHATS_ADMIN_CLINIC_CHANGE_PAGE_SUCCESS: 'CHATS_ADMIN_CLINIC:CHANGE_PAGE_SUCCESS',
  CHATS_ADMIN_CLINIC_CHANGE_PAGE_FAILED: 'CHATS_ADMIN_CLINIC:CHANGE_PAGE_FAILED',
  CHATS_ADMIN_CLINIC_PAGE_RESET: 'CHATS_ADMIN_CLINIC:PAGE_RESET',

  CHATS_PAGE_ADMIN_RESET: 'CHATS_PAGE_ADMIN_RESET',

  ADMIN_ADD_TO_CHAT_REQUEST: 'ADMIN_ADD_TO_CHAT:REQUEST',
  ADMIN_ADD_TO_CHAT_SUCCESS: 'ADMIN_ADD_TO_CHAT:SUCCESS',
  ADMIN_ADD_TO_CHAT_FAILED: 'ADMIN_ADD_TO_CHAT:FAILED',

  ADMIN_REMOVE_FROM_CHAT_REQUEST: 'ADMIN_REMOVE_FROM_CHAT:REQUEST',
  ADMIN_REMOVE_FROM_CHAT_SUCCESS: 'ADMIN_REMOVE_FROM_CHAT:SUCCESS',
  ADMIN_REMOVE_FROM_CHAT_FAILED: 'ADMIN_REMOVE_FROM_CHAT:FAILED',
};

export function chatsRootReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CHATS_ADMIN_GET_REQUEST:
      return {
        ...state,
        fetch: true,
        query: action.payload || '',
      };

    case actions.CHATS_ADMIN_GET_SUCCESS:
      return {
        ...state,
        elements: action.payload.chats,
        fetch: false,
        pagination: {
          ...state.pagination,
          count: action.payload.count,
        },
      };

    case actions.CHATS_ADMIN_GET_FAILED:
      return {
        ...state,
        elements: [],
      };

    case actions.CHATS_ADMIN_CHANGE_PAGE:
      return {
        ...state,
        fetch: true,
        pagination: {
          ...state.pagination,
          page: action.payload,
          limit: state.pagination.limit,
        },
      };

    case actions.CHATS_ADMIN_CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        fetch: false,
        pagination: { ...state.pagination },
      };

    case actions.CHATS_CLINIC_ADMIN_GET_REQUEST:
      return {
        ...state,
        fetch: true,
        query: action.payload || '',
      };

    case actions.CHATS_CLINIC_ADMIN_GET_SUCCESS:
      return {
        ...state,
        elements: action.payload.chats,
        fetch: false,
        pagination: {
          ...state.pagination,
          count: action.payload.count,
        },
      };

    case actions.CHATS_CLINIC_ADMIN_GET_FAILED:
      return {
        ...state,
        elements: [],
      };

    case actions.CHATS_ADMIN_CLINIC_CHANGE_PAGE:
      return {
        ...state,
        fetch: true,
        pagination: {
          ...state.pagination,
          page: action.payload,
          limit: state.pagination.limit,
        },
      };

    case actions.CHATS_ADMIN_CLINIC_CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        fetch: false,
        pagination: { ...state.pagination },
      };

    case actions.CHATS_PAGE_ADMIN_RESET: {
      return {
        ...state,
        fetch: false,
        pagination: {
          ...initialState.pagination,
        },
      };
    }

    case actions.ADMIN_ADD_TO_CHAT_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.ADMIN_ADD_TO_CHAT_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.ADMIN_ADD_TO_CHAT_FAILED:
      return {
        ...state,
        fetch: false,
      };

    case actions.ADMIN_REMOVE_FROM_CHAT_REQUEST:
      return {
        ...state,
        fetch: true,
      };

    case actions.ADMIN_REMOVE_FROM_CHAT_SUCCESS:
      return {
        ...state,
        fetch: false,
      };

    case actions.ADMIN_REMOVE_FROM_CHAT_FAILED:
      return {
        ...state,
        fetch: false,
      };

    default:
      return { ...state };
  }
}
