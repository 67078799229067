export const actions = {
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD:REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD:SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD:ERROR',
  UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD:REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD:SUCCESS',
  UPDATE_PASSWORD_ERROR: 'UPDATE_PASSWORD:ERROR',
};

export function resetPassword(token) {
  return {
    type: actions.RESET_PASSWORD_REQUEST,
    payload: { token },
  };
}

export function updatePasswordViaEmail(email, password, redirect) {
  return {
    type: actions.UPDATE_PASSWORD_REQUEST,
    payload: { email, password, redirect },
  };
}
