import React from 'react';
import './PreviewImageFile.scss';

export const PreviewImageFile = ({ b64, close }) => {
  if (!b64) return null;

  return (
    <div className="preview-image-file img-thumbnail">
      <div className="font-weight-bold">Предпросмотр <small onClick={close}>закрыть</small></div>
      <img src={`data:image/jpg;base64,${b64}`} alt='' /></div>
  );
};
