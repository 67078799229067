import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/free-solid-svg-icons/faFrown';

const NoContent = (props) => {
  const { title, icon } = props;
  return (
    <div className="container">
        <div className="text-center d-sm-flex flex-column">
            <div>
                <FontAwesomeIcon icon={icon || faFrown} className="mt-5 mr-2 ml-2 fa-10x" color="#e9ecef"/>
            </div>
            <div className="mt-3">{title || 'Здесь ничего нет, добавьте что-нибудь скорее!'}</div>
        </div>
    </div>
  );
};

NoContent.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
};

export default NoContent;
