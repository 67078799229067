import React from 'react';

const LocalLoader = (props) => {
  const { title, relative } = props;
  return (
    <div className={`container ${relative && 'relativeLoader'}`}>
      <div className="d-flex flex-column align-items-center mt-2">
        <div>
          <strong>{title || 'Загрузка'}&nbsp;</strong>
        </div>
        <div className="mt-1">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalLoader;
